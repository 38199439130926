/* eslint-disable angular/document-service */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import {
  PublicationDTOModel,
  PublicationDTOModelView
} from 'src/app/Shared/Models/publication-dtomodel'
import {
  RemessaDTOModel,
  valorModelString
} from 'src/app/Shared/Models/remessa-dtomodel'
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model'
import { Session } from 'src/app/Shared/session'

@Component({
  selector: 'app-gestao-titulos',
  templateUrl: './gestao-titulos.component.html',
  styleUrls: ['./gestao-titulos.component.scss']
})
export class GestaoTitulosComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) {}
  shared: Session
  publicacoes
  usuario: UserChangeModel
  term
  p: number = 1

  async ngOnInit() {
    await this.loadpage()
  }

  async atualizar() {
    await this.loadpage()
  }

  async loadpage() {
    this.spinner.show()
    this.shared = Session.getInstance()
    this.usuario = new UserChangeModel()

    this.getHistoricoRemessas()
  }

  async getHistoricoPublicacoes() {
    this.publicacoes = new Array<PublicationDTOModelView>()
    ApiController.getHistoricoPublicacoes(this.http).then(
      sucesso => {
        var retorno = <PublicationDTOModel[]>sucesso

        retorno.forEach(element => {
          var dtoview = new PublicationDTOModelView()

          dtoview.id = element.id
          dtoview.dataHoraEnvio = element.dataHoraEnvio

          if (element.expirado) {
            dtoview.expirado = 'Publicação Expirada'
          } else {
            dtoview.expirado = 'Publicação Ativa'
          }

          this.publicacoes.push(dtoview)
        })

        this.spinner.hide()
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  async getHistoricoRemessas() {
    ApiController.getHistoricorRemessas(this.http).then(
      sucesso => {
        this.spinner.hide()
        var hh = new Array<RemessaDTOModel>()
        var alterando = <RemessaDTOModel[]>sucesso

        alterando.forEach(element => {
          if (element.status == 'Enviada') {
            element.status = 'Erro na remessa'
          }
          hh.push(element)
        })

        this.publicacoes = hh
        //this.publicacoes
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  getViewTitulos(data) {
    this.shared.setRemessa(data)
    this.router.navigate(['/viewTitulos'])
  }

  removerRemessa(id) {
    this.spinner.show()
    ApiController.deleteRemessas(this.http, id).then(
      sucesso => {
        this.getHistoricoRemessas()
        this.alertService.success('Remessa removida com sucesso!')
      },
      err => {
        var mapeado = <ErrorModel>err.error
        var erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(mapeado.IEPROErrorID + ' - ' + mapeado.ErroI)
        this.spinner.hide()
        this.alertService.danger('Falha ao deletar remessa - ' + erromapedo)
      }
    )
  }

  downloadXML(id) {
    this.spinner.show()
    ApiController.getXMLtoDownload(id, this.http).then(
      sucesso => {
        var mapeado = <valorModelString>sucesso

        var blob = new Blob([atob(mapeado.valor)], { type: 'application/xml' })
        var objectUrl = URL.createObjectURL(blob)
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none')
        a.href = objectUrl
        a.download = 'remessaId-' + id
        a.click()

        this.spinner.hide()
      },
      err => {
        console.log(err)
        var mapeado = <ErrorModel>err.error
        var erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(mapeado.IEPROErrorID + ' - ' + mapeado.ErroI)
        this.spinner.hide()
        this.alertService.danger(
          'Falha ao realizar download de XML - ' + erromapedo
        )
      }
    )
  }
}
