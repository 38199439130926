import { Injectable } from '@angular/core';
import { PublicationDTOModel } from 'src/app/Shared/Models/publication-dtomodel';
import { ApiController } from 'src/app/Core/api-controller';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { urlAcesso, constGetPDFtoDownload } from 'src/app/app.host';

@Injectable({
  providedIn: 'root'
})
export class DownloadPublicacoesService {

  constructor(private http : HttpClient) { }

  public async getAllPublications() {
    //this.spinner.show();

    //var publications = new Array<PublicationDTOModel>();
    
    return ApiController.getPublications(this.http);
    
  }

  public downloadDoc(id: string): Observable<any> {
    let url = urlAcesso + constGetPDFtoDownload + id;
    return this.http.get(url, { responseType: "blob" });
  }

}
