import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ContatosModule } from './contatos/contatos.module'
import { GerenciamentoComercialModule } from './gerenciamento-comercial/gerenciamento-comercial.module'
import { IeproCrmRoutingModule } from './iepro-crm-routing.module'


@NgModule({
  declarations: [ ],
  imports: [
    CommonModule,
    IeproCrmRoutingModule,
    ContatosModule,
    GerenciamentoComercialModule
  ],

})
export class IeproCrmModule { }
