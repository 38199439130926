import { Component, OnInit } from '@angular/core'
import { BsLocaleService } from 'ngx-bootstrap/datepicker'
import { IntimacoesDataService } from '../../../shared/intimacoes-data.service'
import { StatusIntimacoes } from '../../../shared/status-intimacoes.model'




@Component({
  selector: 'app-consulta-status-data',
  templateUrl: './consulta-status-data.component.html',
  styleUrls: ['./consulta-status-data.component.scss']
})
export class ConsultaStatusDataComponent implements OnInit {

  public data: Date
  public locale = 'pt-br';
  public intimacoes: StatusIntimacoes[]
  public statusIntimacoes: any
  public dataFormatada
  public statusMensagem = 'Não Enviada'
  public statusEntrega = ''
  semNotificacao: any
  page: number = 1;


  constructor (
    // private bsModalRef: BsModalRef
    private localeService: BsLocaleService,
    private dataService: IntimacoesDataService
  ) {
    this.localeService.use('pt-br');

  }

  ngOnInit() {


  }

  onValueChange(value: Date): void {
    this.localeService.use(this.locale)
    this.data = value
  }

  buscar() {
    this.dataFormatada = this.data.toLocaleDateString('fr-CA')
    this.dataService.getBuscaProtocoloData(this.dataFormatada).subscribe(

      dados => {
        this.intimacoes = dados
        this.statusIntimacoes = this.intimacoes
        // console.log(this.intimacoes.message)
        this.semNotificacao = dados

        console.log(this.semNotificacao.message)
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)

      }

    )
  }

}
