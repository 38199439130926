import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AlterarIntegradorComponent } from './alterar-integrador/alterar-integrador.component'
import { IntegradorHomeComponent } from './integrador-home/integrador-home.component'
import { SecurePassComponent } from './secure-pass/secure-pass.component'

@NgModule({
  declarations: [AlterarIntegradorComponent, IntegradorHomeComponent, SecurePassComponent],
  imports: [
    CommonModule
  ],
  exports: [
    AlterarIntegradorComponent,
    IntegradorHomeComponent
  ]
})
export class ApiIntegradorModule { }
