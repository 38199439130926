import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { AngularFireModule } from 'angularfire2'
import { AngularFireDatabaseModule } from 'angularfire2/database'
import { AngularFirestoreModule } from 'angularfire2/firestore'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
// Import your library
import { AlertModule } from 'ngx-alerts'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { ModalModule } from 'ngx-bootstrap/modal'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { NgxLoadingModule } from 'ngx-loading'
import { NgxMaskModule } from 'ngx-mask'
import { NgxPaginationModule } from 'ngx-pagination'
import { NgxSpinnerModule } from 'ngx-spinner'
import { FirebaseConfig } from './../environments/firebase.config'
import { AppComponent } from './app.component'
import { ROUTES } from './app.routes'
import { AlterarLoginComponent } from './edital-online/components/alterar-login/alterar-login/alterar-login.component'
import { AlterarPassComponent } from './edital-online/components/alterar-pass/alterar-pass/alterar-pass.component'
import { ApiIntegradorModule } from './edital-online/components/api-integrador/api-integrador.module'
import { CadastroUsuarioModule } from './edital-online/components/cadastro-usuario/cadastro-usuario.module'
import { ConfigGeraisModule } from './edital-online/components/config-gerais/config-gerais.module'
import { ConfigGruposModule } from './edital-online/components/config-grupos/config-grupos.module'
import { CreateCartModule } from './edital-online/components/create-cart/create-cart.module'
import { DownloadPublicacoesModule } from './edital-online/components/download-publicacoes/download-publicacoes.module'
import { EdicaoUsuarioModule } from './edital-online/components/edicao-usuario/edicao-usuario.module'
import { GestaoAgendamentoModule } from './edital-online/components/gestao-agendamento/gestao-agendamento.module'
import { GestaoCartorioModule } from './edital-online/components/gestao-cartorio/gestao-cartorio.module'
import { GestaoConteudoModule } from './edital-online/components/gestao-conteudo/gestao-conteudo.module'
import { GestaoPublicacaoModule } from './edital-online/components/gestao-publicacao/gestao-publicacao.module'
import { GestaoTitulosModule } from './edital-online/components/gestao-titulos/gestao-titulos.module'
import { HeaderModule } from './edital-online/components/header/header.module'
import { HomePageModule } from './edital-online/components/home-page/home-page.module'
import { InformativasModule } from './edital-online/components/informativas/informativas.module'
import { ManutConfigAppModule } from './edital-online/components/manut-config-app/manut-config-app.module'
import { RelatorioRemessaModule } from './edital-online/components/relatorio-remessa/relatorio-remessa.module'
import { UploadFilesModule } from './edital-online/components/upload-files/upload-files.module'
import { ViewRemessaSuporteModule } from './edital-online/components/view-remessa-suporte/view-remessa-suporte.module'
import { ViewTitulosModule } from './edital-online/components/view-titulos/view-titulos.module'
import { CadastroEmpresaComponent } from './iepro-crm/gerenciamento-comercial/gerenciamento-comercial/cadastro-empresa/cadastro-empresa.component'
import { IeproCrmModule } from './iepro-crm/iepro-crm.module'
import { AceiteIntimadorComponent } from './intimador-online/aceite-intimador/aceite-intimador/aceite-intimador.component'
import { IntimacaoOnlineModule } from './intimador-online/intimacao-online.module'
import { MenuSistemasModule } from './Menu/menu-sistemas.module'
import { ModulosExterno } from './modulos/modulos-externo.module'
import { JwtInterceptor } from './_helpers/jwt.interceptor'








@NgModule({
  declarations: [
    
    AppComponent
  ],
  imports: [
    ModulosExterno,
    BrowserModule,
    FormsModule,

    AngularFireModule.initializeApp(FirebaseConfig),
    AngularFireDatabaseModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    HeaderModule,
    UploadFilesModule,
    GestaoConteudoModule,
    HttpClientModule,
    EdicaoUsuarioModule,
    InformativasModule,
    CadastroUsuarioModule,
    RelatorioRemessaModule,
    TooltipModule.forRoot(),
    NgxSpinnerModule,
    BsDatepickerModule.forRoot(),
    ViewTitulosModule,
    ConfigGruposModule,
    ViewRemessaSuporteModule,
    ConfigGeraisModule,
    BrowserAnimationsModule,
    DownloadPublicacoesModule,
    AngularFirestoreModule,
    CreateCartModule,
    GestaoCartorioModule,
    GestaoPublicacaoModule,
    MenuSistemasModule,
    GestaoTitulosModule,
    ApiIntegradorModule,
    IeproCrmModule,
    IntimacaoOnlineModule,
    HomePageModule,
    GestaoAgendamentoModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    ManutConfigAppModule,
    NgbModule,
    ModalModule.forRoot(),
    RouterModule.forRoot(ROUTES, { onSameUrlNavigation: 'reload' }),
    NgxLoadingModule.forRoot({}),
    AlertModule.forRoot({ maxMessages: 5, timeout: 7000, position: 'right' })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true, },
    //  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  entryComponents: [
    CadastroEmpresaComponent,
    AlterarLoginComponent,
    AlterarPassComponent,
    



  ]
})
export class AppModule { }
