import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/Shared/Models/error-model';
import { Session } from 'src/app/Shared/session';
import { ApiController } from '../../../../Core/api-controller';
import { GrupoDTOModel } from '../../../../Shared/Models/group-dtomodel';
import { InteiroReturnValor } from '../../../../Shared/Models/remessa-dtomodel';

@Component({
  selector: 'app-criar-grupo',
  templateUrl: './criar-grupo.component.html',
  styleUrls: ['./criar-grupo.component.scss']
})
export class CriarGrupoComponent implements OnInit {

  constructor(private http: HttpClient, private router : Router, private spinner: NgxSpinnerService, private alertService: AlertService) { }
  public shared : Session;

  @ViewChild('name', {static: false}) name : ElementRef;

  ngOnInit() {
    this.spinner.show();  
    this.shared = Session.getInstance();
    this.spinner.hide();

  }

  criarGrupo() {
    var objGrupo = new GrupoDTOModel();
    objGrupo.id = -1;
    objGrupo.nome = this.name.nativeElement.value;
    ApiController.postCriacaoGrupo(this.http, objGrupo)
      .then ( sucesso => {
        var mapeado = <InteiroReturnValor>sucesso;
        if(mapeado.valor != -1) {
          this.shared = Session.getInstance();

          this.shared.setGrupoId(mapeado.valor);
          this.shared.setGrupoNome(objGrupo.nome);
          this.shared.setEdit(true);
          this.shared.setNovo(true);
          this.router.navigate(['/editPermissao']);
        }
        else {
          this.alertService.danger("Falha ao realizar criação de grupo...");
        }
      },
      err => {
        console.log("Falha ao criar grupo permissoes...");
        var mapeado = <ErrorModel>err;
        this.alertService.danger(mapeado.ErroI + " - " + mapeado.Mensagem);
      });
  }

  cancelar(){
    var shared = Session.getInstance();
    this.router.navigate([shared.getHomePage()]);
  }

}
