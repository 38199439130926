/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-gerenciamento-comercial',
  templateUrl: './gerenciamento-comercial.component.html',
  styleUrls: ['./gerenciamento-comercial.component.scss']
})
export class GerenciamentoComercialComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // this.carregarAbas()
  }

  // carregarAbas() {
  //   const abas = document.querySelectorAll('#Tabs>li')
  //   for (let i = 0; i < abas.length; i++) {
  //     abas[i].addEventListener('click', function(){
  //       const conteudos = document.querySelectorAll('.tab-content>.tab-pane')
  //       for (let k = 0; k < conteudos.length; k++) {
  //         conteudos[k].className = 'tab-pane'
  //       }
  //       for (let j = 0; j < abas.length; j++) {
  //         abas[j].getElementsByTagName('a')[0].className = 'nav-link'
  //       }
  //       const abaAtiva = this.getElementsByTagName('a')[0]
  //       const idAbaAtiva = abaAtiva.id
  //       const conteudoAtivo = document.querySelectorAll('.tab-content>#' + idAbaAtiva)[0]
  //       abaAtiva.className = 'nav-link active'
  //       conteudoAtivo.className = 'tab-pane active'
  //     })
  //   }
  // }
}
