/* eslint-disable angular/window-service */
import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { TituloRemessaModel } from 'src/app/Shared/Models/titulo-remessa-model'
import { Session } from 'src/app/Shared/session'
import { ApiController } from '../../../../Core/api-controller'

@Component({
  selector: 'app-view-titulos',
  templateUrl: './view-titulos.component.html',
  styleUrls: ['./view-titulos.component.scss']
})
export class ViewTitulosComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private alertService: AlertService
  ) {}

  public shared: Session
  listaok = false
  titulos

  @ViewChild('calendario', { static: false }) calendario: ElementRef
  @ViewChild('calendario1', { static: false }) calendario1: ElementRef

  ngOnInit() {
    this.shared = Session.getInstance()

    if (this.shared.getRemessa() == null) {
      var data = new Date()
      var dia = data.getDate()
      var mes = data.getMonth() + 1
      var ano = data.getFullYear()

      var dd
      var mm

      if (dia.toString().length < 2) {
        dd = '0' + dia.toString()
      } else {
        dd = dia.toString()
      }

      if (mes.toString().length < 2) {
        mm = '0' + mes.toString()
      } else {
        mm = mes.toString()
      }

      var dataok = dd + '/' + mm + '/' + ano
      this.getViewTitulos(dataok)
    } else {
      this.getViewTitulos(this.shared.getRemessa())
      this.shared.setRemessa(null)
    }
  }

  novoRelatorioTitulos() {
    if (
      this.calendario.nativeElement.value != '' &&
      this.calendario1.nativeElement.value != ''
    ) {
      var newdate = ApiController.convertDate(
        this.calendario.nativeElement.value
      )
      var datesplit = newdate.split('/')

      var newdate1 = ApiController.convertDate(
        this.calendario1.nativeElement.value
      )
      var datesplit1 = newdate1.split('/')

      var date1 = new Date(
        parseInt(datesplit[2]),
        parseInt(datesplit[1]),
        parseInt(datesplit[0])
      )
      var date2 = new Date(
        parseInt(datesplit1[2]),
        parseInt(datesplit1[1]),
        parseInt(datesplit1[0])
      )

      if (date1 > date2) {
        this.alertService.warning('Intervalo de datas ou formato inválido...')
      } else {
        this.getViewTitulosbyDate(newdate, newdate1)
      }
    } else {
      this.alertService.warning(
        'Selecione um intervalo válido para consulta...'
      )
    }
  }

  async getViewTitulosbyDate(date, date1) {
    this.spinner.show()

    ApiController.getTitulosbyDates('-1', date, date1, this.http).then(
      sucesso => {
        this.spinner.hide()

        this.titulos = <TituloRemessaModel>sucesso
        if (this.titulos.length > 10) this.listaok = true
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  async getViewTitulos(date) {
    this.spinner.show()

    ApiController.getTitulosbyDate('-1', date, this.http).then(
      sucesso => {
        this.spinner.hide()

        this.titulos = <TituloRemessaModel>sucesso

        this.listaok = true
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  cancelar() {
    this.router.navigate([this.shared.getHomePage()])
  }

  onPrint() {
    window.print()
  }
}
