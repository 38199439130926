import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { ConfigGeraisComponent } from './config-gerais/config-gerais.component'

@NgModule({
  declarations: [ConfigGeraisComponent],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule.forRoot(),

  ], exports: [
    ConfigGeraisComponent
  ]
})
export class ConfigGeraisModule { }
