import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
// RECOMMENDED
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { RelatorioRemessaComponent } from './relatorio-remessa/relatorio-remessa.component'
import { ViewReportComponent } from './relatorio-remessa/view-report/view-report.component'


@NgModule({
  declarations: [RelatorioRemessaComponent, ViewReportComponent],
  imports: [
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot()
  ],
  exports: [
    RelatorioRemessaComponent
  ]
})
export class RelatorioRemessaModule { }
