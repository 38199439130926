import { Component, OnInit } from '@angular/core'
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { DetalheDocumentoIntimacoes } from './../../../shared/detalhe-documento-intimacoes'
import { IntimacoesDataService } from './../../../shared/intimacoes-data.service'

@Component({
  selector: 'app-relatorio-intimacoes-documento',
  templateUrl: './relatorio-intimacoes-documento.component.html',
  styleUrls: ['./relatorio-intimacoes-documento.component.scss']
})
export class RelatorioIntimacoesDocumentoComponent implements OnInit {

  public documento: string = ''
  public detalheIntimacoes: DetalheDocumentoIntimacoes[]
  semNotificacao: any
  page = ""
  active = ''

  constructor(private dataService: IntimacoesDataService) { }

  ngOnInit() {
  }

  buscar() {
    this.detalheIntimacoes = null
    this.dataService.getBuscaDocumentoDetalhes(this.documento).subscribe(
      dados => {
        this.detalheIntimacoes = dados
        this.semNotificacao = dados
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)
      }

      )
      this.active = ''
    }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.page = "1"
  }

}
