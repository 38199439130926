import { Injectable } from '@angular/core'
import { AngularFireDatabase } from 'angularfire2/database'
import { NgxSpinnerService } from 'ngx-spinner'
import { map } from 'rxjs/operators'
import { Contato } from '../iepro-crm/contatos/contato'

@Injectable({
  providedIn: 'root'
})
export class ContatoService {

  constructor (private _angularFireDataBase: AngularFireDatabase, private spinner: NgxSpinnerService) { }




  // insert(contato: Contato, key: string) {
  //   this._angularFireDataBase.list('contact2/' + key).push({
  //     ocorrencia: contato.ocorrencia
  //   }).set({
  //     ocorrencia: contato.ocorrencia
  //   })
  // }



  update(contato: Contato, key: string) {
    this._angularFireDataBase.list(`contact/${ key }/ocorrencia`).push({
      ocorrenciaUser: contato.ocorrencia.ocorrenciaUser,
      data: contato.ocorrencia.dataOcorrencia,
      usuarioIepro: contato.ocorrencia.usuarioIepro
    })
      .catch((error: any) => {
        console.error(error)
      })
  }

  getAll() {
    return this._angularFireDataBase.list("contact/")
      .snapshotChanges().pipe(
        map(changes => {
          this.spinner.show()
          return changes.map(data => ({ key: data.payload.key, ...data.payload.exportVal() }), this.spinner.hide())

        })
      )
  }

}
