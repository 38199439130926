/* eslint-disable angular/document-service */
import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import { ApiController } from '../../../../Core/api-controller'
import { UsersGrupo } from '../../../../Shared/Models/user-model'
import { Session } from '../../../../Shared/session'

@Component({
  selector: 'app-edit-usuarios-grupo',
  templateUrl: './edit-usuarios-grupo.component.html',
  styleUrls: ['./edit-usuarios-grupo.component.scss']
})
export class EditUsuariosGrupoComponent implements OnInit {
  listaok = false
  term
  usuarios
  usuariosgrupo
  tipoM
  checarTodos = false
  public shared: Session
  grupo

  @ViewChild('check', { static: false }) check: ElementRef

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.spinner.show()
    this.loadpage().then(() => {
      this.grupo = this.shared.getGrupoNome()
    })
  }

  async loadpage(): Promise<Boolean> {
    this.shared = Session.getInstance()

    if (this.shared.getPermissao().ManageSecurity) {
      this.tipoM = true

      if (this.shared.getEdit()) {
        await this.loadUsers()
        await this.loadUsersGrupo()
        this.listaok = true

        return Promise.resolve(true)
      } else {
        this.loadUsersGrupo()
        return Promise.resolve(true)
      }
    } else {
      this.router.navigate([this.shared.getHomePage()])
    }
    this.spinner.hide()
  }

  async loadChecks() {
    // console.log('LOADCHECKS')
    await ApiController.delay(500)
    var that = this
    const checkboxes = document.querySelectorAll('input.form-check-input')
    checkboxes.forEach(function (checkbox) {
      that.usuariosgrupo.forEach(function (grupo) {
        if (checkbox.id == grupo.id) {
          ;(checkbox as HTMLInputElement).checked = true
        }
      })
    })
    if (that.shared.getEdit()) {
      this.spinner.hide()
    }
    return Promise.resolve(true)
  }

  async loadUsers(): Promise<Boolean> {
    ApiController.getUsuarios(this.http).then(
      lista => {
        this.usuarios = <UsersGrupo[]>lista
        if (this.shared.getEdit()) {
          this.loadChecks()
        } else {
          this.spinner.hide()
        }
      },
      err => {
        var mapeado = <ErrorModel>err
      }
    )
    return Promise.resolve(true)
  }

  // checkAll() {
  //   const checkboxes = document.querySelectorAll("input.form-check-input")
  //   for (let index = 0; index < checkboxes.length; index++) {
  //     const element = checkboxes[index] as HTMLInputElement
  //     element.checked = this.check.nativeElement.checked
  //   }
  // }

  async loadUsersGrupo() {
    ApiController.getUsuariosGrupo(this.http, this.shared.getGrupoId()).then(
      lista => {
        if (this.shared.getEdit()) {
          this.usuariosgrupo = <UsersGrupo[]>lista
        } else {
          this.usuarios = <UsersGrupo[]>lista
          if (this.usuarios.length > 0) {
            this.listaok = true
            this.spinner.hide()
          } else {
            this.alertService.info('Grupo não contém usuários cadastrados.')
            this.router.navigate(['/listGroup'])
          }
        }
      },
      err => {
        var mapeado = <ErrorModel>err
      }
    )
  }

  cancelar() {
    this.router.navigate(['/listGroup'])
  }

  async atualizar() {
    const checkboxes = document.querySelectorAll('input.form-check-input')

    var hh = Array<Number>()

    checkboxes.forEach(element => {
      const checkbox = element as HTMLInputElement
      if (checkbox.checked) {
        hh.push(parseInt(checkbox.id))
      }
    })

    ApiController.putUsuariosGrupo(this.http, hh).then(
      sucesso => {
        this.alertService.success('Alterações realizadas com sucesso.')
        this.router.navigate(['/listGroup'])
      },
      err => {
        var mapeado = <ErrorModel>err
      }
    )
  }
}
