/* eslint-disable angular/document-service */
/* eslint-disable angular/window-service */
import { Component, Input, OnInit, Renderer2 } from '@angular/core'
import { Router } from '@angular/router'
import * as AdminLte from 'admin-lte'
import * as $ from 'jquery'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NgxSpinnerService } from 'ngx-spinner'
import { AceiteIntimadorComponent } from './../../../intimador-online/aceite-intimador/aceite-intimador/aceite-intimador.component'
import { PermissoesToken } from './../../../Shared/service/permissoes-token.service'
import { Session } from './../../../Shared/session'

@Component({
  selector: 'app-menu-intimador',
  templateUrl: './menu-intimador.component.html',
  styleUrls: ['./menu-intimador.component.scss']
})
export class MenuIntimadorComponent implements OnInit {
  public permissaoIntimador: any
  public sidebarMenuOpened = true
  public session: Session
  public nomeUsuario: string
  public bsModalRef: BsModalRef

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private tokenService: PermissoesToken,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) {}

  @Input('userName') UserNameToShow: string

  ngOnInit() {
    this.session = Session.getInstance()
    if (this.session.getToken() != undefined) {
      this.session.setMenu(true)
      this.session.setMenuApi(false)
    } else {
      this.session.clear()
    }
    this.UserNameToShow = this.session.getLogin()
    this.nomeUsuario = this.UserNameToShow.split('@', 1)[0]

    this.permissaoIntimador = this.tokenService.getPermissaoIntimadorToken()
  }

  openModalAlterarLogin() {
    this.bsModalRef = this.modalService.show(AceiteIntimadorComponent)
    this.bsModalRef.content.closeBtnName = 'Close'
  }

  homeNavigate() {
    window.location.reload()
  }

  gestaoIntimacoes() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/intimador-online/gestao-de-intimacoes'])
  }

  statusIntimacoes() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/intimador-online/status-intimacoes'])
  }
  statusIntimacoesIepro() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/intimador-online/status-intimacoes-iepro'])
  }

  relatorioIepro() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/intimador-online/relatorio-iepro'])
  }

  relatorioIntimacoes() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/intimador-online/relatorio-intimacoes'])
  }
  inclusaoTermo() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/inclusao-termo-aceite'])
  }

  logOut() {
    this.spinner.show()
    try {
      localStorage.removeItem('currentUser')
      window.location.reload()
    } finally {
      this.spinner.hide()
    }
  }

  ngAfterViewInit() {
    $('[data-widget="treeview"]').each(function () {
      AdminLte.Treeview._jQueryInterface.call($(this), 'init')
    })
  }

  toggleMenuSidebar() {
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      )
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      )
      this.sidebarMenuOpened = false
    } else {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      )
      this.renderer.addClass(document.querySelector('app-root'), 'sidebar-open')
      this.sidebarMenuOpened = true
    }
  }
}
