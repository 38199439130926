import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import { PublicationDTOModel, PublicationDTOModelView } from 'src/app/Shared/Models/publication-dtomodel'
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model'
import { Session } from 'src/app/Shared/session'

@Component({
  selector: 'app-gestao-publicacao',
  templateUrl: './gestao-publicacao.component.html',
  styleUrls: ['./gestao-publicacao.component.scss']
})
export class GestaoPublicacaoComponent implements OnInit {
  constructor (private http: HttpClient, private spinner: NgxSpinnerService, private alertService: AlertService) { }
  shared: Session
  publicacoes
  usuario: UserChangeModel
  alterar = false;
  term

  async ngOnInit() {
    await this.loadpage()
  }

  async loadpage() {
    this.spinner.show()
    this.shared = Session.getInstance()
    this.usuario = new UserChangeModel()

    this.getHistoricoPublicacoes()
  }

  async atualizar() {
    await this.loadpage()
  }

  async getHistoricoPublicacoes() {

    this.publicacoes = new Array<PublicationDTOModelView>()
    ApiController.getHistoricoPublicacoes(this.http)
      .then(sucesso => {
        var retorno = <PublicationDTOModel[]>sucesso

        retorno.forEach(element => {
          var dtoview = new PublicationDTOModelView()

          dtoview.id = element.id
          dtoview.dataHoraEnvio = element.dataHoraEnvio
          if (element.tipo.toUpperCase() == "A")
            dtoview.tipo = "Automática"
          else
            dtoview.tipo = "Manual"

          if (element.expirado) {
            dtoview.expirado = "Publicação Expirada"
            dtoview.status = false
          }
          else {
            dtoview.expirado = "Publicação Ativa"
            dtoview.status = true
          }

          this.publicacoes.push(dtoview)
        })

        this.spinner.hide()
      },
        err => {
          this.spinner.hide()
          console.log(err)
        }
      )
  }

  cancelarEdit() {
    this.alterar = false
  }

  removerPublicacao(id) {
    this.spinner.show()
    ApiController.deletePublicacao(this.http, id)
      .then(sucesso => {
        this.alertService.success("Publicação removida com sucesso.")
        this.getHistoricoPublicacoes()
      },
        err => {
          var mapeado = <ErrorModel>err.error
          var erromapedo = ApiController.mapError(mapeado.IEPROErrorID, mapeado.Mensagem, mapeado.ErroI)
          console.log(mapeado.IEPROErrorID + " - " + mapeado.ErroI)
          this.spinner.hide()
          this.alertService.danger("Falha ao deletar publicação - " + erromapedo)
        })
  }
}

