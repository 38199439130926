/* eslint-disable angular/document-service */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { urlAcesso } from 'src/app/app.host'
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model'
import { Session } from 'src/app/Shared/session'
import { ApiController } from '../../../../Core/api-controller'
import { PublicationDTOModel } from '../../../../Shared/Models/publication-dtomodel'
import { PublicacoesDataService } from '../../shared/publicacoes-data.service'
const pdf2base64 = require('pdf-to-base64')
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  shared: Session
  publicacoes
  pdfSrc
  usuario: UserChangeModel
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router,
    private publicacoesData: PublicacoesDataService
  ) {}

  ngOnInit() {
    this.loadpage()
    // this.buscandoPDFValido()
  }
  loadpage() {
    this.spinner.show()
    // window.location.reload()
    this.shared = Session.getInstance()
    this.usuario = new UserChangeModel()

    this.buscandoPDFValido()
    this.spinner.hide()
  }
  buscandoPDFValido() {
    this.spinner.show()
    this.publicacoesData.getUltimaPublicacaoValida().subscribe(
      sucess => (this.pdfSrc = URL.createObjectURL(sucess)),
      error => (this.pdfSrc = '../../../../../assets/pdf/Pdfdefault.pdf')
    )
    this.spinner.hide()
  }
}
