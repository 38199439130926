import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import * as buscadorcep from 'buscadorcep'
import { BsModalRef } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-cadastro-empresa',
  templateUrl: './cadastro-empresa.component.html',
  styleUrls: ['./cadastro-empresa.component.scss']
})
export class CadastroEmpresaComponent implements OnInit {
  public bsModalRef: BsModalRef
  public formularioEmpresa: FormGroup

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.formularioEmpresa = this.formBuilder.group({
      descricao: [null, [Validators.required, Validators.minLength(5)]],
      cnpj: [
        null,
        [
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14)
        ]
      ],
      responsavel: [null, Validators.required],
      documentoResponsavel: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      telefone: [null, [Validators.required, Validators.minLength(10)]],
      cep: [
        null,
        [Validators.required, Validators.minLength(8), Validators.maxLength(8)]
      ],
      cidade: [null, Validators.required],
      codIBGE: [null, Validators.required],
      endereco: [null, Validators.required],
      bairro: [null, Validators.required]
    })
  }

  doHide() {
    this.bsModalRef.hide()
  }

  verificaValidTouched(campo: string) {
    return (
      !this.formularioEmpresa.get(campo).valid &&
      this.formularioEmpresa.get(campo).touched
    )
  }

  aplicaCssErro(campo: string) {
    return {
      'is-invalid': this.verificaValidTouched(campo)
    }
  }

  consultaCEP(cep) {
    let enderecoApi = buscadorcep(cep)
      .then(response => response)
      .then(enderecoApi =>
        this.populaDadosForm(enderecoApi, this.formularioEmpresa)
      )
  }

  populaDadosForm(enderecoApi, formularioEmpresa) {
    this.formularioEmpresa.patchValue({
      cidade: enderecoApi.localidade,
      codIBGE: enderecoApi.ibge,
      endereco: enderecoApi.logradouro,
      bairro: enderecoApi.bairro
    })
  }
}
