export class GrupoDTOModel {

    id : number;

    nome : string;
}

export class PermissaoDTOModel {

    id : number;

    permissao : string;
}


