/* eslint-disable angular/timeout-service */
/* eslint-disable angular/interval-service */
import { Component, OnInit, TemplateRef } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-inclusao-de-termo-iepro',
  templateUrl: './inclusao-de-termo-iepro.component.html',
  styleUrls: ['./inclusao-de-termo-iepro.component.scss']
})
export class InclusaoDeTermoIeproComponent implements OnInit {
  loading = false
  modalRef: BsModalRef
  files: any[] = []
  p

  constructor(private modalService: BsModalService) {}

  ngOnInit() {
    // this.loading = true
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'gray modal-lg' })
    )
  }

  /**
   * on file drop handler
   */
  onFileDropped($event) {
    this.prepareFilesList($event)
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler(files) {
    this.prepareFilesList(files)
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    this.files.splice(index, 1)
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
    setTimeout(() => {
      if (index === this.files.length) {
        return
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval)
            this.uploadFilesSimulator(index + 1)
          } else {
            this.files[index].progress += 5
          }
        }, 200)
      }
    }, 1000)
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      item.progress = 0
      this.files.push(item)
    }
    this.uploadFilesSimulator(0)
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes, decimals) {
    if (bytes === 0) {
      return '0 Bytes'
    }
    const k = 1024
    const dm = decimals <= 0 ? 0 : decimals || 2
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }
}
