import { Contato } from './../contato';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { AngularFireAuth } from 'angularfire2/auth'
import { NgxSpinnerService } from 'ngx-spinner'
import { Observable } from 'rxjs'
import { ContatoDataService } from '../../../_services/contato-data.service'
import { ContatoService } from '../../../_services/contato.service'
import { Session } from './../../../Shared/session'

@Component({
  selector: 'app-lista',
  templateUrl: './lista.component.html',
  styleUrls: ['./lista.component.scss']
})
export class ListaComponent implements OnInit {
  user: Observable<firebase.User>
  contatos: Observable<any>
  p: number = 1;
  term: void
  classeSpanStatusContato: boolean = false
  contatoModel = new Contato

  constructor(
    private _contatoService: ContatoService,
    private _contatoDataService: ContatoDataService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private afAuth: AngularFireAuth,
    private shared: Session
  ) { }

  ngOnInit() {
    this.spinner.show()
    this.shared = Session.getInstance()
    this.contatos = this._contatoService.getAll()
    this.spinner.hide()
  }
  visualizar(contato: Contato, user_id: string) {
    this._contatoDataService.obtemContato(contato, user_id)
    this.router.navigate(['/iepro-crm/edita-contato'])
  }
}
