import { RouterModule, Routes } from '@angular/router'
import { AlterarIntegradorComponent } from './edital-online/components/api-integrador/alterar-integrador/alterar-integrador.component'
import { IntegradorHomeComponent } from './edital-online/components/api-integrador/integrador-home/integrador-home.component'
import { CadastroUsuarioComponent } from './edital-online/components/cadastro-usuario/cadastro-usuario/cadastro-usuario.component'
import { ConfigGeraisComponent } from './edital-online/components/config-gerais/config-gerais/config-gerais.component'
import { CriarGrupoComponent } from './edital-online/components/config-grupos/criar-grupo/criar-grupo.component'
import { EditPermissoesComponent } from './edital-online/components/config-grupos/edit-permissoes/edit-permissoes.component'
import { EditUsuariosGrupoComponent } from './edital-online/components/config-grupos/edit-usuarios-grupo/edit-usuarios-grupo.component'
import { ListGruposComponent } from './edital-online/components/config-grupos/list-grupos/list-grupos.component'
import { CreateCartComponent } from './edital-online/components/create-cart/create-cart/create-cart.component'
import { DownloadPublicacoesComponent } from './edital-online/components/download-publicacoes/components/download-publicacoes.component'
import { EdicaousuarioComponent } from './edital-online/components/edicao-usuario/edicaousuario/edicaousuario.component'
import { GestaoCartorioComponent } from './edital-online/components/gestao-cartorio/gestao-cartorio/gestao-cartorio.component'
import { GestaoPublicacaoComponent } from './edital-online/components/gestao-publicacao/gestao-publicacao/gestao-publicacao.component'
import { GestaoTitulosComponent } from './edital-online/components/gestao-titulos/gestao-titulos/gestao-titulos.component'
import { HomepageComponent } from './edital-online/components/home-page/homepage/homepage.component'
import { ManutFirebaseComponent } from './edital-online/components/manut-config-app/manut-firebase/manut-firebase.component'
import { RelatorioRemessaComponent } from './edital-online/components/relatorio-remessa/relatorio-remessa/relatorio-remessa.component'
import { CompUploadFilesComponent } from './edital-online/components/upload-files/comp-upload-files/comp-upload-files.component'
import { ViewRemessaSuporteComponent } from './edital-online/components/view-remessa-suporte/view-remessa-suporte/view-remessa-suporte.component'
import { ViewTitulosComponent } from './edital-online/components/view-titulos/view-titulos/view-titulos.component'
import { AuthGuard } from './_guards/auth.guard'


export const ROUTES: Routes = [
    // { path: '', loadChildren: () => import('../app/Menu/menu-sistemas.module').then(m => m.MenuSistemasModule), canActivate: [AuthGuard], },
    // { path: '', component: HomepageComponent, canActivate: [AuthGuard], },

    { path: 'UploadFile', component: CompUploadFilesComponent, canActivate: [AuthGuard] },
    { path: 'EditCartorio', component: EdicaousuarioComponent, canActivate: [AuthGuard] },
    { path: 'AddUser', component: CadastroUsuarioComponent, canActivate: [AuthGuard] },
    { path: 'viewRemessas', component: RelatorioRemessaComponent, canActivate: [AuthGuard] },
    { path: 'viewTitulos', component: ViewTitulosComponent, canActivate: [AuthGuard] },
    { path: 'viewRemessaSuporte', component: ViewRemessaSuporteComponent, canActivate: [AuthGuard] },
    { path: 'createGroup', component: CriarGrupoComponent, canActivate: [AuthGuard] },
    { path: 'listGroup', component: ListGruposComponent, canActivate: [AuthGuard] },
    { path: 'editPermissao', component: EditPermissoesComponent, canActivate: [AuthGuard] },
    { path: 'editUsersGrupo', component: EditUsuariosGrupoComponent, canActivate: [AuthGuard] },
    { path: 'config', component: ConfigGeraisComponent, canActivate: [AuthGuard] },
    { path: 'downloadPublicacoes', component: DownloadPublicacoesComponent, canActivate: [AuthGuard] },
    { path: 'createCart', component: CreateCartComponent, canActivate: [AuthGuard] },
    { path: 'cadastroUsuario', component: CadastroUsuarioComponent, canActivate: [AuthGuard] },
    { path: 'GestaoCartorio', component: GestaoCartorioComponent, canActivate: [AuthGuard] },
    { path: 'GestaoPublicacoes', component: GestaoPublicacaoComponent, canActivate: [AuthGuard] },
    { path: 'GestaoTitulos', component: GestaoTitulosComponent, canActivate: [AuthGuard] },
    { path: 'integrador', component: IntegradorHomeComponent, canActivate: [AuthGuard] },
    { path: 'integrador2', component: AlterarIntegradorComponent, canActivate: [AuthGuard] },
    { path: 'home', component: HomepageComponent, canActivate: [AuthGuard] },
    { path: 'configEmolumentos', component: ManutFirebaseComponent, canActivate: [AuthGuard] },
    { path: 'iepro-crm', loadChildren: () => import('./iepro-crm/iepro-crm.module').then(m => m.IeproCrmModule) },
    { path: 'intimador-online', loadChildren: () => import('../app/intimador-online/intimacao-online.module').then(m => m.IntimacaoOnlineModule) },
    { path: '**', redirectTo: '' }

    /*
    { path: 'Info01', component: Informativa1Component, canActivate: [AuthGuard] },
    { path: 'ChangePass', component: AlterarPassComponent, canActivate: [AuthGuard] }, 
    { path: 'ChangeLogin', component: AlterarLoginComponent, canActivate: [AuthGuard] }, 
    { path: 'SenhaSegura', component: SecurePassComponent, canActivate: [AuthGuard] },
    { path: 'GestaoConteudo', component: GestaoConteudoComponent, canActivate: [AuthGuard] }, 
    { path: 'uploadNews', component: PublicacaoAgendadaEnvioComponent, canActivate: [AuthGuard] },  -> Refatorar
    { path: 'GestaoAgendamento', component: GestaoAgendamentosComponent, canActivate: [AuthGuard]},  -> Não fazer, informado pelo rafa
    **/
]
export const routing = RouterModule.forRoot(ROUTES, { useHash: true, onSameUrlNavigation: 'reload', })
