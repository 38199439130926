/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-relatorio-iepro',
  templateUrl: './relatorio-iepro.component.html',
  styleUrls: ['./relatorio-iepro.component.scss']
})
export class RelatorioIeproComponent implements OnInit {
  public bsModalRef: BsModalRef
  public buscaData = false
  public buscaProtocolo = false
  public buscaDocumento = false

  constructor(private modalService: BsModalService) {}

  ngOnInit() {}

  openBuscaData() {
    return (
      (this.buscaData = true),
      (this.buscaProtocolo = false),
      (this.buscaDocumento = false)
    )
  }

  openBuscaProtocolo() {
    return (
      (this.buscaProtocolo = true),
      (this.buscaData = false),
      (this.buscaDocumento = false)
    )
  }

  openBuscaDocumento() {
    return (
      (this.buscaDocumento = true),
      (this.buscaProtocolo = false),
      (this.buscaData = false)
    )
  }
}
