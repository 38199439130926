import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import * as buscadorcep from 'buscadorcep'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import { Session } from 'src/app/Shared/session'
import { ApiController } from '../../../../Core/api-controller'
import { CartorioCadastroModel } from '../../../../Shared/Models/cartorio-model'


@Component({
  selector: 'app-create-cart',
  templateUrl: './create-cart.component.html',
  styleUrls: ['./create-cart.component.scss']
})
export class CreateCartComponent implements OnInit {

  shared: Session
  objCartorio: CartorioCadastroModel
  formularioCartorio: FormGroup

  constructor (
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private formBuilder: FormBuilder
  ) { }



  ngOnInit() {

    this.shared = Session.getInstance()

    if (this.shared.getToken() != undefined) {
      //await this.loadFields();      
    }
    else {
      this.router.navigate([this.shared.getHomePage()])
      //console.log('foi pro barra');
    }

    this.formularioCartorio = this.formBuilder.group({
      descricao: [null, [Validators.required, Validators.minLength(5)]],
      cnpj: [null,
        [
          Validators.required,
          Validators.minLength(14),
          Validators.maxLength(14)
        ]],
      responsavel: [null, Validators.required],
      documentoResponsavel: [null, Validators.required],
      email: [null,
        [
          Validators.required,
          Validators.email
        ]],
      telefone: [null,
        [
          Validators.required,
          Validators.minLength(10)
        ]],
      cep: [null,
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(8)
        ]],
      cidade: [null, Validators.required],
      codIBGE: [null, Validators.required],
      endereco: [null, Validators.required],
      bairro: [null, Validators.required],
      comarca: [null, Validators.required]
    })

  }

  cancelar() {
    this.router.navigate([this.shared.getHomePage()])
  }





  getInfos(): boolean {
    this.objCartorio = new CartorioCadastroModel()
    this.objCartorio.cidade = this.formularioCartorio.get('cidade').value
    this.objCartorio.cnpj = this.formularioCartorio.get('cnpj').value
    this.objCartorio.codIBGE = this.formularioCartorio.get('codIBGE').value
    this.objCartorio.descricao = this.formularioCartorio.get('descricao').value
    this.objCartorio.documentoResponsavel = this.formularioCartorio.get('documentoResponsavel').value
    this.objCartorio.email = this.formularioCartorio.get('email').value
    this.objCartorio.responsavel = this.formularioCartorio.get('responsavel').value
    this.objCartorio.endereco = this.formularioCartorio.get('endereco').value
    this.objCartorio.bairro = this.formularioCartorio.get('bairro').value
    this.objCartorio.telefone = this.formularioCartorio.get('telefone').value
    this.objCartorio.cep = this.formularioCartorio.get('cep').value
    this.objCartorio.comarca = this.formularioCartorio.get('comarca').value

    if (this.objCartorio != null && this.objCartorio != undefined) {
      return true
    } else {
      return false
    }
    return false
  }

  criarCartorio() {
    this.spinner.show()
    if (this.getInfos()) {

      ApiController.postCriacaoCartorio(this.http, this.objCartorio)
        .then(sucesso => {
          //console.log(sucesso);
          var idCartorio = sucesso
          this.shared.setCartId(idCartorio)
          this.spinner.hide()
          this.alertService.success("Cartório criado com sucesso - Id: " + idCartorio)
          this.router.navigate([this.shared.getHomePage()])
        },
          err => {
            console.log(err)
            console.log(err.error)
            var mapeado = <ErrorModel>err.error
            var erromapedo = ApiController.mapError(mapeado.IEPROErrorID, mapeado.Mensagem, mapeado.ErroI)
            console.log(mapeado.IEPROErrorID + " - " + mapeado.ErroI)
            this.spinner.hide()
          }
        )
    }
    this.spinner.hide()
  }

  verificaValidTouched(campo: string) {

    return !this.formularioCartorio.get(campo).valid && this.formularioCartorio.get(campo).touched

  }

  aplicaCssErro(campo: string) {
    return {
      'is-invalid': this.verificaValidTouched(campo)
    }
  }

  consultaCEP(cep) {

    let enderecoApi = buscadorcep(cep)
      .then(response => response)
      .then(enderecoApi => this.populaDadosForm(enderecoApi, this.formularioCartorio))


  }

  populaDadosForm(enderecoApi, formularioCartorio) {
    this.formularioCartorio.patchValue({
      cidade: enderecoApi.localidade,
      codIBGE: enderecoApi.ibge,
      endereco: enderecoApi.logradouro,
      bairro: enderecoApi.bairro
    })
  }
}
