import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { catchError, take } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { environment } from '../../../environments/environment'
import { CartorioAllDTOModel } from '../../Shared/Models/cartorio-all-dtomodel'
import { DetalheDocumentoIntimacoes } from './detalhe-documento-intimacoes'
import { DetalheProtocoloIntimacoes } from './detalhe-protocolo-intimacoes'
import { DetalheStatusIntimacoes } from './detalhe-status-intimacoes'
import { StatusIntimacoes } from './status-intimacoes.model'

@Injectable({
  providedIn: 'root'
})
export class IntimacoesDataService {
  private readonly API_INTIMADOR = environment.API_IntimadorDigital
  private readonly API_EDITAL = environment.API_EditalOnline
  private endPointData = 'status/senddate/'
  private endPointProtocolo = 'status/protocol/'
  private endPointProtocoloDetalhes = 'Status/Office/ByProtocol/Details'
  private endPointDocumentoDetalhes = 'Status/Office/ByDocument/Details'
  private endPointStatusDataUnica = 'Status/Office/ByDate/Details/'
  private endPointIntervaloData = 'Status/Office/ByDate/Details'
  private endPointCartorioTodos = 'Cartorios/Todos'
  private endPointStatusQuantidadeIepro = 'status/Iepro/Notary/ByDate/?'
  private endPointAdesaoIntimador = 'Cartorios/Intimador/'
  private endPointDataIepro = 'Status/Office/Iepro/ByDate/Details'
  private endPointDocumentoIepro = 'Status/Office/Iepro/ByDocument/Details'
  private endPointProtocoloIepro = 'Status/Office/Iepro/ByProtocol/Details'
  // private endPointConsultaLote = 'Status/protocol/batch'

  constructor(private http: HttpClient) {}

  getBuscaProtocoloData(data) {
    return this.http
      .get<StatusIntimacoes[]>(
        this.API_INTIMADOR + this.endPointData + `${data}`
      )
      .pipe(take(1))
  }
  getBuscaProtocolo(data) {
    return this.http
      .get<StatusIntimacoes[]>(
        this.API_INTIMADOR + this.endPointProtocolo + `${data}`
      )
      .pipe(take(1))
  }

  getBuscaProtocoloDetalhes(data) {
    const params = {
      protocol: data
    }
    return this.http
      .get<DetalheProtocoloIntimacoes[]>(
        this.API_INTIMADOR + this.endPointProtocoloDetalhes,
        { params }
      )
      .pipe(take(1))
  }

  getBuscaDataIepro(idCartorio, dataInicial, dataFinal) {
    const params = {
      officeid: idCartorio,
      startDate: dataInicial,
      endDate: dataFinal
    }
    return this.http
      .get<DetalheStatusIntimacoes[]>(
        this.API_INTIMADOR + this.endPointDataIepro,
        { params }
      )
      .pipe(take(1))
  }

  getBuscaDocumentoIepro(idCartorio, documento) {
    const params = {
      officeid: idCartorio,
      document: documento
    }
    return this.http
      .get<DetalheDocumentoIntimacoes[]>(
        this.API_INTIMADOR + this.endPointDocumentoIepro,
        { params }
      )
      .pipe(take(1))
  }

  getBuscaProtocoloIepro(idCartorio, protocolo) {
    const params = {
      officeid: idCartorio,
      protocol: protocolo
    }
    return this.http
      .get<DetalheProtocoloIntimacoes[]>(
        this.API_INTIMADOR + this.endPointProtocoloIepro,
        { params }
      )
      .pipe(take(1))
  }

  getBuscaDocumentoDetalhes(data) {
    const params = {
      document: data
    }
    return this.http
      .get<DetalheDocumentoIntimacoes[]>(
        this.API_INTIMADOR + this.endPointDocumentoDetalhes,
        { params }
      )
      .pipe(take(1))
  }

  getStatusDetalheDataUnica(data) {
    return this.http
      .get<DetalheStatusIntimacoes[]>(
        this.API_INTIMADOR + this.endPointStatusDataUnica + `${data}`
      )
      .pipe(
        take(1),

        catchError(err => of(err.status))
      )
  }

  getStatusDetalheIntervaloData(dataInicial, dataFinal) {
    const params = {
      startDate: dataInicial,
      endDate: dataFinal
    }
    return this.http
      .get<DetalheStatusIntimacoes[]>(
        this.API_INTIMADOR + this.endPointIntervaloData,
        { params }
      )
      .pipe(take(1))
  }

  getTodosCartorios() {
    return this.http
      .get<CartorioAllDTOModel[]>(this.API_EDITAL + this.endPointCartorioTodos)
      .pipe(take(1))
  }

  getStatusQuantidadeIepro(idCartorio, data) {
    const params = {
      officeid: idCartorio,
      Date: data
    }
    return this.http
      .get<CartorioAllDTOModel[]>(
        this.API_INTIMADOR + this.endPointStatusQuantidadeIepro,
        { params }
      )
      .pipe(take(1))
  }

  putAdesaoIntimador(aceite) {
    const vazio = null
    return this.http.put(
      this.API_EDITAL + this.endPointAdesaoIntimador + aceite,
      vazio
    )
  }

  // getAll(params, protocolo) {
  //   console.log(protocolo)
  //   return this.http.get(this.API_INTIMADOR + this.endPointConsultaLote, { params } + encodeURIComponent(JSON.stringify(criteria)))
  // }

  mesageHttp200(erro) {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: `${erro}`
    })
  }
}
