import { Component, Input, OnInit } from '@angular/core'

@Component({
  selector: 'app-form-control-erro',
  templateUrl: './form-control-erro.component.html',
  styleUrls: ['./form-control-erro.component.scss']
})


export class FormControlErroComponent implements OnInit {
  @Input() msgErro: string
  @Input() mostrarErro: boolean

  constructor () { }

  ngOnInit() {

  }

}
