import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'



const routes: Routes = [
    // { path: '', component: MenuSistemasComponent, canActivate: [AuthGuard] }
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MenuSistemasRoutingModule { }
