import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '../_guards/auth.guard'
import { ListaComponent } from './contatos/lista/lista.component'
import { GerenciamentoComercialComponent } from './gerenciamento-comercial/gerenciamento-comercial/gerenciamento-comercial.component'


const routes: Routes = [
  // { path: 'contatos', loadChildren: () => import('./contatos/contatos.module').then(m => m.ContatosModule) },
  // { path: 'gerenciamento-comercial', loadChildren: () => import('./gerenciamento-comercial/gerenciamento-comercial.module').then(m => m.GerenciamentoComercialModule) }
  // { path: '', pathMatch: 'full', redirectTo: 'gerenciamento-comercial', canActivate: [AuthGuard] },

  { path: 'gerenciamento-comercial', component: GerenciamentoComercialComponent, canActivate: [AuthGuard] },
  { path: 'contatos', component: ListaComponent, canActivate: [AuthGuard] },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IeproCrmRoutingModule { }
