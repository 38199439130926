import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { AlterarLoginComponent } from '../alterar-login/alterar-login/alterar-login.component'
import { AlterarPassComponent } from '../alterar-pass/alterar-pass/alterar-pass.component'
import { CompLoginComponent } from './comp-login/comp-login.component'
import { FormLoginComponent } from './form-login/form-login.component'
import { PageLoginComponent } from './page-login/page-login.component'
import { UserService } from './user.service'


@NgModule({
  imports: [
    CommonModule,
    FormsModule

  ],
  declarations: [
    CompLoginComponent,
    PageLoginComponent,
    FormLoginComponent,
    AlterarLoginComponent,
    AlterarPassComponent

  ],
  exports: [
    CompLoginComponent,
    PageLoginComponent,
  ],

  providers: [
    UserService
  ],


})
export class LoginModule { }
