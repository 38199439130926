import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EdicaousuarioComponent } from './edicaousuario/edicaousuario.component';
import { FormsModule }   from '@angular/forms';

@NgModule({
  declarations: [EdicaousuarioComponent],
  imports: [
    CommonModule,
    FormsModule
  ]
})
export class EdicaoUsuarioModule { }
