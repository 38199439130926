/* eslint-disable angular/document-service */
/* eslint-disable angular/window-service */
import { HttpClient } from '@angular/common/http'
import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { ConfigDtoModel } from 'src/app/Shared/Models/config-dto-model'
import { Session } from 'src/app/Shared/session'
import { HollidayModel } from '../../../../Shared/Models/holliday-model'

@Component({
  selector: 'app-config-gerais',
  templateUrl: './config-gerais.component.html',
  styleUrls: ['./config-gerais.component.scss']
})
export class ConfigGeraisComponent implements OnInit {
  configs: ConfigDtoModel
  feriados // : Array<HollidayModel>;
  alterarFeriados = false
  modalRef: BsModalRef
  dataInclusao: string
  descricao: string
  recorrente: boolean

  constructor(
    private router: Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private modalService: BsModalService
  ) {}

  @ViewChild('diasPublicacao', { static: false }) diasPublicacao: ElementRef
  @ViewChild('horaInicial', { static: false }) horaInicial: ElementRef
  @ViewChild('minInicial', { static: false }) minInicial: ElementRef
  @ViewChild('horaFinal', { static: false }) horaFinal: ElementRef
  @ViewChild('minFinal', { static: false }) minFinal: ElementRef

  ngOnInit() {
    this.spinner.show()

    this.loadinfos()

    //this.loadChecks();
  }

  async loadinfos() {
    await ApiController.getConfig(this.http).then(
      sucesso => {
        var mapeado = <ConfigDtoModel>sucesso

        this.diasPublicacao.nativeElement.selectedIndex =
          mapeado.validNewsTimeInDays
        this.horaInicial.nativeElement.selectedIndex = mapeado.postXMLStartHour
        this.minInicial.nativeElement.selectedIndex = mapeado.postXMLStartMin
        this.horaFinal.nativeElement.selectedIndex = mapeado.postXMLEndHour
        this.minFinal.nativeElement.selectedIndex = mapeado.postXMLEndMin

        this.spinner.hide()
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )

    this.spinner.show()

    await this.getFeriadosReload()
  }

  async getFeriadosReload() {
    await ApiController.getFeriados(this.http).then(
      sucesso => {
        this.feriados = <HollidayModel>sucesso
        this.loadChecks()
        this.spinner.hide()
      },
      err => {
        console.log(err)
        this.spinner.hide()
      }
    )
  }
  async atualizar() {
    this.spinner.show()

    await this.getInfos()
    var shared = Session.getInstance()

    await ApiController.putConfig(this.http, this.configs).then(
      sucesso => {
        this.spinner.hide()
        this.alertService.success('Configurações alteradas com sucesso!')
        this.router.navigate([shared.getHomePage()])
      },
      err => {
        this.spinner.hide()

        this.alertService.danger('Falha ao realizar aterações!')
        this.router.navigate([shared.getHomePage()])
      }
    )
  }

  async changeFeriados() {
    this.alterarFeriados = true
    window.scroll(0, 0)

    this.loadChecks()
  }

  adicionarFeriado() {
    let objFeriado = new HollidayModel()
    objFeriado.data = this.dataInclusao
    objFeriado.descricao = this.descricao
    objFeriado.recorrente = this.recorrente

    if (objFeriado.data != '' && objFeriado.descricao != '') {
      ApiController.postFeriado(this.http, objFeriado).then(
        sucesso => {
          this.alertService.success('Feriado adicionado com sucesso!')
          this.getFeriadosReload()
          this.modalRef.hide()
          this.spinner.hide()
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
    } else {
      this.spinner.hide()
      this.alertService.warning(
        'Preencha todos os campos obrigatórios para inclusão do feriado...'
      )
    }
  }

  async removerFeriado(id) {
    this.spinner.show()

    await ApiController.deleteFeriado(this.http, id).then(
      sucesso => {
        this.alertService.success('Feriado removido com sucesso!')
        this.getFeriadosReload()
      },
      err => {
        console.log(err)
        this.spinner.hide()
      }
    )
  }

  async getInfos() {
    this.configs = new ConfigDtoModel()
    this.configs.postXMLStartHour = this.horaInicial.nativeElement.value
    this.configs.postXMLStartMin = this.minInicial.nativeElement.value
    this.configs.postXMLEndHour = this.horaFinal.nativeElement.value
    this.configs.postXMLEndMin = this.minFinal.nativeElement.value
    this.configs.validNewsTimeInDays = this.diasPublicacao.nativeElement.value
    this.configs.apiGroup = 5 // fixado no back e no front até que seja criado a configuração
  }

  async loadChecks() {
    await ApiController.delay(500)

    const checkboxes = document.querySelectorAll('input.form-check-input')
    for (let index = 0; index < checkboxes.length; index++) {
      const element = checkboxes[index] as HTMLInputElement
      this.feriados.forEach(feriado => {
        if (element.id == feriado.id && feriado.recorrente) {
          element.checked = true
        }
      })
    }
    this.spinner.hide()
  }

  cancelar() {
    var shared = Session.getInstance()
    this.router.navigate([shared.getHomePage()])
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template)
  }
}
