import { Component, OnInit } from '@angular/core'
import { AccordionConfig } from 'ngx-bootstrap/accordion'
import { defineLocale } from 'ngx-bootstrap/chronos'
import { BsLocaleService } from 'ngx-bootstrap/datepicker'
import { deLocale } from 'ngx-bootstrap/locale'
import { DetalheStatusIntimacoes } from '../../../shared/detalhe-status-intimacoes'
import { IntimacoesDataService } from '../../../shared/intimacoes-data.service'

defineLocale('pt-br', deLocale)

export function getAccordionConfig(): AccordionConfig {
  return Object.assign(new AccordionConfig(), { closeOthers: true })
}

@Component({
  selector: 'app-status-intimacoes-detalhe',
  templateUrl: './status-intimacoes-detalhe.component.html',
  styleUrls: ['./status-intimacoes-detalhe.component.scss'],
  providers: [{ provide: AccordionConfig, useFactory: getAccordionConfig }]

})
export class StatusIntimacoesDetalheComponent implements OnInit {

  public data: Date
  dataInicial = new Date();
  bsRangeValue: Date[]
  dataFinal = new Date();
  public locale = 'pt-br';
  public dataFormatada
  public detalheIntimacoes: DetalheStatusIntimacoes[]
  public totalIntimacoes: any = ''
  public statusIntimacoes: any
  public _detalheIntimacoes = new DetalheStatusIntimacoes()
  public email_RetornoSucesso: any
  public telaDataUnica: boolean = false
  disabled = false;
  semNotificacao: any


  // @ViewChild('myaccordion', { static: true }) accordion: NgbAccordion;
  constructor (private localeService: BsLocaleService, private dataService: IntimacoesDataService ) { }

  ngOnInit() {

  }

  onValueChange(value: Date): void {
    this.localeService.use(this.locale)
    this.data = value
  }

  alteraBuscaPorData() {
    this.telaDataUnica = !this.telaDataUnica
  }

  buscar() {
    this._detalheIntimacoes = new DetalheStatusIntimacoes()
    this.dataFormatada = this.data.toLocaleDateString('fr-CA')
    this.dataService.getStatusDetalheDataUnica(this.dataFormatada).subscribe(
      dados => {
        this.detalheIntimacoes = dados

        const objectArray = Object.entries(this.detalheIntimacoes)
        objectArray.forEach(([key, value]) => {
          this._detalheIntimacoes.email_sucessos = value.email_sucessos
          this._detalheIntimacoes.email_falhas = value.email_falhas
          this._detalheIntimacoes.sms_falhas = value.sms_falhas
          this._detalheIntimacoes.sms_sucessos = value.sms_sucessos
          this._detalheIntimacoes.total_falhas_email = value.total_falhas_email
          this._detalheIntimacoes.total_falhas_sms = value.total_falhas_sms
          this._detalheIntimacoes.total_falhas_whats = value.total_falhas_whats
          this._detalheIntimacoes.total_sucessos_email = value.total_sucessos_email
          this._detalheIntimacoes.total_sucessos_sms = value.total_sucessos_sms
          this._detalheIntimacoes.total_sucessos_whats = value.total_sucessos_whats
          this._detalheIntimacoes.whatsapp_falhas = value.whatsapp_falhas
          this._detalheIntimacoes.whatsapp_sucessos = value.whatsapp_sucessos
        })
        this.semNotificacao = dados
        console.log(dados)
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)
      }
    )
  }

  statusDetalhe() {
    // this.dataFinal.setDate(this.dataFinal.getDate() + 7)
    this.dataInicial = this.data[0]
    this.dataFinal = this.data[1]
    this.dataService.getStatusDetalheIntervaloData(this.dataInicial.toLocaleDateString('fr-CA'), this.dataFinal.toLocaleDateString('fr-CA'))
      .subscribe(dados => {
        this.totalIntimacoes = dados
        this.semNotificacao = dados
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)
      })
  }

  // beforeChange($event: NgbPanelChangeEvent) {
  //   console.log($event.panelId)
  //   if ($event.panelId === 'panelOne') {
  //     $event.preventDefault()
  //   }

  //   if ($event.panelId === 'panelTwo' && $event.nextState === false) {
  //     $event.preventDefault()
  //   }
  // }

  // togglePanel(id) {
  //   this.accordion.toggle(id)
  // }

}
