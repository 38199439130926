import { environment } from '../environments/environment'


export const urlAcesso = environment.API_EditalOnline
export const urlIdentity = environment.IDENTITY_LOGIN

// Get
export const constGetEditaisbyDate = "Publicacoes?date="
export const constGetAllPublications = "Publicacoes/Todas"
export const constGetMyPermission = "Permissoes/MinhasPermissoes"
export const constGetSecurePass = "SenhaSegura"
//export const constGetPublicacoesbyId = "Publicacoes/All?idUsuario=";
export const constGetPublicacaoCartorio = "Publicacoes/NaoPublicado"
export const constGetPublicacoesUsuario = "Publicacoes/Usuario"
export const constGetPublicacoesAgendadas = "Publicacoes/Agendamentos/Todos"
export const constGetPublicacoesAgendadaId = "Publicacoes/Agendamentos/Arquivo/" // + id
export const constGetDadosCartorio = "Cartorios"
export const constGetListaCartorio = "Cartorios/Todos"
export const constGetDadosCartorioId = "Cartorio?id="
export const constGetDadosUsuarioCompleto = "Usuarios/Completo"
export const constGetDadosCartorioCompleto = "Cartorios/Completo"
export const constGetHistoricoRemessas = "Remessas/Cartorio"
export const constGetHistoricoRemessasId = "Remessas/Cartorio?codCart="
export const constGetUltimaPublicacao = "Publicacoes/Ultima"
export const constGetDadosLoginId = "Usuarios?idCart="
export const constGetDadosLogin = "Usuarios"
export const constGetCartorioIdUsuario = "Cartorios/Usuario"
export const constGetXMLtoDownload = "Remessas/File"
export const constGetPDFtoDownload = "Publicacoes/File/"
export const constGetListGrupos = "Grupos/Lista"
export const constGetListPermissoes = "Permissoes"
export const constGetListPermissoesGrupo = "Permissoes/Grupo/"
export const constGetUsersGrupo = "Grupos/" // id / Usuarios;
export const constGetUsersAll = "Usuarios/Lista"
export const constGetUsersbyCart = "Usuarios/Cartorio/" // + id;
export const constGetCartorioLetra = "Cartorios/Cidade/Letra/" // + letra
export const constGetConfig = "Configuracoes"
export const constGetFeriados = "Feriados/Todos"
export const constGetUsuarioApi = "Usuarios/API"
export const constGetEsqueciSenha = "Usuarios/EsqueciMinhaSenha?email=" // + email

// Relatorios
export const constGetRemessasbyDate = "Remessas/Report?dateTime="
export const constGetRemessasbyDates = "Remessas/Report?cartId=" // &dInicio="&dFim=
export const constGetTitulosbyDate = "Devedores/Report?cartId="
export const constGetTitulosbyDates = "Devedores/Relatorio?idCartorio="  // &dInicio="&dFim=

// Post
export const constPostPublicacaoRemessa = "Remessas"
export const constPostPublicacaoIepro = "Publicacoes"
export const constPostPublicacaoAgendada = "Publicacoes/Agendamentos/Add/" // + data
export const constPostLogin = "Login"
export const constPostCriarGrupo = "Grupos"
export const constPostCriarcartorio = "Cartorios"
export const constPostCriaUsuario = "Usuarios"
export const constPostFeriado = "Feriados"
export const constPostCriaUsuarioAPI = "Usuarios/API"

// Put
export const constPutCartorios = "Cartorio"
export const constPutUsuarios = "Usuarios"
export const constPutAgendamentos = "Publicacoes/Agendamentos"
export const constPutAlterarSenha = "Usuario/AlterarSenha"
export const constPutPermissaoGrupo = "Permissoes/Grupo/" // + id
export const constPutUsuariosGrupo = "Grupos/" // + id + /Usuarios
export const constPutUsuarioGrupo = "Grupos/" // + id + /idusuario
export const constPutNovaSenha = "Usuarios/NovaSenha?id="
export const constPutConfig = "Configuracoes"

// Delete
export const constDeleteRemessas = "Remessas?id="
export const constDeletePublicacoes = "Publicacoes?id="
export const constDeleteGrupo = "Grupos/" // +id
export const constDeleteFeriado = "Feriados/" // +id
export const constDeleteAgendamento = "Publicacoes/Agendamentos/" //+id