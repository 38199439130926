/* eslint-disable angular/document-service */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import { Session } from 'src/app/Shared/session'
import { ApiController } from '../../../../Core/api-controller'
import { CartorioAllDTOModel } from '../../../../Shared/Models/cartorio-all-dtomodel'
import {
  PublicationDTOModel,
  PublicationDTOModelView
} from '../../../../Shared/Models/publication-dtomodel'
import {
  RemessaDTOModel,
  valorModelString
} from '../../../../Shared/Models/remessa-dtomodel'
import { UserChangeModel } from '../../../../Shared/Models/user-change-model'

@Component({
  selector: 'app-gestao-conteudo',
  templateUrl: './gestao-conteudo.component.html',
  styleUrls: ['./gestao-conteudo.component.scss']
})
export class GestaoConteudoComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) {}
  shared: Session
  publicacoes
  cartorios
  usuario: UserChangeModel
  loading = false
  tipoP = false
  tipoC = false
  tipoM
  tipoS = false
  exibir
  that
  pubExibir = false

  async ngOnInit() {
    await this.loadpage()
  }

  async loadpage() {
    this.spinner.show()
    this.shared = Session.getInstance()
    this.usuario = new UserChangeModel()

    if (
      this.shared.getPermissao().PostXMLFile &&
      !this.shared.getPermissao().ManagePublication &&
      !this.shared.getPermissao().ManageSecurity
    ) {
      this.tipoC = true
      this.getHistoricoRemessas()
    } else if (
      this.shared.getPermissao().ManagePublication &&
      !this.shared.getPermissao().PostXMLFile
    ) {
      this.tipoP = true
      this.getHistoricoPublicacoes()
    } else if (this.shared.getPermissao().ManageOffice) {
      this.tipoS = true
      this.getCartorios()
    }
  }
  async atualizar() {
    await this.loadpage()
  }

  async loadFields() {
    ApiController.getDadosLogin(this.http).then(
      sucesso => {
        this.usuario = <UserChangeModel>sucesso
        if (this.usuario.login.length >= 15) {
          this.usuario.login = this.usuario.login.slice(0, 13) + '...'
        }
      },
      err => {
        this.usuario = new UserChangeModel()
        console.log(err)
      }
    )
  }

  async getCartorios() {
    this.spinner.show()

    this.cartorios = new Array<CartorioAllDTOModel>()
    ApiController.getListaCartorios(this.http).then(sucesso => {
      var mapeado = <CartorioAllDTOModel[]>sucesso
      this.cartorios = mapeado
      //console.log(this.cartorios);
      this.spinner.hide()
    })
  }

  async getHistoricoPublicacoes() {
    this.publicacoes = new Array<PublicationDTOModelView>()
    ApiController.getHistoricoPublicacoes(this.http).then(
      sucesso => {
        var retorno = <PublicationDTOModel[]>sucesso

        retorno.forEach(element => {
          var dtoview = new PublicationDTOModelView()

          dtoview.id = element.id
          dtoview.dataHoraEnvio = element.dataHoraEnvio

          if (element.expirado) {
            dtoview.expirado = 'Publicação Expirada'
          } else {
            dtoview.expirado = 'Publicação Ativa'
          }

          this.publicacoes.push(dtoview)
        })

        this.spinner.hide()
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  async getHistoricoRemessas() {
    ApiController.getHistoricorRemessas(this.http).then(
      sucesso => {
        this.spinner.hide()
        var hh = new Array<RemessaDTOModel>()
        var alterando = <RemessaDTOModel[]>sucesso

        alterando.forEach(element => {
          if (element.status == 'Enviada') {
            element.status = 'Erro na remessa'
          }
          hh.push(element)
        })

        this.publicacoes = hh
        //this.publicacoes
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  uploadFiles() {
    var shared = Session.getInstance()
    this.shared.setCartId(null)
    this.router.navigate(['/UploadFile'])
  }

  gestaoConteudo() {
    var shared = Session.getInstance()
    this.shared.setCartId(null)
    this.router.navigate([this.shared.getHomePage()])
  }

  changePass() {
    var shared = Session.getInstance()
    this.shared.setCartId(null)

    this.router.navigate(['/ChangePass'])
  }

  changeLogin() {
    var shared = Session.getInstance()
    this.shared.setCartId(null)

    this.router.navigate(['/ChangeLogin'])
  }

  async editCartorio() {
    var shared = Session.getInstance()
    this.shared.setCartId(null)

    this.router.navigate(['/EditCartorio'])
  }

  async securePass() {
    this.router.navigate(['/SenhaSegura'])
  }

  logOut() {
    localStorage.removeItem('currentUser')
    this.router.navigate([this.shared.getHomePage()])
  }

  carrega() {
    this.pubExibir = true
  }

  editDados(id) {
    var shared = Session.getInstance()
    this.shared.setCartId(id)

    this.router.navigate(['/EditCartorio'])
  }

  editLogin(id) {
    var shared = Session.getInstance()
    this.shared.setCartId(id)

    this.router.navigate(['/ChangeLogin'])
  }

  editPass(id) {
    var shared = Session.getInstance()
    this.shared.setCartId(id)

    this.router.navigate(['/ChangePass'])
  }

  removerRemessa(id) {
    this.spinner.show()
    ApiController.deleteRemessas(this.http, id).then(
      sucesso => {
        this.getHistoricoRemessas()
        this.alertService.success('Remessa removida com sucesso!')
      },
      err => {
        var mapeado = <ErrorModel>err.error
        var erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(mapeado.IEPROErrorID + ' - ' + mapeado.ErroI)
        this.spinner.hide()
        //alert("Falha ao deletar remessa - " + erromapedo);
      }
    )
  }

  removerPublicacao(id) {
    this.spinner.show()
    ApiController.deletePublicacao(this.http, id).then(
      sucesso => {
        this.alertService.success('Publicação removida com sucesso.')
        this.getHistoricoPublicacoes()
      },
      err => {
        var mapeado = <ErrorModel>err.error
        var erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(mapeado.IEPROErrorID + ' - ' + mapeado.ErroI)
        this.spinner.hide()
        //alert("Falha ao deletar publicação - " + erromapedo);
      }
    )
  }

  downloadXML(id) {
    this.spinner.show()
    ApiController.getXMLtoDownload(id, this.http).then(
      sucesso => {
        var mapeado = <valorModelString>sucesso

        var blob = new Blob([atob(mapeado.valor)], { type: 'application/xml' })
        var objectUrl = URL.createObjectURL(blob)
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none')
        a.href = objectUrl
        a.download = 'remessaId-' + id
        a.click()

        this.spinner.hide()
      },
      err => {
        console.log(err)
        var mapeado = <ErrorModel>err.error
        var erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(mapeado.IEPROErrorID + ' - ' + mapeado.ErroI)
        this.spinner.hide()
        //alert("Falha ao realizar download de XML - " + erromapedo);
      }
    )
  }

  downloadPDF(id) {
    this.spinner.show()
    ApiController.getPDFtoDownload(id, this.http).then(
      sucesso => {
        var mapeado = <valorModelString>sucesso

        var blob = new Blob([mapeado.valor], { type: 'application/pdf' })
        var objectUrl = URL.createObjectURL(blob)
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none')
        a.href = objectUrl
        a.download = 'publicacao-' + id
        a.click()

        this.spinner.hide()
      },
      err => {
        console.log(err)
        var mapeado = <ErrorModel>err.error
        var erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(mapeado.IEPROErrorID + ' - ' + mapeado.ErroI)
        this.spinner.hide()
        //alert("Falha ao realizar download de PDF - " + erromapedo);
      }
    )
  }

  reportRemessa() {
    this.router.navigate(['/viewRemessas'])
  }

  reportTitulos() {
    this.router.navigate(['/viewTitulos'])
  }

  getViewRemessa(id) {
    var shared = Session.getInstance()
    this.shared.setCartId(id)
    this.router.navigate(['/viewRemessaSuporte'])
  }

  getViewTitulos(data) {
    var shared = Session.getInstance()
    this.shared.setRemessa(data)

    this.router.navigate(['/viewTitulos'])
  }

  downloadPublicacoes() {
    this.router.navigate(['/downloadPublicacoes'])
  }

  findCart(letra) {
    this.spinner.show()
    ApiController.getFindCartorioLetra(this.http, letra).then(
      sucesso => {
        var mapeado = <CartorioAllDTOModel[]>sucesso
        this.cartorios = mapeado
        this.spinner.hide()
      },
      err => {
        console.log(err)
        var mapeado = <ErrorModel>err.error
        var erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(erromapedo)
        this.spinner.hide()
      }
    )
  }

  createUser(id) {
    this.shared.setCartId(id)
    this.router.navigate(['/cadastroUsuario'])
  }
}
