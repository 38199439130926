/* eslint-disable angular/timeout-service */
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import Swal from 'sweetalert2'
import { ApiController } from '../Core/api-controller'
import { ErrorModel } from '../Shared/Models/error-model'
import { AuthenticationService } from '../_services/authentication.service'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  errorMessage = ''
  erromapedo
  mapeado
  public href: string = ''

  constructor(
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt accessToken if available
    let currentUser = JSON.parse(localStorage.getItem('currentUser'))
    if (currentUser && currentUser.accessToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.accessToken}`
        }
      })
    }

    //return next.handle(request);
    return next.handle(request).pipe(
      catchError(err => {
        this.mapeado = <ErrorModel>err.error
        this.erromapedo = ApiController.mapError(
          this.mapeado.IEPROErrorID,
          this.mapeado.Mensagem,
          this.mapeado.ErroI
        )
        console.log(this.mapeado.IEPROErrorID)

        if (this.mapeado.IEPROErrorID != undefined) {
          if (err.status === 401) {
            this.href = this.router.url
            console.log(this.router.url)
            this.errorMessage = 'Usuário sem permissão...'
            // auto logout if 401 response returned from api
            this.authenticationService.logout()
            setTimeout(function () {
              location.reload()
            }, 2000)
          } else {
            this.errorMessage = this.erromapedo
            // this.authenticationService.logout()
            setTimeout(function () {
              location.reload()
            }, 2000)
          }
        }
        if (this.mapeado.IEPROErrorID === undefined) {
          if (err.status === 401) {
            this.errorMessage = 'Usuário ou senha inválidos'
            // auto logout if 401 response returned from api
            this.authenticationService.logout()
            setTimeout(function () {
              location.reload()
            }, 2000)
          } else {
            switch (err.status) {
              case 0:
                this.errorMessage =
                  'Tivemos um erro por aqui, tente novamente e caso o erro persista, volte mais tarde!'
                break
              case 400:
                this.errorMessage = err.error.Title
                break
              case 401:
                this.errorMessage = err.error.Title
                break
              case 403:
                this.errorMessage = err.error.Title
                break
              case 415:
                this.errorMessage = err.error.Title
                break
              case 500:
                this.errorMessage = err.error.Title
                break
              default:
                this.errorMessage = err.error.Title
                break
            }
          }
        }

        //TODO Comentamos este trecho pois achamos que não há necessidade: André Mello & Iury Cardoso
        // else {
        //     switch (err.status) {
        //         case 0:
        //             this.errorMessage = 'Tivemos um erro por aqui, tente novamente e caso o erro persista, volte mais tarde!'
        //             break
        //         case 400:
        //             this.errorMessage = err.error.message
        //             break
        //         case 401:
        //             this.errorMessage = err.error.message
        //             break
        //         case 500:
        //             this.errorMessage = err.error.message
        //             break
        //         default:
        //             this.errorMessage = err.error.message
        //             break
        //     }
        // }

        return Observable.throw(
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${this.errorMessage}`
          })
        )
      })
    )
  }
}
