export class CartorioModel {

    id :  number;

    descricao  : string;
    
    cnpj : string;

    responsavel : string;
    
    documentoResponsavel : string;

    endereco: string;

    bairro : string;
   
    email : string;

    telefone : string;

    cep :  string;

    comarca: string
}

export class CartorioCadastroModel {

    cidade : string;

    descricao  : string;
    
    cnpj : string;

    responsavel : string;
    
    documentoResponsavel : string;
   
    email : string;
    
    codIBGE : number;

    endereco: string;

    bairro : string;

    telefone : string;

    cep :  string;

    comarca: string

}

