import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { defineLocale } from 'ngx-bootstrap/chronos'
import { BsLocaleService } from 'ngx-bootstrap/datepicker'
import { deLocale } from 'ngx-bootstrap/locale'
import { Observable } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { CartorioAllDTOModel } from './../../../../Shared/Models/cartorio-all-dtomodel'
import { IntimacoesDataService } from '../../../shared/intimacoes-data.service'
defineLocale('pt-br', deLocale)
@Component({
  selector: 'app-status-intimacoes-iepro',
  templateUrl: './status-intimacoes-iepro.component.html',
  styleUrls: ['./status-intimacoes-iepro.component.scss']
})
export class StatusIntimacoesIeproComponent implements OnInit {

  public data: Date
  dataInicial = new Date();
  bsRangeValue: Date[]
  dataFinal = new Date();
  public locale = 'pt-br';
  public idCartorio: any
  public _cartorios: any
  public _detalheIntimacoes = new CartorioAllDTOModel()
  public cartorios: CartorioAllDTOModel[]
  public totalIntimacoes: any

  constructor (public statusDataService: IntimacoesDataService, private http: HttpClient, private localeService: BsLocaleService) { }

  ngOnInit() {
    this.buscarCartorio()
  }

  onValueChange(value: Date): void {
    this.localeService.use(this.locale)
    this.data = value
    console.log(this.data)
  }


  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.cartorios.filter(v => v.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: { descricao: string }) => x.descricao;

  buscarCartorio() {
    // this._detalheIntimacoes = new CartorioAllDTOModel()

    this.statusDataService.getTodosCartorios().subscribe(
      dados => {
        this.cartorios = dados
      }
    )
  }

  statusDetalhe() {
    // this.dataFinal.setDate(this.dataFinal.getDate() + 7)
    console.log(this.idCartorio.id)
    this.statusDataService.getStatusQuantidadeIepro(this.idCartorio.id, this.data.toLocaleDateString('fr-CA'))
      .subscribe(dados => {
        this.totalIntimacoes = dados
        console.log(this.totalIntimacoes)
      })
  }

}
