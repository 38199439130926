import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiController } from 'src/app/Core/api-controller';
import { valorModelString } from 'src/app/Shared/Models/remessa-dtomodel';
import { Session } from 'src/app/Shared/session'

@Component({
  selector: 'app-secure-pass',
  templateUrl: './secure-pass.component.html',
  styleUrls: ['./secure-pass.component.scss']
})
export class SecurePassComponent implements OnInit {

  securePass: string;
  showSecurePass : boolean  = false;
  session: Session

  constructor(private http : HttpClient, private router : Router, private spinner: NgxSpinnerService) { }

  ngOnInit() { 
    this.session = Session.getInstance()
  }

  getSecurePass() {
    this.spinner.show();
    ApiController.getSecurePass(this.http)
      .then( sucesso => {
        var pass = <valorModelString> sucesso;
        this.showSecurePass = true;
        this.securePass = '"' + pass.valor + '"';
        this.spinner.hide();
      },
      err => {
        this.spinner.hide();
      }
    )
  }
}
