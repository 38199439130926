import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewRemessaSuporteComponent } from './view-remessa-suporte/view-remessa-suporte.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter'

@NgModule({
  declarations: [ViewRemessaSuporteComponent],
  imports: [
    CommonModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  exports : [
    ViewRemessaSuporteComponent
  ]
})
export class ViewRemessaSuporteModule { }
