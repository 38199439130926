import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { defineLocale } from 'ngx-bootstrap/chronos'
import { BsLocaleService } from 'ngx-bootstrap/datepicker'
import { deLocale } from 'ngx-bootstrap/locale'
import { Observable } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { CartorioAllDTOModel } from 'src/app/Shared/Models/cartorio-all-dtomodel'
import { DetalheStatusIntimacoes } from './../../shared/detalhe-status-intimacoes'
import { IntimacoesDataService } from './../../shared/intimacoes-data.service'

defineLocale('pt-br', deLocale)

@Component({
  selector: 'app-relatorio-iepro-data',
  templateUrl: './relatorio-iepro-data.component.html',
  styleUrls: ['./relatorio-iepro-data.component.scss']
})
export class RelatorioIeproDataComponent implements OnInit {

  public locale = 'pt-br';
  public data: Date
  dataInicial = new Date();
  dataFinal = new Date();
  bsValue = new Date();
  bsRangeValue: Date[];
  firstDate = new Date();
  public idCartorio: any
  public detalheIntimacoes: DetalheStatusIntimacoes[]
  public dataFormatada
  public cartorios: CartorioAllDTOModel[]
  semNotificacao: any
  page = ""
  active = ''

  constructor(
    private localeService: BsLocaleService,
    public statusDataService: IntimacoesDataService,
    private http: HttpClient,
    private dataService: IntimacoesDataService) {
    this.firstDate.setDate(this.firstDate.getDate() - 1);
    this.bsRangeValue = [this.firstDate, this.bsValue];
   }

  ngOnInit() {
    this.buscarCartorio()
  }

  onValueChange(value: Date): void {
    this.localeService.use(this.locale)
    this.data = value
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.cartorios.filter(v => v.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: { descricao: string }) => x.descricao;

  buscarCartorio() {
    this.statusDataService.getTodosCartorios().subscribe(
      dados => {
        this.cartorios = dados
      }
    )
  }

  buscar() {
    this.detalheIntimacoes = null
    this.dataInicial = this.data[0]
    this.dataFinal = this.data[1]
    this.dataService.getBuscaDataIepro(this.idCartorio.id, this.dataInicial.toLocaleDateString('fr-CA'), this.dataFinal.toLocaleDateString('fr-CA'))
      .subscribe(dados => {
        this.detalheIntimacoes = dados
        this.semNotificacao = dados
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)
      }
    )
    this.active = ''
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.page = "1"
  }

}
