import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tabela-atendimento',
  templateUrl: './tabela-atendimento.component.html',
  styleUrls: ['./tabela-atendimento.component.scss']
})
export class TabelaAtendimentoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
