import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditaComponent } from './edita/edita.component';
import { ListaComponent } from './lista/lista.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap'
import { AngularFireAuthModule } from 'angularfire2/auth'
import { AngularFireModule } from 'angularfire2/index'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { NgpSortModule } from "ngp-sort-pipe"
import { NgxMaskModule } from 'ngx-mask'
import { NgxPaginationModule } from 'ngx-pagination'
import { FirebaseConfig } from './../../../environments/firebase.config'
// import { ContatosRoutingModule } from './contatos-routing.module'


@NgModule({
  declarations: [
    EditaComponent,
    ListaComponent
  ],
  imports: [
    CommonModule,
    AngularFireAuthModule,
    // ContatosRoutingModule,
    AngularFireModule.initializeApp(FirebaseConfig),
    NgxPaginationModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule,
    NgbCollapseModule,
    NgpSortModule,
    NgxMaskModule.forRoot()
  ]
})
export class ContatosModule { }
