/* eslint-disable angular/document-service */
import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from '../../../../Core/api-controller'
import { ErrorModel } from '../../../../Shared/Models/error-model'
import { PermissaoDTOModel } from '../../../../Shared/Models/group-dtomodel'
import { Session } from '../../../../Shared/session'

@Component({
  selector: 'app-edit-permissoes',
  templateUrl: './edit-permissoes.component.html',
  styleUrls: ['./edit-permissoes.component.scss']
})
export class EditPermissoesComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) {}

  permissoesAtuais
  permissoes
  public shared: Session
  tipoM
  listaok = false

  @ViewChild('check', { static: false }) check: ElementRef

  async ngOnInit() {
    this.spinner.show()
    this.shared = Session.getInstance()

    if (this.shared.getPermissao().ManageSecurity) {
      this.tipoM = true
      if (this.shared.getEdit()) {
        await this.loadPermissoes()
        await this.loadPermissoesGrupo(true)
        this.listaok = true
        this.loadChecks()
      } else {
        await this.loadPermissoesGrupo(false)
        this.listaok = true
      }
    } else {
      this.shared.setCartId(null)
      this.router.navigate([this.shared.getHomePage()])
    }
  }

  async loadPermissoes() {
    ApiController.getListPermissoes(this.http).then(
      sucesso => {
        var mapeado = <PermissaoDTOModel[]>sucesso
        this.permissoes = mapeado
      },
      err => {
        var mapeado = <ErrorModel>err
        this.alertService.danger(mapeado.ErroI + ' ' + mapeado.Mensagem)
        console.log(mapeado)
      }
    )
  }

  async loadPermissoesGrupo(edit) {
    ApiController.getListPermissoesGrupo(
      this.http,
      this.shared.getGrupoId()
    ).then(
      sucesso => {
        var mapeado = <PermissaoDTOModel[]>sucesso
        if (edit) this.permissoesAtuais = mapeado
        else {
          this.permissoes = mapeado
        }
      },
      err => {
        var mapeado = <ErrorModel>err
        this.alertService.danger(mapeado.ErroI + ' ' + mapeado.Mensagem)
        console.log(mapeado)
      }
    )
  }

  async loadChecks() {
    await ApiController.delay(500)
    const checkboxes = document.querySelectorAll('input.form-check-input')
    for (let index = 0; index < checkboxes.length; index++) {
      const element = checkboxes[index] as HTMLInputElement
      this.permissoesAtuais.forEach(permissaoatual => {
        if (element.id == permissaoatual.id) {
          element.checked = true
        }
      })
    }
    this.spinner.hide()
  }

  async atualizar() {
    const checkboxes = document.querySelectorAll('input.form-check-input')
    var hh = Array<Number>()
    checkboxes.forEach(element => {
      const checkbox = element as HTMLInputElement
      if (checkbox.checked) {
        hh.push(parseInt(checkbox.id))
      }
    })

    ApiController.putPermissaoGrupo(this.http, hh).then(
      sucesso => {
        this.alertService.success('Alterações realizadas com sucesso.')
        if (this.shared.getNovo()) this.router.navigate(['/editUsersGrupo'])
        else this.router.navigate(['/listGroup'])
      },
      err => {
        console.log('Falha ao alterar permissoes...')
        var mapeado = <ErrorModel>err
        this.alertService.danger(mapeado.ErroI + ' - ' + mapeado.Mensagem)
      }
    )
  }

  cancelar() {
    this.router.navigate(['/listGroup'])
  }
}
