/* eslint-disable angular/window-service */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { Session } from 'src/app/Shared/session'
import { ApiController } from '../../../../Core/api-controller'
import { RemessaReport } from '../../../../Shared/Models/remessa-dtomodel'
import { throwError, observable } from 'rxjs'

@Component({
  selector: 'app-relatorio-remessa',
  templateUrl: './relatorio-remessa.component.html',
  styleUrls: ['./relatorio-remessa.component.scss']
})
export class RelatorioRemessaComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private router: Router
  ) {}
  shared: Session
  remessas
  // data: Object;
  listaok
  data: Date

  //this.dataSelecionadaClick.emit(this.calendario.nativeElement.value);

  totalTitulos = 0

  ngOnInit() {
    this.shared = Session.getInstance()
  }

  onValueChange(value: Date): void {
    this.data = value
    // console.log(this.data)
  }

  novoRelatorio() {
    let newdate = this.data
    //(newdate);
    this.getViewRemessa(newdate)
  }

  cancelar() {
    this.router.navigate([this.shared.getHomePage()])
  }

  async getViewRemessa(data) {
    this.totalTitulos = 0
    this.spinner.show()

    ApiController.getViewRemessasbyDate(data, this.http).then(
      sucesso => {
        this.spinner.hide()
        this.remessas = <RemessaReport>sucesso

        this.remessas.forEach(element => {
          this.totalTitulos += element.quantidadeTitulos
        })

        var oi = this.remessas.sort(
          (a, b) => b.quantidadeTitulos - a.quantidadeTitulos
        )

        this.listaok = true
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  onPrint() {
    window.print()
  }
}
