import { Component, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-consulta-intimacoes',
  templateUrl: './consulta-intimacoes.component.html',
  styleUrls: ['./consulta-intimacoes.component.scss']
})
export class ConsultaIntimacoesComponent implements OnInit {

  public bsModalRef: BsModalRef
  public buscaData: boolean = false
  public buscaProtocolo: boolean = false
  public buscaProtocoloLote: boolean = false


  constructor (
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  openBuscaData() {
    return this.buscaData = true, this.buscaProtocolo = false, this.buscaProtocoloLote = false
  }

  openBuscaProtocolo() {
    return this.buscaProtocolo = true, this.buscaData = false, this.buscaProtocoloLote = false
  }

  openBuscaProtocoloLote() {
    return this.buscaProtocoloLote = true, this.buscaData = false, this.buscaProtocolo = false
  }



}
