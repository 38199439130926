import { Component, OnInit } from '@angular/core'
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { defineLocale } from 'ngx-bootstrap/chronos'
import { BsLocaleService } from 'ngx-bootstrap/datepicker'
import { deLocale } from 'ngx-bootstrap/locale'
import { DetalheStatusIntimacoes } from '../../../shared/detalhe-status-intimacoes'
import { IntimacoesDataService } from '../../../shared/intimacoes-data.service'

defineLocale('pt-br', deLocale)
@Component({
  selector: 'app-relatorio-intimacoes-data',
  templateUrl: './relatorio-intimacoes-data.component.html',
  styleUrls: ['./relatorio-intimacoes-data.component.scss']
})
export class RelatorioIntimacoesDataComponent implements OnInit {

  public locale = 'pt-br';
  public data: Date
  dataInicial = new Date();
  dataFinal = new Date();
  bsValue = new Date();
  bsRangeValue: Date[];
  firstDate = new Date();
  public detalheIntimacoes: DetalheStatusIntimacoes[]
  public dataFormatada
  semNotificacao: any
  page = ""
  active = ''

  constructor (private localeService: BsLocaleService, private dataService: IntimacoesDataService) {

   }


  ngOnInit() {
    this.firstDate.setDate(this.firstDate.getDate() - 1);
    this.bsRangeValue = [this.firstDate, this.bsValue];
  }

  onValueChange(value: Date): void {
    this.localeService.use(this.locale)
    this.data = value
    console.log(this.data)
  }

  buscar() {
    this.detalheIntimacoes = null
    this.dataInicial = this.data[0]
    this.dataFinal = this.data[1]
    console.log(this.dataInicial)
    console.log(this.dataFinal)
    this.dataService.getStatusDetalheIntervaloData(this.dataInicial.toLocaleDateString('fr-CA'), this.dataFinal.toLocaleDateString('fr-CA'))
      .subscribe(dados => {
        this.detalheIntimacoes = dados
        this.semNotificacao = dados
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)
      }
    )
    this.active = ''
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.page = "1"
  }
}
