/* eslint-disable angular/timeout-service */
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { EventEmitter, Output } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { PublicationScheduleDTOModel } from 'src/app/Shared/Models/publication-dtomodel'
import {
  constDeleteAgendamento,
  constDeleteFeriado,
  constDeleteGrupo,
  constDeletePublicacoes,
  constDeleteRemessas,
  constGetAllPublications,
  constGetCartorioIdUsuario,
  constGetCartorioLetra,
  constGetConfig,
  constGetDadosCartorio,
  constGetDadosCartorioCompleto,
  constGetDadosCartorioId,
  constGetDadosLogin,
  constGetDadosLoginId,
  constGetDadosUsuarioCompleto,
  constGetEditaisbyDate,
  constGetEsqueciSenha,
  constGetFeriados,
  constGetHistoricoRemessas,
  constGetHistoricoRemessasId,
  constGetListaCartorio,
  constGetListGrupos,
  constGetListPermissoes,
  constGetListPermissoesGrupo,
  constGetMyPermission,
  constGetPDFtoDownload,
  constGetPublicacoesAgendadaId,
  constGetPublicacoesAgendadas,
  constGetPublicacoesUsuario,
  constGetRemessasbyDate,
  constGetSecurePass,
  constGetTitulosbyDate,
  constGetTitulosbyDates,
  constGetUltimaPublicacao,
  constGetUsersAll,
  constGetUsersbyCart,
  constGetUsersGrupo,
  constGetUsuarioApi,
  constGetXMLtoDownload,
  constPostCriarcartorio,
  constPostCriarGrupo,
  constPostCriaUsuario,
  constPostCriaUsuarioAPI,
  constPostFeriado,
  constPostPublicacaoAgendada,
  constPostPublicacaoIepro,
  constPostPublicacaoRemessa,
  constPutAgendamentos,
  constPutAlterarSenha,
  constPutCartorios,
  constPutConfig,
  constPutNovaSenha,
  constPutPermissaoGrupo,
  constPutUsuarioGrupo,
  constPutUsuarios,
  constPutUsuariosGrupo,
  urlAcesso
} from '../app.host'
import { CartorioModel } from '../Shared/Models/cartorio-model'
import { ChangePassModel } from '../Shared/Models/change-pass-model'
import { UserChangeModel } from '../Shared/Models/user-change-model'
import { Session } from '../Shared/session'

export class ApiController {
  @Output() static newUserType = new EventEmitter()

  constructor() {}

  // ---- Generic Get ----
  static getData(http: HttpClient, psUrl: string) {
    try {
      return http.get(psUrl).toPromise()
    } catch (error) {
      console.log(error)
      return null
    }
  }

  // ---- Gets ----
  static getPublications(http: HttpClient) {
    let urlget = urlAcesso + constGetAllPublications
    return ApiController.getData(http, urlget)
  }

  static getMinhasPermissoes(http: HttpClient) {
    let urlget = urlAcesso + constGetMyPermission
    return http.get(urlget).pipe(take(1))
  }

  static getSecurePass(http: HttpClient) {
    let urlget = urlAcesso + constGetSecurePass
    return ApiController.getData(http, urlget)
  }

  static getCartorio(http: HttpClient) {
    try {
      let urlGetCartorio = urlAcesso + constGetDadosCartorio
      return http.get(urlGetCartorio).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getCartorio - ' + error)
      return null
    }
  }

  static getIdCartorio(http: HttpClient) {
    try {
      let getIdCartorio = urlAcesso + constGetCartorioIdUsuario
      return http.get(getIdCartorio).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getIdCartorio - ' + error)
      return null
    }
  }

  static downloadDoc(http: HttpClient, id: string): Observable<any> {
    let url = urlAcesso + constGetPublicacoesAgendadaId + id
    return http.get(url, { responseType: 'blob' })
  }

  static getDownloadAgendamento(http: HttpClient, id: string) {
    try {
      let urlGetCartorio = urlAcesso + constGetPublicacoesAgendadaId + id
      return http.get(urlGetCartorio).toPromise()
    } catch (error) {
      console.log('Erro ao realizar download do Agendamento - ' + error)
      return null
    }
  }

  static getDadosLoginId(http: HttpClient, id: string) {
    try {
      let urlGetCartorio = urlAcesso + constGetDadosLoginId + id
      return http.get(urlGetCartorio).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getCartorio - ' + error)
      return null
    }
  }

  static getDadosLogin(http: HttpClient) {
    try {
      let urlGetCartorio = urlAcesso + constGetDadosLogin
      return http.get(urlGetCartorio).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getCartorio - ' + error)
      return null
    }
  }

  static getCartorioId(http: HttpClient, id: string) {
    try {
      let urlGetCartorio = urlAcesso + constGetDadosCartorioId + id
      return http.get(urlGetCartorio).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getCartorio - ' + error)
      return null
    }
  }

  static getCadastroUsuarioCompleto(http: HttpClient) {
    try {
      let urlGetcomplete = urlAcesso + constGetDadosUsuarioCompleto
      return http.get(urlGetcomplete).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getCadastroUsuarioCompleto - ' + error)
      return null
    }
  }

  static getCadastroCartorioCompleto(http: HttpClient) {
    try {
      let urlGetcomplete = urlAcesso + constGetDadosCartorioCompleto
      return http.get(urlGetcomplete).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getCadastroCartorioCompleto - ' + error)
      return null
    }
  }

  static getListaCartorios(http: HttpClient) {
    try {
      let urlGetListaCartorios = urlAcesso + constGetListaCartorio
      return http.get(urlGetListaCartorios).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getEditais - ' + error)
      return null
    }
  }

  static getPublicacaobyDate(date, http: HttpClient) {
    try {
      date = this.convertDate(date)
      let urlGetEditaisbyDate = urlAcesso + constGetEditaisbyDate + date
      return http
        .get(urlGetEditaisbyDate, {
          headers: new HttpHeaders({
            //"Access-Control-Allow-Origin": "192.168.1.115",
            'content-type': 'application/x-www-form-urlencoded'
          })
        })
        .toPromise()
    } catch (error) {
      console.log('Erro ao realizar getEditais - ' + error)
      return null
    }
  }

  // static getUltimaPublicacaoValida(http: HttpClient) {
  //   try {
  //     let urlGetEditaisbyDate = urlAcesso + constGetUltimaPublicacao
  //     return http
  //       .get(urlGetEditaisbyDate, {
  //         headers: new HttpHeaders({
  //           //"Access-Control-Allow-Origin": "192.168.1.115",
  //           'content-type': 'application/x-www-form-urlencoded'
  //         })
  //       })
  //       .toPromise()
  //   } catch (error) {
  //     console.log('Erro ao realizar getEditais - ' + error)
  //     return null
  //   }
  // }

  static getHistoricoPublicacoes(http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetPublicacoesUsuario
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getHistoricoAgendamento(http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetPublicacoesAgendadas
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getViewRemessasbyDate(date: string, http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetRemessasbyDate + date
      return http.get(urlget).toPromise()
    } catch (error) {
      console.log(error)
    }
  }

  static getViewRemessa(id: string, http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetHistoricoRemessasId + id
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getHistoricorRemessas(http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetHistoricoRemessas
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getXMLtoDownload(id, http: HttpClient) {
    try {
      let urlGetFile = urlAcesso + constGetXMLtoDownload + '/' + id

      return http.get(urlGetFile).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getEditais - ' + error)
      return null
    }
  }

  static getPDFtoDownload(id, http: HttpClient) {
    try {
      let urlGetFile = urlAcesso + constGetPDFtoDownload + id

      return http.get(urlGetFile).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getPDF - ' + error)
      return null
    }
  }

  static getTitulosbyDates(
    id: string,
    date: string,
    date1: string,
    http: HttpClient
  ) {
    try {
      let urlGetTitulos =
        urlAcesso +
        constGetTitulosbyDates +
        id +
        '&dInicio=' +
        date +
        '&dFim=' +
        date1

      return http.get(urlGetTitulos).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getTitulosbyDate - ' + error)
      return null
    }
  }

  static getTitulosbyDate(id: string, date, http: HttpClient) {
    try {
      let urlGetTitulos =
        urlAcesso + constGetTitulosbyDate + id + '&date=' + date

      return http.get(urlGetTitulos).toPromise()
    } catch (error) {
      console.log('Erro ao realizar getTitulosbyDate - ' + error)
      return null
    }
  }

  static getListGroup(http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetListGrupos
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getEsqueciSenha(http: HttpClient, email) {
    try {
      let urlget = urlAcesso + constGetEsqueciSenha + email
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getListPermissoes(http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetListPermissoes
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getListPermissoesGrupo(http: HttpClient, id) {
    try {
      let urlget = urlAcesso + constGetListPermissoesGrupo + id
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getUsuarios(http: HttpClient): Promise<any> {
    try {
      let urlget = urlAcesso + constGetUsersAll
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getUsuariosbyCartId(http: HttpClient, id): Promise<any> {
    try {
      let urlget = urlAcesso + constGetUsersbyCart + id
      return Promise.resolve(http.get(urlget).toPromise())
    } catch (error) {
      alert('Falha ao buscar usuários.')
    }
  }

  static getUsuariosGrupo(http: HttpClient, id): Promise<any> {
    try {
      let urlget = urlAcesso + constGetUsersGrupo + id + '/Usuarios'
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getUsuariosAPI(http: HttpClient): Promise<any> {
    try {
      let urlget = urlAcesso + constGetUsuarioApi
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getFindCartorioLetra(http: HttpClient, letra) {
    try {
      let urlget = urlAcesso + constGetCartorioLetra + letra
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getConfig(http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetConfig
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  static getFeriados(http: HttpClient) {
    try {
      let urlget = urlAcesso + constGetFeriados
      return http.get(urlget).toPromise()
    } catch (error) {}
  }

  // ---- Post ----
  static postCriarGrupo(formData, http: HttpClient) {
    try {
      return http
        .post(urlAcesso + constPostPublicacaoIepro, formData)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar postPublicacao - ' + err)
      return null
    }
  }

  static postPublicacaoCartorios(formData, http: HttpClient) {
    try {
      return http
        .post(urlAcesso + constPostPublicacaoRemessa, formData)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar postPublicacao - ' + err)
      return null
    }
  }

  static postPublicacaoIepro(formData, http: HttpClient) {
    try {
      return http
        .post(urlAcesso + constPostPublicacaoIepro, formData)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar postPublicacao - ' + err)
      return null
    }
  }

  static postPublicacaoAgendamento(formData, http: HttpClient, data) {
    try {
      return http
        .post(urlAcesso + constPostPublicacaoAgendada + data, formData)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar postPublicacao - ' + err)
      return null
    }
  }

  static postCriacaoGrupo(http: HttpClient, grupo) {
    try {
      return http.post(urlAcesso + constPostCriarGrupo, grupo).toPromise()
    } catch (err) {
      console.log('Erro ao realizar putPublicacao - ' + err)
      return null
    }
  }

  static postCriacaoCartorio(http: HttpClient, objCartorio) {
    try {
      return http
        .post(urlAcesso + constPostCriarcartorio, objCartorio)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar postCriaCartorio - ' + err)
      return null
    }
  }

  static postCriaUsuario(http: HttpClient, objUsuario) {
    try {
      return http.post(urlAcesso + constPostCriaUsuario, objUsuario).toPromise()
    } catch (err) {
      console.log('Erro ao realizar postCriaUsuario - ' + err)
      return null
    }
  }
  static postCriaUsuarioAPI(http: HttpClient, objUsuario) {
    try {
      return http
        .post(urlAcesso + constPostCriaUsuarioAPI, objUsuario)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar postCriaUsuario - ' + err)
      return null
    }
  }

  static postFeriado(http: HttpClient, objFeriado) {
    try {
      return http.post(urlAcesso + constPostFeriado, objFeriado).toPromise()
    } catch (err) {
      console.log('Erro ao realizar postFeriado - ' + err)
      return null
    }
  }

  // ---- Put ----

  static putUsuariosGrupo(http: HttpClient, usuarios) {
    let shared = Session.getInstance()

    try {
      return http
        .put(
          urlAcesso + constPutUsuariosGrupo + shared.getGrupoId() + '/Usuarios',
          usuarios
        )
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar putPublicacao - ' + err)
      return null
    }
  }

  static putUsuarioGrupo(http: HttpClient, idusuario, idGrupo) {
    let shared = Session.getInstance()

    try {
      return http
        .put(urlAcesso + constPutUsuarioGrupo + idGrupo + '/Usuario', idusuario)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar put usuario no grupo - ' + err)
      return null
    }
  }

  static putPermissaoGrupo(http: HttpClient, permissoes) {
    try {
      let shared = Session.getInstance()
      return http
        .put(
          urlAcesso + constPutPermissaoGrupo + shared.getGrupoId(),
          permissoes
          //return http.post(urlAcesso + constPostPermissaoGrupo + shared.grupoId, permissoes,
        )
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar putPublicacao - ' + err)
      return null
    }
  }

  static putAlterarSenha(http: HttpClient, passObj: ChangePassModel) {
    try {
      return http.put(urlAcesso + constPutAlterarSenha, passObj).toPromise()
    } catch (err) {
      console.log('Erro ao realizar putAlterarSenha - ' + err)
      return null
    }
  }

  static putNovaSenha(http: HttpClient, passObj: ChangePassModel, id: string) {
    try {
      return http.put(urlAcesso + constPutNovaSenha + id, passObj).toPromise()
    } catch (err) {
      console.log('Erro ao realizar putAlterarSenha - ' + err)
      return null
    }
  }
  static putAlterarAgendamento(
    http: HttpClient,
    agendamentoObj: PublicationScheduleDTOModel
  ) {
    try {
      return http
        .put(urlAcesso + constPutAgendamentos, agendamentoObj)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar putAgendamento - ' + err)
      return err
    }
  }

  static putAlterarLogin(http: HttpClient, changeUserObj: UserChangeModel) {
    try {
      return http.put(urlAcesso + constPutUsuarios, changeUserObj).toPromise()
    } catch (err) {
      console.log('Erro ao realizar putAlterarLogin - ' + err)
      return err
    }
  }

  static putAlteracaoDadosCartorio(
    http: HttpClient,
    cartorioObj: CartorioModel
  ) {
    try {
      return http.put(urlAcesso + constPutCartorios, cartorioObj).toPromise()
    } catch (err) {
      console.log('Erro ao realizar putAlteracaoDadosCartorio - ' + err)
      return err
    }
  }

  static putConfig(http: HttpClient, configs) {
    try {
      return http.put(urlAcesso + constPutConfig, configs).toPromise()
    } catch (err) {
      console.log('Erro ao realizar putConfig - ' + err)
      return err
    }
  }

  // ---- Delete ----
  static deleteRemessas(http: HttpClient, id: string) {
    try {
      return http.delete(urlAcesso + constDeleteRemessas + id).toPromise()
    } catch (err) {
      console.log('Erro ao realizar deleteRemessas - ' + err)
      return err
    }
  }

  static deletePublicacao(http: HttpClient, id: string) {
    try {
      return http.delete(urlAcesso + constDeletePublicacoes + id).toPromise()
    } catch (err) {
      console.log('Erro ao realizar deleteRemessas - ' + err)
      return err
    }
  }

  static deleteGrupo(http: HttpClient, id: string) {
    try {
      return http.delete(urlAcesso + constDeleteGrupo + id).toPromise()
    } catch (err) {
      console.log('Erro ao realizar deleteRemessas - ' + err)
      return err
    }
  }

  static deleteFeriado(http: HttpClient, id: string) {
    try {
      return http.delete(urlAcesso + constDeleteFeriado + id).toPromise()
    } catch (err) {
      console.log('Erro ao realizar deleteFeriado - ' + err)
      return err
    }
  }

  static deleteUserAPI(http: HttpClient) {
    try {
      return http.delete(urlAcesso + constPostCriaUsuarioAPI).toPromise()
    } catch (err) {
      console.log('Erro ao realizar deleteUserAPI - ' + err)
      return err
    }
  }

  static deleteUser(http: HttpClient, id) {
    try {
      return http
        .delete(urlAcesso + constPostCriaUsuario + '/' + id)
        .toPromise()
    } catch (err) {
      console.log('Erro ao realizar deleteUserAPI - ' + err)
      return err
    }
  }

  static deleteAgendamento(http: HttpClient, id: string) {
    try {
      return http.delete(urlAcesso + constDeleteAgendamento + id).toPromise()
    } catch (err) {
      console.log('Erro ao realizar deleteAgendamento - ' + err)
      return err
    }
  }

  // ---- Utilidades ----

  static convertDate(dateToConvert) {
    //console.log(dateToConvert);
    let dateselected
    if (dateToConvert.includes('/')) {
      dateselected = dateToConvert.split('/')
    } else {
      dateselected = dateToConvert.split('-')
    }

    let dd = dateselected[0]
    let mm = dateselected[1]
    let yyyy = dateselected[2]

    if (dd.length == 4) {
      dd = dateselected[2]
      yyyy = dateselected[0]
    }

    if (dd.length < 2) {
      dd = '0' + dd
    }

    if (mm.length < 2) {
      mm = '0' + mm
    }
    console.log(dd + '/' + mm + '/' + yyyy)
    return dd + '/' + mm + '/' + yyyy
  }

  static criptText(toCript: string) {
    let key = CryptoJS.enc.Utf8.parse('ri57wQXnTOwXSQmw')
    let iv = CryptoJS.enc.Utf8.parse('xKksSeTes2vUAJFP')
    let encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(toCript),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }
    )

    let passCripted = encodeURIComponent(encrypted.toString())
    return passCripted
  }

  static delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  static mapError(erroCod: number, message: string, errorI: string) {
    let retorno
    switch (erroCod) {
      case 0:
        retorno = message //"Falha ao realizar operação - Entre em contato com o suporte...";
        console.log(errorI + '- ' + message)
        break

      case 2:
        retorno = 'Operação não autorizada...'
        console.log(errorI + '- ' + message)
        break

      case 3:
        retorno = 'Tipo de arquivo não suportado...'
        console.log(errorI + '- ' + message)
        break

      case 4:
        // mostrar tempos e horarios
        retorno = message
        console.log(errorI + '- ' + message)
        break

      case 5:
        retorno =
          'Remessa não encontrada. Atualize sua página e tente novamente...'
        console.log(errorI + '- ' + message)
        break

      case 6:
        // mostrar
        retorno = message
        console.log(errorI + '- ' + message)
        break

      case 7:
        // mostrar
        retorno = ' - ' + errorI
        console.log(errorI + '- ' + message)
        break

      case 8:
        // mostrar
        retorno = message + ' - ' + errorI
        console.log(errorI + '- ' + message)
        break

      case 9:
      case 10:
        // mostrar
        retorno = 'Dados inválidos - Verifique suas informações.'
        console.log(errorI + '- ' + message)
        break

      default:
        console.log(erroCod)
        retorno = erroCod + ' - Erro desconhecido...'
        break
    }

    return retorno
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }
}
