import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, EventEmitter, Injectable, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import { Session } from 'src/app/Shared/session'
import { ApiController } from '../../../../Core/api-controller'
import { PublicationDTOModel } from '../../../../Shared/Models/publication-dtomodel'
import { RemessaDTOModel } from '../../../../Shared/Models/remessa-dtomodel'

@Component({
  selector: 'app-comp-upload-files',
  templateUrl: './comp-upload-files.component.html',
  styleUrls: ['./comp-upload-files.component.scss']
})

@Injectable()
export class CompUploadFilesComponent implements OnInit {

  showTemplate: boolean = false;
  public shared: Session
  @ViewChild('validatedCustomFile', { static: false }) inputFileItem: ElementRef
  @ViewChild('inputFile1', { static: false }) inputFileItem1: ElementRef
  @Output() newUserType = new EventEmitter();

  constructor (
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) { }

  file
  fileEvent
  path = "Selecione seu arquivo...";
  size
  envioOk: boolean
  idPublicacao: string
  retornoHttp
  titulo
  publicador
  Enviando = false;

  @Output() alterarUsuario = new EventEmitter();

  ngOnInit() {
    this.shared = Session.getInstance()

    if (this.shared.getPermissao().ManagePublication) {
      this.titulo = "Envio de notícia"
      this.publicador = true
    }
    else {
      this.titulo = "Envio de remessa"
      this.publicador = false
    }
  }

  inputFile(event) {
    this.spinner.show()
    if (event[0]) {
      this.fileEvent = event[0]
    } else {
      this.fileEvent = event.target.files[0]
    }
    if (event && this.fileEvent) {
      this.path = this.fileEvent.name
      this.file = this.fileEvent
      this.size = this.fileEvent.size / 1000

      if (this.size > 5000) {
        console.log("Arquivo de --- " + this.size + "KB")
        this.alertService.danger("Arquivo superior ao tamanho suportado... 5 MB")
        this.file = 0
        this.path = "Selecione seu arquivo..."
      }
      else {
        console.log("Arquivo de " + this.size + "KB")
      }
    }
    this.spinner.hide()
  }

  async submitFile() {
    try {
      if (!this.Enviando) {
        this.Enviando = true
        this.spinner.show()

        const formData = new FormData()
        formData.append('pdf', this.file)

        if (this.shared.getPermissao().ManagePublication) {
          await ApiController.postPublicacaoIepro(formData, this.http)
            .then(
              result => {
                this.spinner.hide()
                var mapeado = <PublicationDTOModel>result
                this.alertService.success("Publicação realizada com sucesso! - Id Publicação: " + mapeado.id)
                this.router.navigate([this.shared.getHomePage()])
              },
              err => {
                var mapeado = <ErrorModel>err.error
                var erromapedo = ApiController.mapError(mapeado.IEPROErrorID, mapeado.Mensagem, mapeado.ErroI)
                console.log(mapeado.IEPROErrorID + " - " + mapeado.ErroI)
                this.spinner.hide()
              }
            )
        }
        else if (this.shared.getPermissao().PostXMLFile) {
          await ApiController.postPublicacaoCartorios(formData, this.http)
            .then(
              result => {
                var mapeado = <RemessaDTOModel>result
                this.spinner.hide()
                this.alertService.success("Publicação realizada com sucesso! - Id Publicação: " + mapeado.id)
                this.router.navigate([this.shared.getHomePage()])
              },
              err => {
                console.log(err)
                var mapeado = <ErrorModel>err.error
                console.log(mapeado)
                var erromapedo = ApiController.mapError(mapeado.IEPROErrorID, mapeado.Mensagem, mapeado.ErroI)
                console.log(mapeado.IEPROErrorID + " - " + mapeado.ErroI)
                this.spinner.hide()
                if (mapeado.ErroI === "4") {
                  alert("Falha ao realizar publicação - " + erromapedo)
                  this.router.navigate([this.shared.getHomePage()])
                }
                else {
                  this.alertService.danger("Falha ao realizar publicação - " + erromapedo)
                }
              }
            )
        }
        this.spinner.hide()
      }
    }
    catch (err) {
      this.spinner.hide()
      console.log("Erro - " + err)
    }
  }

  cancelar() {
    this.file = undefined
  }
}


