/* eslint-disable angular/document-service */
/* eslint-disable angular/window-service */
import { HttpClient } from '@angular/common/http'
import {
  Component,
  ElementRef,
  EventEmitter,
  Injectable,
  OnInit,
  Output,
  Renderer2,
  ViewChild
} from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import * as AdminLte from 'admin-lte'
import * as $ from 'jquery'
import { AlertService } from 'ngx-alerts'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { MenuService } from 'src/app/Menu/menu.service'
import { ConfigDtoModel } from 'src/app/Shared/Models/config-dto-model'
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model'
import { Session } from 'src/app/Shared/session'
import { UserLogin } from '../../../../Shared/Models/user-model'
import { AlterarLoginComponent } from '../../alterar-login/alterar-login/alterar-login.component'
import { AlterarPassComponent } from '../../alterar-pass/alterar-pass/alterar-pass.component'
import { UserService } from '../user.service'

@Component({
  selector: 'app-comp-login',
  templateUrl: './comp-login.component.html',
  styleUrls: ['./comp-login.component.scss']
})
@Injectable()
export class CompLoginComponent implements OnInit {
  public sidebarMenuOpened = true
  user = new UserLogin('', '')
  session: Session
  message: string
  UserNameToShow: string
  returnUrl: string
  tipoP
  tipoC
  tipoM
  tipoS
  exibir
  usuario: UserChangeModel
  urlNavigate: string
  seguir: boolean = false
  recuperar: boolean = false
  bsModalRef: BsModalRef
  sidebar: string
  nomeUsuario: string

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private modalService: BsModalService,
    public messageService: MenuService,
    private renderer: Renderer2
  ) {}

  @ViewChild('img', { static: false }) img: ElementRef

  @Output() newUserType = new EventEmitter()

  ngOnInit() {
    this.session = Session.getInstance()
    //console.log(this.session.getPermissao());

    /* Create an alert to show if the browser is IE or not */
    if (this.isIE()) {
      alert(
        'Navegador incompatível - Utilize os navegadores demonstrados abaixo...'
      )
    }

    if (this.session.getToken() != undefined) {
      this.session.setMenu(true)
      this.session.setMenuApi(false)

      this.UserNameToShow = this.session.getLogin()
      this.nomeUsuario = this.UserNameToShow.split('@', 1)[0]

      ApiController.getConfig(this.http).then(
        sucesso => {
          this.session = Session.getInstance()
          var mapeado = <ConfigDtoModel>sucesso

          this.session.setValidNewsTimeInDays(mapeado.validNewsTimeInDays)
          this.session.setPostXMLStartHour(mapeado.postXMLStartHour)
          this.session.setPostXMLStartMin(mapeado.postXMLStartMin)

          this.session.setPostXMLEndMin(mapeado.postXMLEndMin)
          this.session.setPostXMLEndHour(mapeado.postXMLEndHour)

          this.spinner.hide()
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )

      // this.urlNavigate = '/home';
    } else {
      this.session.clear()
    }
  }

  closeOthers(evt) {
    console.log(evt)
  }

  isIE() {
    const match = navigator.userAgent.search(/(?:Edge|MSIE|Trident\/.*; rv:)/)
    let isIE = false

    if (match !== -1) {
      isIE = true
    }

    return isIE
  }

  // setMessage() {
  //   return this.messageService.setMessage('teste mensagem')
  // }

  openModalAlterarLogin() {
    this.bsModalRef = this.modalService.show(AlterarLoginComponent)
    this.bsModalRef.content.closeBtnName = 'Close'
  }

  openModalAlterarSenha() {
    this.bsModalRef = this.modalService.show(AlterarPassComponent)
    this.bsModalRef.content.closeBtnName = 'Close'
  }

  forgotpass() {
    //this.router.navigate(['/UploadFile']);
  }

  cancelLogin() {
    this.message = ''
    this.session.clear()
    this.user = new UserLogin('', '')
    window.location.href = '/login'
    window.location.reload()
  }

  uploadFiles() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.session.setCartId(null)
    this.router.navigate(['/UploadFile'])
  }

  uploadFilesNews() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.session.setCartId(null)
    this.router.navigate(['/uploadNews'])
  }

  reportRemessa() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/viewRemessas'])
  }

  reportTitulos() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/viewTitulos'])
  }

  changePass() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.session.setCartId(null)
    this.router.navigate(['/ChangePass'])
  }

  changeLogin() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.session.setCartId(null)
    this.router.navigate(['/ChangeLogin'])
  }

  gestaoAgendamento() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.session.setCartId(null)
    this.router.navigate(['/GestaoAgendamento'])
  }

  downloadPublicacoes() {
    this.router.navigate(['/downloadPublicacoes'])
  }

  async editCartorio() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.session.setCartId(null)
    this.session.setEditar(true)
    this.router.navigate(['/EditCartorio'])
  }

  securePass() {
    this.router.navigate(['/SenhaSegura'])
  }

  async home() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.session.setCartId(null)
    this.router.navigate([this.urlNavigate])
  }

  grupos() {
    this.session.setConfig(false)
    this.session.setMenuApi(false)
    this.router.navigate(['/listGroup'])
  }

  homeNavigate() {
    this.router.navigate(['/home'])
  }

  criarGrupos() {
    this.router.navigate(['/createGroup'])
  }

  configGrupo() {
    this.session.setConfig(!this.session.getConfig())
  }

  configGerais() {
    this.router.navigate(['/config'])
  }

  configEmolumentos() {
    this.session.setConfigEmolumentos(!this.session.getConfigEmolumentos())
  }

  editarEmolumentos() {
    this.router.navigate(['/configEmolumentos'])
  }

  createCart() {
    this.router.navigate(['/createCart'])
  }

  gestaoCartorio() {
    this.router.navigate(['/GestaoCartorio'])
  }

  gestaoTitulos() {
    this.router.navigate(['/GestaoTitulos'])
  }

  gestaoPublicacoes() {
    this.router.navigate(['/GestaoPublicacoes'])
  }

  criarUsuarioAPI() {
    this.session.setApi(true)
    this.router.navigate(['/cadastroUsuario'])
  }

  abreAPI() {
    this.session.setApi(true)

    this.router.navigate(['/integrador'])
  }

  logOut() {
    this.spinner.show()
    try {
      localStorage.removeItem('currentUser')
      window.location.reload()
    } finally {
      this.spinner.hide()
    }
  }

  ngAfterViewInit() {
    $('[data-widget="treeview"]').each(function () {
      AdminLte.Treeview._jQueryInterface.call($(this), 'init')
    })
  }

  toggleMenuSidebar() {
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      )
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      )
      this.sidebarMenuOpened = false
    } else {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      )
      this.renderer.addClass(document.querySelector('app-root'), 'sidebar-open')
      this.sidebarMenuOpened = true
    }
  }
}
