/* eslint-disable angular/document-service */
/* eslint-disable angular/window-service */
import { CurrentUser } from './../../../../Shared/Models/current-user-model'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AngularFireDatabase } from 'angularfire2/database'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import {
  EmoluentModelYear,
  EmolumentoModel,
  EmolumentoModelFirebase
} from 'src/app/Shared/Models/emolumento-model'
import { Session } from 'src/app/Shared/session'
import {
  ValorReferenciaModel,
  ValorReferenciaModelSemFaixa
} from '../../../../Shared/Models/valor-referencia-model'

@Component({
  selector: 'app-manut-firebase',
  templateUrl: './manut-firebase.component.html',
  styleUrls: ['./manut-firebase.component.scss']
})
export class ManutFirebaseComponent implements OnInit {
  currentYear = new Date().getFullYear().toString()
  urlFirebaseRoot = 'https://calculadora-37d0c.firebaseio.com/emolument'
  urlFirebase = this.urlFirebaseRoot + '/' + this.currentYear
  //urlFirebase = "https://consultacnp.firebaseio.com/calculator/emolumentTeste/";
  emolumentosCalc: EmolumentoModel
  emolumentosToFirebase: EmolumentoModelFirebase
  digitalizacao = 0
  conducao = 0
  intimacao = 0
  processamento = 0
  protesto = 0
  faixaAvaliacao = Array<ValorReferenciaModel>()
  faixaAvaliacaoSelo = Array<ValorReferenciaModel>()
  faixaEmolumentoSelo = Array<ValorReferenciaModel>()
  exibir = false
  digitando = ''
  vigencia
  shared: Session
  listaVigencia: Array<String>
  constructor(
    private router: Router,
    private angularFire: AngularFireDatabase,
    private alertService: AlertService,
    private spinner: NgxSpinnerService
  ) {
    this.emolumentosCalc = new EmolumentoModel()
    this.emolumentosToFirebase = new EmolumentoModelFirebase()
    this.listaVigencia = new Array<String>()
  }
  novaVigencia

  @ViewChild('option', { static: false }) AnoVigente: ElementRef

  async ngOnInit() {
    window.scroll(0, 0)
    this.shared = Session.getInstance()
    if (this.shared.getCartId() == null) this.shared.setCartId(-1)

    if (this.shared.getToken() == undefined) {
      this.router.navigate([this.shared.getHomePage()])
    }

    this.getValues()
    this.exibir = true
    this.spinner.hide()
  }

  ngAfterViewChecked(): void {
    var existeOption = document.getElementById(this.vigencia)
    if (existeOption) {
      document.getElementById(this.vigencia).setAttribute('selected', 'true')
    }
  }

  async getValues() {
    this.spinner.show()
    await this.getFirebaseVigencia()
    //console.log(this.vigencia)
    await this.buscaValoresSelecionado(this.vigencia)
    await this.mapeandoObj()
    await this.listarTabelas()
    this.spinner.hide()
  }

  cancelar() {
    window.scroll(0, 0)
    this.router.navigate([this.shared.getHomePage()])
  }

  onSearchChange(searchValue: string, id) {
    if (id == 'ano') this.vigencia = searchValue
    else {
      var splited = id.split('-')
      var tabela = splited[0]
      var id = splited[1]
      var idOk = Number(id) - 1
      var vr = splited[2]

      switch (tabela) {
        case 'faixaAvaliacao':
          if (vr == 'V') this.faixaAvaliacao[idOk].Valor = Number(searchValue)
          else this.faixaAvaliacao[idOk].ValorReferencia = Number(searchValue)
          break

        case 'faixaEmolumentoSelo':
          if (vr == 'V')
            this.faixaEmolumentoSelo[idOk].Valor = Number(searchValue)
          else
            this.faixaEmolumentoSelo[idOk].ValorReferencia = Number(searchValue)
          break

        case 'faixaAvaliacaoSelo':
          if (vr == 'V')
            this.faixaAvaliacaoSelo[idOk].Valor = Number(searchValue)
          else
            this.faixaAvaliacaoSelo[idOk].ValorReferencia = Number(searchValue)
          break

        case 'intimacao':
          this.intimacao = Number(searchValue)
          break

        case 'processamento':
          this.processamento = Number(searchValue)
          break

        case 'protesto':
          this.protesto = Number(searchValue)
          break

        case 'conducao':
          this.conducao = Number(searchValue)
          break

        case 'digitalizacao':
          this.digitalizacao = Number(searchValue)
          break

        default:
          break
      }
    }
  }

  async atualizarFirebase() {
    window.scroll(0, 0)
    if (this.todosEditados()) {
      //var rootRef = this.angularFire.database.refFromURL(this.urlFirebase)
      var rootRef = this.angularFire.database.refFromURL(this.urlFirebaseRoot)
      await rootRef.child('vigencia').set(Number(this.vigencia))
      await this.atualizarTabela()
      await this.getValues()
    }
  }

  todosEditados(): boolean {
    var inputs = document.querySelectorAll('input.form-styleFaixa')
    for (let index = 0; index < inputs.length; index++) {
      const element = inputs[index] as HTMLInputElement
      if (element.value == '') {
        this.alertService.warning('Preencha todos os campos!')
        return false
      }
    }
    return true
  }

  async atualizarTabela() {
    window.scroll(0, 0)
    await this.atualizaObj()
    //var rootRef = this.angularFire.database.refFromURL(this.urlFirebase)
    var rootRef = this.angularFire.database.refFromURL(this.urlFirebaseRoot)
    //console.log(this.emolumentosToFirebase)
    await rootRef.child(this.vigencia).set(this.emolumentosToFirebase)
    this.alertService.success('Operação concluída.')
  }

  async buscaValoresSelecionado(ano?) {
    if (ano == '' || ano == undefined) ano = this.AnoVigente.nativeElement.value

    this.vigencia = ano

    if (ano == String(this.novaVigencia + 1)) {
      var inputs = document.querySelectorAll('input.form-styleFaixa')

      for (let index = 0; index < inputs.length; index++) {
        const element = inputs[index] as HTMLInputElement
        element.value = ''
      }
    } else {
      //console.log(ano)
      await this.getFirebaseValuesToYear(ano)
      this.mapeandoObj()
    }
  }

  async getFirebaseValuesToYear(year) {
    var rootRef = this.angularFire.database.refFromURL(
      this.urlFirebaseRoot + '/' + year
    )
    await rootRef.once('value').then(
      x => {
        var objEmolumento = <EmolumentoModel>x.toJSON()
        this.emolumentosCalc = objEmolumento
        //console.log(this.emolumentosCalc)
      },
      err => {
        console.log(err)
        this.spinner.hide()
        this.alertService.danger(
          'Falha ao buscar os valores. Tente novamente mais tarde!'
        )
      }
    )
  }

  async listarTabelas() {
    this.novaVigencia = 0
    //JANDER var rootRef = this.angularFire.database.refFromURL(this.urlFirebase)
    var rootRef = this.angularFire.database.refFromURL(this.urlFirebaseRoot)
    this.listaVigencia = new Array<String>()
    var tabelas = rootRef.once('value', function (snapshot) {
      return snapshot
    })
    await tabelas.then(x => {
      x.forEach(y => {
        if (y.key != 'vigencia') {
          if (Number(y.key) > this.novaVigencia) {
            this.novaVigencia = Number(y.key)
          }
          this.listaVigencia.push(y.key)
        }
      })
    })
    this.listaVigencia.push(String(this.novaVigencia + 1))
  }

  async getFirebaseVigencia() {
    var rootRef = this.angularFire.database.refFromURL(this.urlFirebaseRoot)
    await rootRef.once('value').then(
      x => {
        var retorno = <EmoluentModelYear>x.toJSON()
        this.vigencia = retorno.vigencia
      },
      err => {
        console.log(err)
        this.spinner.hide()
        this.alertService.danger(
          'Falha ao buscar os valores. Tente novamente mais tarde!'
        )
      }
    )
  }

  async atualizaObj() {
    this.emolumentosToFirebase.Conducao = this.conducao
    this.emolumentosToFirebase.Digitalizacao = this.digitalizacao
    this.emolumentosToFirebase.Intimacao = this.intimacao
    this.emolumentosToFirebase.Processamento = this.processamento
    this.emolumentosToFirebase.Protesto = this.protesto

    this.emolumentosToFirebase.FaixaAvaliacao = new Array<
      ValorReferenciaModelSemFaixa
    >()
    this.emolumentosToFirebase.FaixaAvaliacaoSelo = new Array<
      ValorReferenciaModelSemFaixa
    >()
    this.emolumentosToFirebase.FaixaEmolumentoSelo = new Array<
      ValorReferenciaModelSemFaixa
    >()

    this.emolumentosToFirebase.FaixaAvaliacao.push(
      new ValorReferenciaModelSemFaixa()
    )
    this.faixaAvaliacao.forEach(element => {
      var obj = new ValorReferenciaModelSemFaixa()
      //console.log(obj)
      obj.Valor = element.Valor
      obj.ValorReferencia = element.ValorReferencia
      this.emolumentosToFirebase.FaixaAvaliacao.push(obj)
    })

    this.emolumentosToFirebase.FaixaAvaliacao[1].ValorReferencia = 0

    this.emolumentosToFirebase.FaixaAvaliacaoSelo.push(
      new ValorReferenciaModelSemFaixa()
    )
    this.faixaAvaliacaoSelo.forEach(element => {
      var obj = new ValorReferenciaModelSemFaixa()
      obj.Valor = element.Valor
      obj.ValorReferencia = element.ValorReferencia
      this.emolumentosToFirebase.FaixaAvaliacaoSelo.push(obj)
    })

    this.emolumentosToFirebase.FaixaEmolumentoSelo.push(
      new ValorReferenciaModelSemFaixa()
    )
    this.faixaEmolumentoSelo.forEach(element => {
      var obj = new ValorReferenciaModelSemFaixa()
      obj.Valor = element.Valor
      obj.ValorReferencia = element.ValorReferencia
      this.emolumentosToFirebase.FaixaEmolumentoSelo.push(obj)
    })
  }

  mapeandoObj() {
    this.conducao = this.emolumentosCalc.Conducao
    this.digitalizacao = this.emolumentosCalc.Digitalizacao
    this.intimacao = this.emolumentosCalc.Intimacao
    this.processamento = this.emolumentosCalc.Processamento
    this.protesto = this.emolumentosCalc.Protesto

    this.faixaAvaliacao = new Array<ValorReferenciaModel>()
    this.faixaAvaliacaoSelo = new Array<ValorReferenciaModel>()
    this.faixaEmolumentoSelo = new Array<ValorReferenciaModel>()

    var obj = new ValorReferenciaModel()
    obj.Valor = this.emolumentosCalc.FaixaAvaliacao[1].Valor
    obj.ValorReferencia = 0
    obj.ValorReferencia1 = 0
    obj.IdFaixa = 1
    this.faixaAvaliacao.push(obj)

    for (let i = 2; i < 15; i++) {
      var obj = new ValorReferenciaModel()
      obj.Valor = this.emolumentosCalc.FaixaAvaliacao[i].Valor
      if (this.emolumentosCalc.FaixaAvaliacao[i].ValorReferencia == undefined)
        this.emolumentosCalc.FaixaAvaliacao[i].ValorReferencia = 999999999999999

      obj.ValorReferencia = this.emolumentosCalc.FaixaAvaliacao[
        i
      ].ValorReferencia
      obj.ValorReferencia1 = i - 1
      obj.IdFaixa = i
      this.faixaAvaliacao.push(obj)
    }

    for (let i = 1; i < 6; i++) {
      var obj = new ValorReferenciaModel()
      obj.Valor = this.emolumentosCalc.FaixaAvaliacaoSelo[i].Valor

      if (
        this.emolumentosCalc.FaixaAvaliacaoSelo[i].ValorReferencia == undefined
      )
        this.emolumentosCalc.FaixaAvaliacaoSelo[
          i
        ].ValorReferencia = 999999999999999

      obj.ValorReferencia = this.emolumentosCalc.FaixaAvaliacaoSelo[
        i
      ].ValorReferencia
      obj.IdFaixa = i
      this.faixaAvaliacaoSelo.push(obj)
    }

    for (let i = 1; i < 5; i++) {
      var obj = new ValorReferenciaModel()
      obj.Valor = this.emolumentosCalc.FaixaEmolumentoSelo[i].Valor
      obj.ValorReferencia = this.emolumentosCalc.FaixaEmolumentoSelo[
        i
      ].ValorReferencia
      obj.IdFaixa = i
      this.faixaEmolumentoSelo.push(obj)
    }
  }
}
