import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatTableDataSource} from '@angular/material';

import { GestaoConteudoComponent } from './gestao-conteudo/gestao-conteudo.component';
import { GestaoCartorioModule } from '../gestao-cartorio/gestao-cartorio.module';
import { GestaoPublicacaoModule } from '../gestao-publicacao/gestao-publicacao.module';

@NgModule({
  imports: [
    CommonModule, 
    GestaoCartorioModule,
    GestaoPublicacaoModule

  ],
  declarations: [
    GestaoConteudoComponent
  ],
  exports: [
    GestaoConteudoComponent
  ],
  schemas: [ MatTableDataSource ]
})
export class GestaoConteudoModule { }
