export class UserModel {
    constructor (
        public id: string,
        public user: string,
        public userType: string,
        public password: string,
        public profile: string
    ) { }
}

export class UserLogin {
    constructor (
        public login: string,
        public password: string
    ) { }
}
export class UsersGrupo {
    constructor (
        public id: number,
        public login: string,
        public cidade: string
    ) { }
}


