import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { AccordionModule } from 'ngx-bootstrap/accordion'
import { defineLocale } from 'ngx-bootstrap/chronos'
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker'
import { ptBrLocale } from 'ngx-bootstrap/locale'
import { TypeaheadModule } from 'ngx-bootstrap/typeahead'
import { NgxPaginationModule } from 'ngx-pagination'
import { ConsultaIntimacoesComponent } from './gestao-de-intimacoes/consulta-intimacoes/consulta-intimacoes.component'
import { ConsultaStatusDataComponent } from './gestao-de-intimacoes/consulta-intimacoes/consulta-status-data/consulta-status-data.component'
import { ConsultaStatusProtocoloComponent } from './gestao-de-intimacoes/consulta-intimacoes/consulta-status-protocolo/consulta-status-protocolo.component'
import { RelatorioIntimacoesDataComponent } from './gestao-de-intimacoes/relatorio-intimacoes/relatorio-intimacoes-data/relatorio-intimacoes-data.component'
import { RelatorioIntimacoesDocumentoComponent } from './gestao-de-intimacoes/relatorio-intimacoes/relatorio-intimacoes-documento/relatorio-intimacoes-documento.component'
import { RelatorioIntimacoesProtocoloComponent } from './gestao-de-intimacoes/relatorio-intimacoes/relatorio-intimacoes-protocolo/relatorio-intimacoes-protocolo.component'
import { RelatorioIntimacoesComponent } from './gestao-de-intimacoes/relatorio-intimacoes/relatorio-intimacoes.component'
import { StatusIntimacoesDetalheComponent } from './gestao-de-intimacoes/status-intimacoes/status-intimacoes-detalhe/status-intimacoes-detalhe.component'
import { StatusIntimacoesIeproComponent } from './gestao-de-intimacoes/status-intimacoes/status-intimacoes-iepro/status-intimacoes-iepro.component'
import { StatusIntimacoesComponent } from './gestao-de-intimacoes/status-intimacoes/status-intimacoes.component'
import { StatusTodasIntimacoesComponent } from './gestao-de-intimacoes/status-intimacoes/status-todas-intimacoes/status-todas-intimacoes/status-todas-intimacoes.component'
import { DndDirective } from './inclusao-de-termo-iepro/dnd.directive'
import { InclusaoDeTermoIeproComponent } from './inclusao-de-termo-iepro/inclusao-de-termo-iepro.component'
import { ProgressComponent } from './inclusao-de-termo-iepro/progress/progress.component'
import { IntimadorOnlineRoutingModule } from './intimador-online-routing.module'
import { RelatorioIeproDataComponent } from './relatorio-iepro/relatorio-iepro-data/relatorio-iepro-data.component'
import { RelatorioIeproDocumentoComponent } from './relatorio-iepro/relatorio-iepro-documento/relatorio-iepro-documento.component'
import { RelatorioIeproProtocoloComponent } from './relatorio-iepro/relatorio-iepro-protocolo/relatorio-iepro-protocolo.component'
import { RelatorioIeproComponent } from './relatorio-iepro/relatorio-iepro.component'

defineLocale('pt-br', ptBrLocale)

@NgModule({
  declarations: [
    ConsultaIntimacoesComponent,
    ConsultaStatusDataComponent,
    ConsultaStatusProtocoloComponent,
    StatusIntimacoesComponent,
    StatusIntimacoesDetalheComponent,
    StatusIntimacoesIeproComponent,
    StatusTodasIntimacoesComponent,
    //TODO comentado pois estava dando erro no build de producao
    // ConsultaStatusProtocoloLoteComponent,
    RelatorioIntimacoesComponent,
    RelatorioIntimacoesDataComponent,
    RelatorioIntimacoesProtocoloComponent,
    RelatorioIntimacoesDocumentoComponent,
    RelatorioIeproComponent,
    RelatorioIeproDataComponent,
    RelatorioIeproProtocoloComponent,
    RelatorioIeproDocumentoComponent,
    ProgressComponent,
    InclusaoDeTermoIeproComponent,
    DndDirective
  ],
  imports: [
    CommonModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    TypeaheadModule.forRoot(),
    IntimadorOnlineRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPaginationModule,
    Ng2SearchPipeModule
  ],
  entryComponents: []
})
export class IntimacaoOnlineModule {
  constructor(private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('pt-br')
  }
}
