/* eslint-disable angular/document-service */
import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import { PublicationScheduleDTOModel } from 'src/app/Shared/Models/publication-dtomodel'
import { Session } from 'src/app/Shared/session'

@Component({
  selector: 'app-gestao-agendamentos',
  templateUrl: './gestao-agendamentos.component.html',
  styleUrls: ['./gestao-agendamentos.component.scss']
})
export class GestaoAgendamentosComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) {}

  shared: Session
  agendamentos
  alterar = false
  idalteracao

  @ViewChild('dateUpload', { static: false }) dateChange: ElementRef

  async ngOnInit() {
    await this.loadpage()
  }

  async loadpage() {
    this.spinner.show()
    this.shared = Session.getInstance()

    await this.getHistoricoAgendamento()
  }

  async getHistoricoAgendamento() {
    this.agendamentos = new Array<PublicationScheduleDTOModel>()
    ApiController.getHistoricoAgendamento(this.http).then(
      sucesso => {
        //console.log(sucesso);
        var retorno = <PublicationScheduleDTOModel[]>sucesso
        //console.log(retorno)
        retorno.forEach(element => {
          var dtoview = new PublicationScheduleDTOModel()

          dtoview.id = element.id
          dtoview.dataHoraAgendamento = element.dataHoraAgendamento
          dtoview.arquivo = element.arquivo
          this.agendamentos.push(dtoview)
        })

        this.spinner.hide()
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  removerAgendamento(id) {
    this.spinner.show()
    ApiController.deleteAgendamento(this.http, id).then(
      sucesso => {
        this.alertService.success('Agendamento removido com sucesso.')
        this.getHistoricoAgendamento()
      },
      err => {
        var mapeado = <ErrorModel>err.error
        var erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(mapeado.IEPROErrorID + ' - ' + mapeado.ErroI)
        this.spinner.hide()
        this.alertService.danger('Falha ao deletar publicação - ' + erromapedo)
      }
    )
  }

  async alterarData(id) {
    this.alterar = true
    this.idalteracao = id
  }

  async concluirAlteracao() {
    this.alterar = false
    var objAlterar = new PublicationScheduleDTOModel()
    objAlterar.dataHoraAgendamento = this.dateChange.nativeElement.value
    objAlterar.id = this.idalteracao
    ApiController.putAlterarAgendamento(this.http, objAlterar).then(
      sucesso => {
        this.alertService.success('Agendamento alterado com sucesso.')
        this.loadpage()
      },
      err => {
        console.log(err)
        this.alertService.danger('Falha ao realizar alteração de data.')
      }
    )
  }

  downloadFile(id) {
    this.spinner.show()
    ApiController.downloadDoc(this.http, id).subscribe(result => {
      ///console.log(result)

      var blob = new Blob([result], { type: 'application/pdf' })
      var objectUrl = URL.createObjectURL(blob)
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.setAttribute('style', 'display: none')
      a.href = objectUrl
      a.download = 'agendamento-' + id
      a.click()

      //var url = window.URL.createObjectURL(result);
      //window.open(url);
      this.spinner.hide()
    })
  }

  cancelarEdit() {
    this.alterar = false
  }
}
