import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-control-form-erro',
  templateUrl: './control-form-erro.component.html',
  styleUrls: ['./control-form-erro.component.scss']
})
export class ControlFormErroComponent implements OnInit {
  @Input() msgErro: string
  @Input() mostrarErro: boolean

  constructor() { }

  ngOnInit() {
  }

}
