import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiController } from 'src/app/Core/api-controller';
import { UserCreateModel } from 'src/app/Shared/Models/user-change-model';
import { Session } from 'src/app/Shared/session';
import { CartorioModel } from '../../../../Shared/Models/cartorio-model';
import { GrupoDTOModel } from '../../../../Shared/Models/group-dtomodel';
import { InteiroReturnValor } from '../../../../Shared/Models/remessa-dtomodel';

@Component({
  selector: 'app-cadastro-usuario',
  templateUrl: './cadastro-usuario.component.html',
  styleUrls: ['./cadastro-usuario.component.scss']
})
export class CadastroUsuarioComponent implements OnInit {

  shared : Session;
  objChangeLogin : UserCreateModel;
  exibe : boolean = false; 

  constructor(private http : HttpClient, private router : Router, private spinner: NgxSpinnerService, private alertService: AlertService) { }

  @ViewChild('login', {static: false}) login  : ElementRef;
  @ViewChild('email', {static: false}) email  : ElementRef;
  @ViewChild('documento', {static: false}) documento  : ElementRef;
  @ViewChild('novaSenha', {static: false}) novaSenha  : ElementRef;
  
  async ngOnInit() {
    this.shared = Session.getInstance();

    if(this.shared.getToken() == undefined) {
      this.router.navigate([this.shared.getHomePage()]);
    }

    this.spinner.show();

    var id = this.shared.getCartId();
    if(id == null) {
      await ApiController.getCartorio( this.http )
        .then(
          result => {         
          var cartorio = <CartorioModel>result;     
          this.shared.setCartId(cartorio.id);   
        }
      );
    }
    this.spinner.hide();
  }

  async changeLogin(){
    this.spinner.show();  

    this.objChangeLogin = new UserCreateModel();
    this.objChangeLogin.login = this.login.nativeElement.value;
    this.objChangeLogin.email = this.email.nativeElement.value;
    this.objChangeLogin.documento = this.documento.nativeElement.value;
    this.objChangeLogin.codCart = parseInt(this.shared.getCartId());
    this.objChangeLogin.senha = ApiController.criptText(this.novaSenha.nativeElement.value);

    //console.log(this.objChangeLogin);
    var usuario : InteiroReturnValor;
    var grupos : GrupoDTOModel[];
    var idGrupo;

    if(this.shared.getNewUser()) {
      await ApiController.postCriaUsuario(this.http, this.objChangeLogin)
      .then(
        result => {
          this.spinner.hide();
          usuario = <InteiroReturnValor>result;
          this.alertService.success("Cadastro realizado com sucesso...");  
          this.shared.setNewUser(false); 
          this.router.navigate(["/GestaoCartorio"]);
        },
        err => {
          this.spinner.hide();
          console.log(err);
        }
      )
    }
    else {
      await ApiController.postCriaUsuarioAPI(this.http, this.objChangeLogin)
      .then(
        result => {
          this.spinner.hide();
          usuario = <InteiroReturnValor>result;
        
          this.alertService.success("Cadastro realizado com sucesso...");   
          this.router.navigate(["/integrador"]);
          
        },
        err => {
          this.spinner.hide();
          console.log(err);
        }
      )
    }
  }

  cancelar(){
    if(!this.shared.getNewUser())
      this.router.navigate(["/integrador"]);
    else
      this.router.navigate(["/GestaoCartorio"]);
  }

  maxlength(event): boolean {

    if(this.novaSenha.nativeElement.value.length <= 14) {
      return true;
    }
    else {
      this.alertService.warning("Limite de 15 caracteres atingido!");
      return false;
    } 
  }

}
