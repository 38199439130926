import { Injectable } from '@angular/core'
import { PermissaoModel } from './Models/permissao-model'
import { UserModel } from './Models/user-model'

@Injectable({
  providedIn: 'root'
})
export class Session {

  public static instance: Session = null;
  private user: UserModel
  private login: string
  private accessToken: string
  private tipo: string
  private showTemplate: string
  private cartId: string = null;
  private userId: string
  private remessa: string
  private api: boolean = false;
  private grupoId
  private grupoNome
  public edit
  private novo
  private config
  private configEmolumentos = false
  private novosEmolumentos = false
  private menuapi: boolean = false;
  private menu: boolean = false;
  private newUser: boolean = false;
  private editar: boolean = false;

  private validNewsTimeInDays
  private postXMLStartHour
  private postXMLStartMin
  private postXMLEndHour
  private postXMLEndMin

  private permissao: PermissaoModel

  private homePage: string = '/home';

  constructor () {
    return Session.instance = Session.instance || this
    //this.load();
  }

  public static getInstance() {

    if (this.instance == null || this.instance == undefined) {
      this.instance = new Session()
      this.instance.load()
      if (this.instance.user == null) {
        this.instance.user = new UserModel("", "", "", "", "")
      }


      if (this.instance.permissao == null) {
        this.instance.permissao = new PermissaoModel()
      }

    }

    return this.instance
  }

  load() {
    //console.log(this);
    var me = JSON.parse(localStorage.getItem('currentUser'))
    //console.log(me);  
    if (me != null) {
      this.user = me.user
      this.login = me.login
      this.accessToken = me.accessToken
      this.tipo = me.tipo
      this.showTemplate = me.showTemplate
      this.cartId = me.cartId
      this.userId = me.userId
      this.remessa = me.remessa
      this.grupoId = me.grupoId
      this.grupoNome = me.grupoNome
      this.edit = me.edit
      this.novo = me.novo
      this.configEmolumentos = me.configEmolumentos
      this.novosEmolumentos = me.novosEmolumentos
      this.config = me.config
      this.menu = me.menu
      this.api = me.api
      this.menuapi = me.menuapi

      this.permissao = me.permissao

      /*if (this.permissao.ManagePublication) {
        this.homePage = '/GestaoPublicacoes'; 
      }
      else if(this.permissao.PostXMLFile) {
        this.homePage = '/GestaoTitulos';
      } 
      else if (this.permissao.ManageOffice) {
        this.homePage = '/GestaoCartorio';
      } */

      this.homePage = '/home'

      this.validNewsTimeInDays = me.validNewsTimeInDays
      this.postXMLStartHour = me.postXMLStartHour
      this.postXMLStartMin = me.postXMLStartMin
      this.postXMLEndHour = me.postXMLEndtHour
      this.postXMLEndMin = me.postXMLEndMin

    } else {
      if (this.user == null) {
        this.user = new UserModel("", "", "", "", "")
      }
    }
  }

  save() {
    localStorage.setItem('currentUser', JSON.stringify(this))
  }

  clear() {
    localStorage.removeItem('currentUser')
    this.user = new UserModel("", "", "", "", "")
    this.login = ""
    this.tipo = ""
    this.showTemplate = null
    this.cartId = null
    this.userId = null
    this.remessa = null
    this.grupoId = null
    this.grupoNome = null
    this.edit = null
    this.novo = null
    this.config = false
    this.configEmolumentos = false
    this.novosEmolumentos = false
    this.menu = false
    this.api = false
    this.menuapi = false

    this.validNewsTimeInDays = 4
    this.postXMLStartHour = 8
    this.postXMLStartMin = 0
    this.postXMLEndHour = 18
    this.postXMLEndMin = 0
  };

  public getHomePage() {
    return this.homePage
  }

  public setHomePage(s: string) {
    this.homePage = s
  }

  public setPermissao(p: PermissaoModel) {
    this.permissao = p
    this.save()
  }

  public setEditar(valor: boolean) {
    this.editar = valor
  }

  public getEditar() {
    return this.editar
  }

  public setPermissaoByText(lst: string[]) {

    if (this.permissao == null) {
      this.permissao = new PermissaoModel()
    }

    for (let l in lst) {

      switch (lst[l]) {

        case ("PostXMLFile"): {
          this.permissao.PostXMLFile = true
          break
        }

        case ("ManageXMLFiles"): {
          this.permissao.ManageXMLFiles = true
          break
        }

        case ("ManageOffice"): {
          this.permissao.ManageOffice = true
          break
        }

        case ("ManageTitles"): {
          this.permissao.ManageTitles = true
          break
        }

        case ("ManageSecurity"): {
          this.permissao.ManageSecurity = true
          break
        }

        case ("ManagePublication"): {
          this.permissao.ManagePublication = true
          break
        }

        case ("SendEmails"): {
          this.permissao.SendEmails = true
          break
        }

        case ("UseAPI"): {
          this.permissao.UseAPI = true
          break
        }
      }
    }

    //this.permissao = p;
    this.save()
  }

  public getPermissao() {
    return this.permissao
  }

  public setUser(u: UserModel) {
    this.user = u
    this.save()
  }

  public getUser(): UserModel {
    return this.user
  }

  public setLogin(l: string) {
    this.login = l
    this.save()
  }

  public getLogin(): string {
    return this.login
  }

  public setToken(t: string) {
    this.accessToken = t
    this.save()
  }

  public getToken(): string {
    return this.accessToken
  }

  public setTipo(t: string) {
    this.user.userType = t
    this.tipo = t
    this.save()
  }

  public getTipo(): string {
    return this.tipo
  }

  public setShowTemplate(t: string) {
    this.showTemplate = t
    this.save()
  }

  public getShowTemplate(): string {
    return this.showTemplate
  }

  public setCartId(id: any) {
    this.cartId = id
    this.save()
  }

  public setNewUser(valor: boolean) {
    this.newUser = valor
    this.save()
  }

  public getNewUser(): boolean {
    return this.newUser
  }

  public getCartId(): string {
    return this.cartId
  }


  public setUserId(id: string) {
    this.userId = id
    this.save()
  }

  public getUserId(): string {
    return this.userId
  }

  public setRemessa(r: string) {
    this.remessa = r
    this.save()
  }

  public getRemessa(): string {
    return this.remessa
  }

  public setGrupoId(id) {
    this.grupoId = id
    this.save()
  }

  public getGrupoId() {
    return this.grupoId
  }

  public setGrupoNome(nome) {
    this.grupoNome = nome
    this.save()
  }

  public getGrupoNome() {
    return this.grupoNome
  }

  public setEdit(edit) {
    this.edit = edit
    this.save()
  }

  public getEdit() {
    return this.edit
  }

  public setNovo(novo) {
    this.novo = novo
    this.save()
  }

  public getNovo() {
    return this.novo
  }

  public setConfig(config) {
    this.config = config
    this.save()
  }

  public setConfigEmolumentos(config) {
    this.configEmolumentos = config
    this.save()
  }

  public setnovosEmolumentos(valor) {
    this.novosEmolumentos = valor
    this.save()
  }

  public getnovosEmolumentos() {
    return this.novosEmolumentos
  }
  public setMenuApi(value) {
    this.menuapi = value
    this.save()

  }

  public getConfig() {
    return this.config
  }

  public getConfigEmolumentos() {
    return this.configEmolumentos
  }

  public setMenu(menu: boolean = false) {
    this.menu = menu
    this.save()
  }

  public getMenu() {
    return this.menu
  }

  public setValidNewsTimeInDays(validNewsTimeInDays) {
    this.validNewsTimeInDays = validNewsTimeInDays
    this.save()
  }

  public getValidNewsTimeInDays() {
    return this.validNewsTimeInDays
  }


  public setPostXMLStartHour(postXMLStartHour) {
    this.postXMLStartHour = postXMLStartHour
    this.save()
  }

  public getPostXMLStartHour() {
    return this.postXMLStartHour
  }


  public setPostXMLStartMin(postXMLStartMin) {
    this.postXMLStartMin = postXMLStartMin
    this.save()
  }

  public getPostXMLStartMin() {
    return this.postXMLStartMin
  }


  public setPostXMLEndHour(postXMLEndHour) {
    this.postXMLEndHour = postXMLEndHour
    this.save()
  }

  public getPostXMLEndHour() {
    return this.postXMLEndHour
  }

  public setPostXMLEndMin(postXMLEndMin) {
    this.postXMLEndMin = postXMLEndMin
    this.save()
  }

  public getPostXMLEndMin() {
    return this.postXMLEndMin
  }

  public setApi(valor: boolean) {
    this.api = valor
    this.save()
  }

  public getApi() {
    return this.api
  }




}
