import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/Shared/Models/error-model';
import { Session } from 'src/app/Shared/session';
import { ApiController } from '../../../../Core/api-controller';
import { GrupoDTOModel } from '../../../../Shared/Models/group-dtomodel';

@Component({
  selector: 'app-list-grupos',
  templateUrl: './list-grupos.component.html',
  styleUrls: ['./list-grupos.component.scss']
})
export class ListGruposComponent implements OnInit {

  constructor(private http: HttpClient, private router : Router, private spinner: NgxSpinnerService, private alertService: AlertService) { }

  listaok = false;
  grupos;
  tipoM;
  public shared : Session;

  async ngOnInit() {
    await this.loadpage();
  }

  async loadpage() {
    //this.spinner.show();  
    try {

      this.shared = Session.getInstance();
     
      if(this.shared.getToken() != undefined){

        if(this.shared.getPermissao().ManageSecurity){
          this.shared.setGrupoId(null);        
          this.tipoM = true;
          await this.loadList();
        }
        else {
          console.log("Erro");
        }
      }
      else {
        this.shared.setCartId(null);
        this.router.navigate([this.shared.getHomePage()]); 
      }
      
    } finally {
      //this.spinner.hide();
    }
    
  }

  async loadList() {
    
    try {
      this.spinner.show(); 
      ApiController.getListGroup(this.http)
        .then( lista => {
          this.grupos  = <GrupoDTOModel[]> lista;
          this.listaok = true;
        },
        err => {
          var mapeado = <ErrorModel>err;
          console.log(mapeado);
        } 
      )
    } finally {
      this.spinner.hide();
    }
  }

  editarPermissao(id, nome) {
    this.shared = Session.getInstance();
    this.shared.setEdit(true);
    this.shared.setGrupoId(id);
    this.shared.setGrupoNome(nome);
    this.router.navigate(['/editPermissao']);
  }

  editarUsuarios(id, nome) {
    this.shared = Session.getInstance(); 
    
    this.shared.setEdit(true);
    this.shared.setGrupoId(id);
    this.shared.setGrupoNome(nome);
    //console.log('id:' + id + '/nome:' + nome);
    this.router.navigate(['/editUsersGrupo']);
  }

  viewUsuarios(id, nome) {
    this.shared = Session.getInstance();

    this.shared.setEdit(false);
    this.shared.setGrupoId(id);
    this.shared.setGrupoNome(nome);

    //console.log('id:' + id + '/nome:' + nome);
    this.router.navigate(['/editUsersGrupo']);
  }

  removerGrupo(id) {
    ApiController.deleteGrupo(this.http, id)
      .then ( sucesso => {
        if(sucesso) {
          this.alertService.success("Grupo removido com sucesso.");
          this.loadpage();
        }
        else {
          this.alertService.danger("Falha ao remover grupo.");
        }
      },
     err => {
      var mapeado = <ErrorModel>err;
      console.log(mapeado);
      }
    );
  }
}
