import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { Observable } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { CartorioAllDTOModel } from 'src/app/Shared/Models/cartorio-all-dtomodel'
import { IntimacoesDataService } from '../../shared/intimacoes-data.service'
import { DetalheProtocoloIntimacoes } from './../../shared/detalhe-protocolo-intimacoes'

@Component({
  selector: 'app-relatorio-iepro-protocolo',
  templateUrl: './relatorio-iepro-protocolo.component.html',
  styleUrls: ['./relatorio-iepro-protocolo.component.scss']
})
export class RelatorioIeproProtocoloComponent implements OnInit {

  public protocolo: string = ''
  public detalheIntimacoes: DetalheProtocoloIntimacoes[]
  public cartorios: CartorioAllDTOModel[]
  public idCartorio: any
  semNotificacao: any
  page = ""
  active = ''

  constructor(
    private dataService: IntimacoesDataService,
    public statusDataService: IntimacoesDataService,
    private http: HttpClient) { }

  ngOnInit() {
    this.buscarCartorio()
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.cartorios.filter(v => v.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: { descricao: string }) => x.descricao;

  buscarCartorio() {
    this.statusDataService.getTodosCartorios().subscribe(
      dados => {
        this.cartorios = dados
      }
    )
  }

  buscar() {
    this.detalheIntimacoes = null
    this.dataService.getBuscaProtocoloIepro(this.idCartorio.id, this.protocolo).subscribe(
      dados => {
        this.detalheIntimacoes = dados
        this.semNotificacao = dados
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)
      }

      )
    this.active = ''

    }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.page = "1"
  }

}
