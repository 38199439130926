import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { NgxPaginationModule } from 'ngx-pagination'
import { GestaoTitulosComponent } from './gestao-titulos/gestao-titulos.component'

@NgModule({
  declarations: [GestaoTitulosComponent],
  imports: [
    CommonModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  exports: [GestaoTitulosComponent]
})
export class GestaoTitulosModule { }
