import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { NgxPaginationModule } from 'ngx-pagination'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { GestaoCartorioComponent } from './gestao-cartorio/gestao-cartorio.component'

@NgModule({
  declarations: [GestaoCartorioComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    GestaoCartorioComponent
  ]
})
export class GestaoCartorioModule { }