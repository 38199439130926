import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, EventEmitter, Injectable, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { ChangePassModel } from 'src/app/Shared/Models/change-pass-model'
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model'
import { Session } from 'src/app/Shared/session'


@Injectable({
  providedIn: 'root',
})


@Component({
  selector: 'app-alterar-pass',
  templateUrl: './alterar-pass.component.html',
  styleUrls: ['./alterar-pass.component.scss']
})
export class AlterarPassComponent implements OnInit {

  objChange: ChangePassModel
  shared: Session
  objDados: UserChangeModel
  exibeSelecionado: boolean = false;
  usuarios
  idAtual
  selecionavel = false;
  usuarioLogin = ""
  usuarioId = ""
  closeBtnName

  @Output() onHide = new EventEmitter<void>();

  constructor (
    private router: Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef
  ) { }

  @ViewChild('senhaAtual', { static: false }) senhaatual: ElementRef
  @ViewChild('novaSenha', { static: false }) senhaNova: ElementRef
  @ViewChild('novaSenhaT', { static: false }) senhaNovaT: ElementRef
  @ViewChild('option', { static: false }) option: ElementRef

  async ngOnInit() {
    this.shared = Session.getInstance()
    if (this.shared.getCartId() == null)
      this.shared.setCartId(-1)

    if (this.shared.getToken() == undefined) {
      this.router.navigate([this.shared.getHomePage()])
    }
    else if (parseInt(this.shared.getCartId()) >= 0) {
      await this.getUsers()
      this.selecionavel = true
    }
    else {
      await ApiController.getIdCartorio(this.http)
        .then(sucesso => {
          this.shared.setCartId(sucesso)
        }
        )
      await this.getUsers()
      this.selecionavel = true
    }
  }

  async selecionarUsuario() {
    this.exibeSelecionado = true
    /*
    if usuario selecionado == o meu usuário
      tem que colocar a senha antiga
    else
      somente senha nova
    */
    //console.log(this.shared)

    var usuarioId = this.option.nativeElement.value.split('-')
    //console.log(this.shared.getLogin());
    this.usuarioLogin = usuarioId[1].trim()
    this.usuarioId = usuarioId[0].trim()
    if (this.usuarioLogin == this.shared.getLogin()) {
      this.selecionavel = false
    }
  }

  async getUsers() {
    ApiController.getUsuariosbyCartId(this.http, this.shared.getCartId())
      .then(usuarios => {
        this.usuarios = <UserChangeModel>usuarios
      },
        err => {
          console.log(err)
        })
  }

  loadFieldsAndChange() {

    ApiController.getDadosLoginId(this.http, this.shared.getCartId())
      .then(sucesso => {
        this.objDados = <UserChangeModel>sucesso
        this.objChange.senhaAntiga = ''

        ApiController.putNovaSenha(this.http, this.objChange, this.usuarioId)
          .then(
            result => {
              this.spinner.hide()
              this.alertService.success("Alterações realizadas com sucesso...")
              this.doHide()
            },
            err => {
              this.spinner.hide()
              console.log(err)
            }
          )
      },
        err => {
          this.objDados = new UserChangeModel()
          console.log(err)
        })
  }

  async changePass() {

    if (this.senhaNova.nativeElement.value != ""
      && this.senhaNovaT.nativeElement.value != "") {

      this.objChange = new ChangePassModel()
      if (parseInt(this.shared.getCartId()) == -1)
        this.objChange.senhaAntiga = ApiController.criptText(this.senhaatual.nativeElement.value)

      //console.log(this.shared.getLogin())

      if (this.usuarioLogin == this.shared.getLogin()) {
        this.objChange.senhaAntiga = ApiController.criptText(this.senhaatual.nativeElement.value)
      }
      var nova = ApiController.criptText(this.senhaNova.nativeElement.value)
      var confirm = ApiController.criptText(this.senhaNovaT.nativeElement.value)

      if (nova == confirm) {
        this.spinner.show()

        this.objChange.senhaNova = nova
        //console.log(this.usuarioLogin)
        //console.log(this.shared.getLogin())
        if (this.usuarioLogin != this.shared.getLogin()) {

          await this.loadFieldsAndChange()

        }
        else {
          //console.log(this.objChange)
          await ApiController.putAlterarSenha(this.http, this.objChange)
            .then(
              result => {
                this.spinner.hide()
                this.alertService.success("Alterações realizadas com sucesso...")
                this.router.navigate([this.shared.getHomePage()])
              },
              err => {
                this.spinner.hide()
                console.log(err)
                this.spinner.hide()
              }
            )
        }
      }
      else {
        this.spinner.hide()

        this.alertService.warning("Novas senhas não conferem...")
      }
    }
    else {
      this.spinner.hide()

      this.alertService.warning("Verifique os dados inseridos...")
    }
  }

  maxlength(event): boolean {

    if (this.senhaNova.nativeElement.value.length <= 14) {
      return true
    }
    else {
      this.alertService.warning("Limite de 15 caracteres atingido!")
      return false
    }
  }

  cancelar() {
    this.router.navigate([this.shared.getHomePage()])
  }

  doHide() {
    this.bsModalRef.hide()
  }
}
