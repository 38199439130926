import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiController } from 'src/app/Core/api-controller';
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model';
import { Session } from 'src/app/Shared/session';

@Component({
  selector: 'app-integrador-home',
  templateUrl: './integrador-home.component.html',
  styleUrls: ['./integrador-home.component.scss']
})
export class IntegradorHomeComponent implements OnInit {

  constructor( private http : HttpClient, private router : Router, private spinner: NgxSpinnerService, private alertService: AlertService) { }

  usuario : UserChangeModel;
  usuarios;
  exibe : boolean = false;
  existe : boolean = false;
  session : Session;
  @ViewChild('option', {static: false}) option : ElementRef;

  async ngOnInit() {
    this.session = Session.getInstance();

    this.loadUsuarios();
  }

  async loadUsuarios() {
    this.spinner.show();
    await ApiController.getUsuariosAPI(this.http)
    .then( sucesso => {
      this.usuario = <UserChangeModel>sucesso;
      this.spinner.hide();
    },
    err => {
      console.log(err);
      this.usuario = new UserChangeModel();
      this.exibe = false;
      this.spinner.hide();

    })

    if(this.usuario == null) {
      this.usuario = new UserChangeModel();
      this.usuario.login = "Sem login definido";
      this.usuario.email = "Sem e-mail definido";
      this.usuario.documento = "Sem documento definido";
      this.existe = false;
    }
    else
      this.existe = true;
    
    this.exibe = true;
  }

  adicionarAPI() {
    this.session.setApi(true);
    this.session.setNewUser(false);
    this.router.navigate(['/cadastroUsuario']);
  }

}
