import { Component, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-relatorio-intimacoes',
  templateUrl: './relatorio-intimacoes.component.html',
  styleUrls: ['./relatorio-intimacoes.component.scss']
})
export class RelatorioIntimacoesComponent implements OnInit {

  public bsModalRef: BsModalRef
  public buscaData: boolean =false
  public buscaProtocolo: boolean = false
  public buscaDocumento: boolean = false

  constructor(
    private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  openBuscaData() {
    return this.buscaData = true,
    this.buscaProtocolo = false,
    this.buscaDocumento = false
  }

  openBuscaProtocolo() {
    return this.buscaProtocolo = true,
    this.buscaData = false,
    this.buscaDocumento = false
  }

  openBuscaDocumento() {
    return this.buscaDocumento = true,
    this.buscaProtocolo = false,
    this.buscaData = false
  }

}
