/* eslint-disable angular/window-service */
import { Location } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { ConfigDtoModel } from 'src/app/Shared/Models/config-dto-model'
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model'
import { Session } from 'src/app/Shared/session'
import { CurrentUser } from '../../../../Shared/Models/current-user-model'
import { UserLogin } from '../../../../Shared/Models/user-model'
import { UserService } from '../user.service'

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss']
})
export class FormLoginComponent implements OnInit {
  user = new UserLogin('', '') // UserModel('','','','','');
  session: Session
  message: string
  UserNameToShow: string
  returnUrl: string
  exibir
  usuario: UserChangeModel
  urlNavigate: string
  seguir: boolean = false
  recuperar: boolean = false
  permissao: string[]
  userMapeado: any
  configMapeada: any

  constructor(
    private location: Location,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) {}

  @ViewChild('emailRecup', { static: false }) emailRecup: ElementRef
  @ViewChild('email', { static: false }) email: ElementRef
  @ViewChild('password', { static: false }) password: ElementRef

  ngOnInit() {
    this.session = Session.getInstance()
    //console.log(this.session);

    /* Create an alert to show if the browser is IE or not */

    if (this.session.getToken() != undefined) {
      this.session.setMenu(true)
      this.session.setMenuApi(false)

      this.UserNameToShow = this.session.getLogin()
      if (this.UserNameToShow.length >= 15) {
        this.UserNameToShow = this.UserNameToShow.slice(0, 13) + '...'
      }

      ApiController.getConfig(this.http).then(
        sucesso => {
          this.session = Session.getInstance()
          var mapeado = <ConfigDtoModel>sucesso

          this.session.setValidNewsTimeInDays(mapeado.validNewsTimeInDays)
          this.session.setPostXMLStartHour(mapeado.postXMLStartHour)
          this.session.setPostXMLStartMin(mapeado.postXMLStartMin)

          this.session.setPostXMLEndMin(mapeado.postXMLEndMin)
          this.session.setPostXMLEndHour(mapeado.postXMLEndHour)

          this.spinner.hide()
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
    } else {
      this.session.clear()
    }
  }

  loadFields() {
    ApiController.getDadosLogin(this.http).then(
      sucesso => {
        this.usuario = <UserChangeModel>sucesso

        //console.log("User login: " + this.usuario.toString());
        if (this.usuario.login.length >= 15) {
          this.usuario.login = this.usuario.login.slice(0, 13) + '...'
        }
      },
      err => {
        this.usuario = new UserChangeModel()
        console.log('User login: ' + err)
      }
    )
  }

  login1() {
    this.message = ''
    //console.log(this.user);
    this.userService.login(this.user).subscribe(
      sucesso => {
        //console.log(sucesso);
        return <CurrentUser>sucesso
      },
      err => {
        console.log(err)
      }
    )
  }

  login() {
    this.spinner.show()
    this.user.login = this.email.nativeElement.value.trim()
    this.user.password = this.password.nativeElement.value.trim()

    this.session = Session.getInstance()
    this.session.setLogin(this.user.login)
    // var that = this.session

    this.userService.login(this.user).subscribe(
      userAuthentication => {
        this.userMapeado = <CurrentUser>userAuthentication

        if (this.userMapeado.accessToken != null) {
          this.session.setMenu(true)
          this.session.setToken(this.userMapeado.accessToken)

          // that.setCartId(null)
          this.UserNameToShow = this.user.login
          if (this.UserNameToShow.length >= 15) {
            this.UserNameToShow = this.UserNameToShow.slice(0, 13) + '...'
          }
          ApiController.getMinhasPermissoes(this.http).subscribe(permissoes => {
            // popular as infos na tela
            this.permissao = <string[]>permissoes
            //console.log(permissao);

            this.session.setPermissaoByText(this.permissao)
            //console.log("6 -"+"apos set delas");
            window.location.reload()
            this.spinner.hide()
          })

          this.seguir = true
        }

        // this.urlNavigate = '/home'
      },
      err => {
        console.log(err)
        this.spinner.hide()
        this.seguir = false
      }
    )

    // this.session = that
    if (this.seguir) {
      ApiController.getConfig(this.http).then(
        sucesso => {
          this.spinner.show()
          // window.location.reload()

          // popular as infos na tela
          this.configMapeada = <ConfigDtoModel>sucesso

          this.session.setValidNewsTimeInDays(
            this.configMapeada.validNewsTimeInDays
          )
          this.session.setPostXMLStartHour(this.configMapeada.postXMLStartHour)
          this.session.setPostXMLStartMin(this.configMapeada.postXMLStartMin)

          this.session.setPostXMLEndMin(this.configMapeada.postXMLEndMin)
          this.session.setPostXMLEndHour(this.configMapeada.postXMLEndHour)
          this.spinner.hide()
          //console.log("8 -"+"depois de set todo mundo");
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )

      //console.log("9 -"+"antes de cartorioCompleto");

      ApiController.getCadastroCartorioCompleto(this.http).then(
        result => {
          this.spinner.hide()
          if (
            this.session.getPermissao().ManagePublication ||
            this.session.getPermissao().ManageOffice ||
            this.session.getPermissao().PostXMLFile ||
            this.session.getPermissao().UseAPI
          ) {
            // this.session.setHomePage('/home')
          } else {
            this.alertService.danger(
              'Usuário sem grupo de permissões cadastradas...'
            )
            this.session.clear()
          }

          // this.returnUrl = this.route.snapshot.queryParams['returnUrl']
          // this.router.navigate([this.returnUrl])
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
    }
    // this.router.navigate(['/home'])
  }
  recuperarSenha() {
    this.spinner.show()
    var emailR = this.emailRecup.nativeElement.value

    if (emailR.length > 3) {
      ApiController.getEsqueciSenha(this.http, emailR).then(
        sucesso => {
          this.spinner.hide()
          // Sucesso
          this.recuperar = false
          this.alertService.success(
            'E-mail de recuperação enviado com sucesso!'
          )
        },
        err => {
          this.spinner.hide()
          this.alertService.danger('Falha ao realizar recuperação')
        }
      )
    } else {
      this.spinner.hide()
      this.alertService.warning('Informe um e-mail válido')
    }
  }

  esqueciSenha() {
    this.recuperar = true
  }

  cancelar() {
    this.recuperar = false
  }
}
