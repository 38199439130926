/* eslint-disable angular/window-service */
import { HttpClient } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { Router } from '@angular/router'
import { LocalDataSource, ViewCell } from 'ng2-smart-table'
import { NgxSpinnerService } from 'ngx-spinner'
import { urlAcesso } from 'src/app/app.host'
import { PublicationAllDTOModel } from 'src/app/Shared/Models/publication-dtomodel'
import { Session } from 'src/app/Shared/session'
import { DownloadPublicacoesService } from '../services/download-publicacoes.service'

@Component({
  selector: 'checkbox-view',
  templateUrl: './download-publicacoes-checkbox.html'
})
export class CheckboxViewComponent implements ViewCell, OnInit {
  renderValue: boolean = false
  @Input() value: string
  @Input() rowData: any

  ngOnInit() {
    this.renderValue = this.rowData.ativa
  }
}

@Component({
  selector: 'button-view',
  styleUrls: ['./download-publicacoes.component.scss'],
  template: `
    <button
      class="btn btn-primary btn-baixar"
      (click)="downloadPDFWithoutTitles()"
    >
      {{ renderValue }}
    </button>
  `
})
export class ButtonViewComponent implements ViewCell, OnInit {
  renderValue: string

  @Input() value: string
  @Input() rowData: any

  @Output() save: EventEmitter<any> = new EventEmitter()

  ngOnInit() {
    this.renderValue = 'Baixar' //this.value.toString().toUpperCase();
  }

  onClick() {
    this.save.emit(this.rowData)
  }

  downloadPDFWithoutTitles() {
    this.service.downloadDoc(this.rowData.id).subscribe(result => {
      var url = window.URL.createObjectURL(result)
      window.open(url)
    })
  }

  downloadPDTWithTitles() {
    window.open(urlAcesso + 'PDF/' + this.rowData.arquivo)
  }

  constructor(
    private service: DownloadPublicacoesService,
    private http: HttpClient
  ) {}
}

@Component({
  selector: 'app-download-publicacoes',
  templateUrl: './download-publicacoes.component.html',
  styleUrls: ['./download-publicacoes.component.scss']
})
export class DownloadPublicacoesComponent implements OnInit {
  shared: Session
  source: LocalDataSource // add a property to the component

  settings = {
    actions: {
      add: false,
      edit: false,
      delete: false
    },
    columns: {
      id: {
        title: 'ID',
        editable: false,
        filter: false,
        sortDirection: 'desc'
      },
      /*     arquivo: {
             title: 'Arquivo',
             editable: false,
             filter: false
           },*/
      publicacao: {
        title: 'Data de Publicação',
        editable: false,
        filter: false
      },
      ativa: {
        title: 'Ativa',
        editable: false,
        filter: false,
        type: 'custom',
        renderComponent: CheckboxViewComponent,
        width: '10px'
      },
      tipo: {
        title: 'Tipo de Publicação',
        editable: false,
        filter: false
      },
      path: {
        title: 'Download',
        editable: false,
        type: 'custom',

        filter: false,
        renderComponent: ButtonViewComponent,
        onComponentInitFunction(instance) {
          instance.save.subscribe(row => {
            alert(`${row.path} saved!`)
          })
        }
      }
    }
  }

  constructor(
    private service: DownloadPublicacoesService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show()
    var dtos: Array<PublicationAllDTOModel>
    this.service.getAllPublications().then(
      sucesso => {
        dtos = <PublicationAllDTOModel[]>sucesso
        dtos.forEach(element => {
          if (element.tipo.toUpperCase() == 'A') element.tipo = 'Automática'
          else element.tipo = 'Manual'
        })

        this.source = new LocalDataSource(dtos) // create the sourc
        this.spinner.hide()
      },
      err => {
        console.log(err)
        this.spinner.hide()
      }
    )
  }
}
