import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from '../_guards/auth.guard'
import { RelatorioIntimacoesComponent } from './gestao-de-intimacoes/relatorio-intimacoes/relatorio-intimacoes.component'
import { InclusaoDeTermoIeproComponent } from './inclusao-de-termo-iepro/inclusao-de-termo-iepro.component'
import { RelatorioIeproComponent } from './relatorio-iepro/relatorio-iepro.component'

const routes: Routes = [
  { path: 'relatorio-intimacoes', component: RelatorioIntimacoesComponent, canActivate: [AuthGuard] },
  { path: 'relatorio-iepro', component: RelatorioIeproComponent, canActivate: [AuthGuard] },
  { path: 'inclusao-termo-aceite', component: InclusaoDeTermoIeproComponent, canActivate: [AuthGuard] }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IntimadorOnlineRoutingModule { }
