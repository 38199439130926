import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { Ng2SearchPipeModule } from 'ng2-search-filter'
import { NgxPaginationModule } from 'ngx-pagination'
import { CriarGrupoComponent } from './criar-grupo/criar-grupo.component'
import { EditPermissoesComponent } from './edit-permissoes/edit-permissoes.component'
import { EditUsuariosGrupoComponent } from './edit-usuarios-grupo/edit-usuarios-grupo.component'
import { ListGruposComponent } from './list-grupos/list-grupos.component'

@NgModule({
  declarations: [CriarGrupoComponent, ListGruposComponent, EditPermissoesComponent, EditUsuariosGrupoComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    CriarGrupoComponent,
    ListGruposComponent,
    EditPermissoesComponent,
    EditUsuariosGrupoComponent
  ]
})
export class ConfigGruposModule { }
