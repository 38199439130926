/* eslint-disable angular/document-service */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { NgxSpinnerService } from 'ngx-spinner'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import { Session } from 'src/app/Shared/session'
import { ApiController } from '../../../../Core/api-controller'
import {
  RemessaDTOModel,
  valorModelString
} from '../../../../Shared/Models/remessa-dtomodel'

@Component({
  selector: 'app-view-remessa-suporte',
  templateUrl: './view-remessa-suporte.component.html',
  styleUrls: ['./view-remessa-suporte.component.scss']
})
export class ViewRemessaSuporteComponent implements OnInit {
  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {}

  public shared: Session
  tipoS
  tipoM
  publicacoes
  term
  p: number = 1

  ngOnInit() {
    this.spinner.show()
    this.shared = Session.getInstance()

    if (this.shared.getPermissao().ManageSecurity) {
      this.tipoM = true
      this.tipoS = false
    } else if (this.shared.getPermissao().ManageOffice) {
      this.tipoM = false
      this.tipoS = true
    }

    this.getHistoricoRemessas(this.shared.getCartId())
  }

  getHistoricoRemessas(id) {
    ApiController.getViewRemessa(id, this.http).then(
      sucesso => {
        this.spinner.hide()
        let hh = new Array<RemessaDTOModel>()
        let alterando = <RemessaDTOModel[]>sucesso

        alterando.forEach(element => {
          if (element.status === 'Enviada') {
            element.status = 'Erro na remessa'
          }
          hh.push(element)
        })

        this.publicacoes = hh
      },
      err => {
        this.spinner.hide()
        console.log(err)
      }
    )
  }

  downloadXML(id) {
    this.spinner.show()
    ApiController.getXMLtoDownload(id, this.http).then(
      sucesso => {
        let mapeado = <valorModelString>sucesso

        let blob = new Blob([atob(mapeado.valor)], { type: 'application/xml' })
        let objectUrl = URL.createObjectURL(blob)
        let a = document.createElement('a')
        document.body.appendChild(a)
        a.setAttribute('style', 'display: none')
        a.href = objectUrl
        a.download = 'remessaId-' + id
        a.click()

        this.spinner.hide()
      },
      err => {
        console.log(err)
        let mapeado = <ErrorModel>err.error
        let erromapedo = ApiController.mapError(
          mapeado.IEPROErrorID,
          mapeado.Mensagem,
          mapeado.ErroI
        )
        console.log(mapeado.IEPROErrorID + ' - ' + mapeado.ErroI)
        this.spinner.hide()
      }
    )
  }

  cancelar() {
    this.router.navigate(['/GestaoCartorio'])
  }
}
