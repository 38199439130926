import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewTitulosComponent } from './view-titulos/view-titulos.component';

@NgModule({
  declarations: [ViewTitulosComponent],
  imports: [
    CommonModule
  ],
  exports: [
    ViewTitulosComponent
  ]
})
export class ViewTitulosModule { }
