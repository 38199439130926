import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DownloadPublicacoesComponent, ButtonViewComponent,CheckboxViewComponent } from './components/download-publicacoes.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';

@NgModule({
  declarations: [
    DownloadPublicacoesComponent, 
    ButtonViewComponent,
    CheckboxViewComponent
  ],
  imports: [
    CommonModule,
    Ng2SmartTableModule
  ],
  entryComponents: [
    ButtonViewComponent,
    CheckboxViewComponent
  ]
})
export class DownloadPublicacoesModule { }
