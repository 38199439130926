import { Component, OnInit } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { CadastroEmpresaComponent } from './../cadastro-empresa/cadastro-empresa.component'

@Component({
  selector: 'app-barra-acoes',
  templateUrl: './barra-acoes.component.html',
  styleUrls: ['./barra-acoes.component.scss']
})
export class BarraAcoesComponent implements OnInit {

  public bsModalRef: BsModalRef


  constructor (private modalService: BsModalService
  ) { }

  ngOnInit() {
  }

  openModalCadastraEmpresa() {
    this.bsModalRef = this.modalService.show(CadastroEmpresaComponent, Object.assign({}, { class: 'gray modal-lg' }))
    this.bsModalRef.content.closeBtnName = 'Close'
  }

}
