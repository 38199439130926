import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, EventEmitter, Injectable, OnInit, Output, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model'
import { Session } from 'src/app/Shared/session'

@Injectable({
  providedIn: 'root',
})



@Component({
  selector: 'app-alterar-login',
  templateUrl: './alterar-login.component.html',
  styleUrls: ['./alterar-login.component.scss']
})
export class AlterarLoginComponent implements OnInit {

  objChangeLogin: UserChangeModel
  shared: Session
  objDados: UserChangeModel
  exibe: boolean = false;
  exibeSelecionado: boolean = false;
  usuarios
  idAtual
  selecionavel = false;
  closeBtnName: string

  @Output() onHide = new EventEmitter<void>();

  constructor (
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef
  ) {

    this.shared = Session.getInstance()
  }

  @ViewChild('login', { static: false }) login: ElementRef
  @ViewChild('email', { static: false }) email: ElementRef
  @ViewChild('documento', { static: false }) documento: ElementRef
  @ViewChild('option', { static: false }) option: ElementRef

  ngOnInit() {
    this.shared = Session.getInstance()

    if (this.shared.getToken() == undefined) {
      this.router.navigate([this.shared.getHomePage()])
    }
    else if (this.shared.getCartId() != null) {
      this.selecionavel = true
      this.getUsers()
      this.loadFields(true)
    }
    else {
      this.selecionavel = true

      ApiController.getIdCartorio(this.http)
        .then(sucesso => {
          this.shared.setCartId(sucesso)
        })

      this.getUsers()

      this.loadFields(true)
    }
  }


  async getUsers() {
    ApiController.getUsuariosbyCartId(this.http, this.shared.getCartId())
      .then(usuarios => {
        this.usuarios = <UserChangeModel>usuarios
        //console.log(this.usuarios);
        this.exibe = true
      },
        err => {
          console.log(err)
        })
  }

  async loadFields(exibeselecionado) {

    ApiController.getDadosLoginId(this.http, this.shared.getCartId())
      .then(sucesso => {
        this.exibe = true
        if (exibeselecionado) {
          this.exibeSelecionado = false
        }
        else {
          this.exibeSelecionado = true
        }

        if (this.shared.getCartId())
          this.objDados = <UserChangeModel>sucesso
        else
          this.objDados = new UserChangeModel()

        //console.log(this.objDados);
      },
        err => {
          this.objDados = new UserChangeModel()
          this.exibe = true
          this.exibeSelecionado = true
        })
  }

  async changeLogin() {
    this.spinner.show()

    this.objChangeLogin = new UserChangeModel()
    this.objChangeLogin.login = this.login.nativeElement.value
    this.objChangeLogin.email = this.email.nativeElement.value
    this.objChangeLogin.documento = this.documento.nativeElement.value

    this.objChangeLogin.id = this.objDados.id

    await ApiController.putAlterarLogin(this.http, this.objChangeLogin)
      .then(
        result => {
          this.spinner.hide()

          this.alertService.success("Alterações realizadas com sucesso...")
          this.doHide()

        },
        err => {
          this.spinner.hide()
        }
      )
  }

  async buscarDados() {
    var usuarioId = this.option.nativeElement.value.split('-')
    this.idAtual = usuarioId[0]
    this.usuarios.forEach(usuario => {
      if (usuario.id == this.idAtual) {
        this.objDados = usuario
        return
      }
    })

    this.exibeSelecionado = true
  }


  doHide() {
    this.bsModalRef.hide()
  }

}