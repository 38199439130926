import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { Observable } from 'rxjs'
import { debounceTime, map } from 'rxjs/operators'
import { CartorioAllDTOModel } from 'src/app/Shared/Models/cartorio-all-dtomodel'
import { DetalheDocumentoIntimacoes } from './../../shared/detalhe-documento-intimacoes'
import { IntimacoesDataService } from './../../shared/intimacoes-data.service'


@Component({
  selector: 'app-relatorio-iepro-documento',
  templateUrl: './relatorio-iepro-documento.component.html',
  styleUrls: ['./relatorio-iepro-documento.component.scss']
})
export class RelatorioIeproDocumentoComponent implements OnInit {

  public documento: string = ''
  public detalheIntimacoes: DetalheDocumentoIntimacoes[]
  public cartorios: CartorioAllDTOModel[]
  public idCartorio: any
  semNotificacao: any
  page = ""
  active = ''

  constructor(
    private dataService: IntimacoesDataService,
    public statusDataService: IntimacoesDataService,
    private http: HttpClient) { }

  ngOnInit() {
    this.buscarCartorio()
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => term === '' ? []
        : this.cartorios.filter(v => v.descricao.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  formatter = (x: { descricao: string }) => x.descricao;

  buscarCartorio() {
    this.statusDataService.getTodosCartorios().subscribe(
      dados => {
        this.cartorios = dados
      }
    )
  }

  buscar() {
    this.detalheIntimacoes = null
    this.dataService.getBuscaDocumentoIepro(this.idCartorio.id, this.documento).subscribe(
      dados => {
        this.detalheIntimacoes = dados
        this.semNotificacao = dados
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)
      }

      )
    this.active = ''

    }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.page = "1"
  }

}
