import { DatepickerFormatOptions } from 'ngx-bootstrap/datepicker/models';

export class PublicationDTOModel {    

    id : number;

    dataHoraEnvio : string;

    expirado : boolean;

    tipo : string;

    idagendamento : number

}

export class PublicationDTOModelView {

    id : number;

    dataHoraEnvio : string;

    expirado : string;

    tipo : string;

    idagendamento : number

    status : Boolean

}

export class PublicationScheduleDTOModel {

    id : number;

    dataHoraAgendamento : string;

    arquivo;
}



export class PublicationAllDTOModel {

    id : number;

    arquio : string;

    publicacao : Date;

    ativa: Boolean;

    tipo : string;

    path: string;
}
