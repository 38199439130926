import { Component, OnInit } from '@angular/core'
import { IntimacoesDataService } from '../../../shared/intimacoes-data.service'
import { StatusIntimacoes } from '../../../shared/status-intimacoes.model'

@Component({
  selector: 'app-consulta-status-protocolo',
  templateUrl: './consulta-status-protocolo.component.html',
  styleUrls: ['./consulta-status-protocolo.component.scss']
})
export class ConsultaStatusProtocoloComponent implements OnInit {
  
  public intimacoes: StatusIntimacoes[]
  public protocolo: string = ''
  public statusIntimacoes: any
  semNotificacao: any
  page: number = 0;


  constructor(
    private dataService: IntimacoesDataService
  ) { }

  ngOnInit() {
  }

  buscar() {
    this.dataService.getBuscaProtocolo(this.protocolo).subscribe(
      dados => {
        this.intimacoes = dados
        this.statusIntimacoes = this.intimacoes
        this.semNotificacao = dados
        if (this.semNotificacao.message != undefined)
          this.dataService.mesageHttp200(this.semNotificacao.message)
      }

      )

    }



}
