import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { CompUploadFilesComponent } from './comp-upload-files/comp-upload-files.component'
import { DragDropDirective } from './drag-drop.directive'


@NgModule({
  declarations: [
    CompUploadFilesComponent,
    DragDropDirective
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CompUploadFilesComponent
  ],
})

export class UploadFilesModule { }
