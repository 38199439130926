import { Injectable } from '@angular/core'
import * as jwt_decode from 'jwt-decode'
import { Session } from '../session'


@Injectable({
  providedIn: 'root'
})
export class PermissoesToken {
  session: Session

  constructor () { }

  getPermissaoIntimadorToken() {
    this.session = Session.getInstance()
    var token = this.session.getToken()
    var decode = jwt_decode(token)
    return decode
  }
}
