import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Session } from 'src/app/Shared/session';


@Component({
  selector: 'app-informativa1',
  templateUrl: './informativa1.component.html',
  styleUrls: ['./informativa1.component.scss']
})
export class Informativa1Component implements OnInit {

  constructor(private router : Router) { }

  shared: Session; 

  ngOnInit() {
    //this.shared = new Session();
    

    //if(this.shared.token == undefined)
    //this.router.navigate(['/']);
  }

  editar()
  {
    this.router.navigate(['/EditCartorio']);
  }

  cancelar(){
    localStorage.removeItem('currentUser');
    this.shared = Session.getInstance();
    this.router.navigate([this.shared.getHomePage()]);    
}

}
