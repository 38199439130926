export class Contato {
    documento: string
    email: string
    mensagem: string
    nome: string
    telefone: string
    ocorrencia: { ocorrenciaUser, dataOcorrencia, usuarioIepro }


}
