export class UserChangeModel {
    
    id : number;
    login : string;
    email : string;
    documento : string;
    
}

export class UserCreateModel {
    
    login : string;

    codCart : number;

    email : string;

    senha : string;

    documento : string
}
