/* eslint-disable angular/window-service */
/* eslint-disable angular/document-service */
import { HttpClient } from '@angular/common/http'
import {
  Component,
  ElementRef,
  Injectable,
  OnInit,
  ViewChild
} from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { Session } from 'src/app/Shared/session'
import { ApiController } from '../../../../Core/api-controller'
import { CartorioModel } from '../../../../Shared/Models/cartorio-model'

@Component({
  selector: 'app-edicaousuario',
  templateUrl: './edicaousuario.component.html',
  styleUrls: ['./edicaousuario.component.scss']
})
@Injectable()
export class EdicaousuarioComponent implements OnInit {
  editar = false
  cartorio
  shared: Session

  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private alertService: AlertService
  ) {}

  @ViewChild('description', { static: false }) description: ElementRef
  @ViewChild('cnpj', { static: false }) cnpj: ElementRef
  @ViewChild('responsible', { static: false }) responsible: ElementRef
  @ViewChild('responsibleDocument', { static: false })
  responsibleDocument: ElementRef
  @ViewChild('email', { static: false }) email: ElementRef
  @ViewChild('address', { static: false }) address: ElementRef
  @ViewChild('district', { static: false }) district: ElementRef
  @ViewChild('phoneNumber', { static: false }) phoneNumber: ElementRef
  @ViewChild('cep', { static: false }) cep: ElementRef
  @ViewChild('comarca', { static: false }) comarca: ElementRef

  async ngOnInit() {
    this.cartorio = new CartorioModel()
    this.shared = Session.getInstance()
    this.editar = this.shared.getEditar()

    //console.log(this.editar);
    await this.loadFields()
  }

  async loadFields() {
    if (!this.editar) {
      const inputs = document.getElementsByClassName('form-control')
      for (let index = 0; index < inputs.length; index++) {
        const element = inputs[index] as HTMLInputElement
        element.readOnly = true
      }
    }

    this.spinner.show()
    if (this.shared.getCartId()) {
      ApiController.getCartorioId(this.http, this.shared.getCartId()).then(
        sucesso => {
          this.cartorio = <CartorioModel>sucesso
          this.spinner.hide()
        }
      )
    } else {
      await ApiController.getCartorio(this.http).then(result => {
        this.cartorio = <CartorioModel>result
        this.spinner.hide()
      })
    }
  }

  async getValues() {
    this.cartorio = new CartorioModel()
    this.cartorio.descricao = this.description.nativeElement.value

    this.cartorio.cnpj = this.cnpj.nativeElement.value

    this.cartorio.responsavel = this.responsible.nativeElement.value

    this.cartorio.documentoResponsavel = this.responsibleDocument.nativeElement.value

    this.cartorio.email = this.email.nativeElement.value

    this.cartorio.endereco = this.address.nativeElement.value

    this.cartorio.bairro = this.district.nativeElement.value

    this.cartorio.telefone = this.phoneNumber.nativeElement.value

    this.cartorio.cep = this.cep.nativeElement.value

    // this.cartorio.comarca = this.comarca.nativeElement.value
  }

  async validAndSaveValues() {
    await this.getValues()

    // todo: criar a validação do form identicando o campo.

    await ApiController.putAlteracaoDadosCartorio(
      this.http,
      this.cartorio
      ).then(
        result => {
        this.alertService.success('Alterações realizadas com sucesso...')
        this.router.navigate([this.shared.getHomePage()])
      },
      err => {
        console.log(err)

        if (err.status == 400) {
          this.alertService.warning(
            'Confira seus dados para realizar atualização...'
          )
          window.scroll(0, 0)
        } else {
          console.log(err)
          window.scroll(0, 0)
        }
      }
    )
  }

  cancelar() {
    this.router.navigate([this.shared.getHomePage()])
  }

  voltar() {
    this.router.navigate(['./GestaoCartorio'])
  }
}
