/* eslint-disable angular/document-service */
import { Component, Input, OnInit, Renderer2 } from '@angular/core'
import { Router } from '@angular/router'
import * as AdminLte from 'admin-lte'
import * as $ from 'jquery'
import { Session } from 'src/app/Shared/session'

@Component({
  selector: 'app-menu-central-documentos',
  templateUrl: './menu-central-documentos.component.html',
  styleUrls: ['./menu-central-documentos.component.scss']
})
export class MenuCentralDocumentosComponent implements OnInit {
  public sidebarMenuOpened = true
  session: Session

  constructor(private router: Router, private renderer: Renderer2) {}

  @Input('userName') UserNameToShow: string

  ngOnInit() {
    this.session = Session.getInstance()
    if (this.session.getToken() != undefined) {
      this.session.setMenu(true)
      this.session.setMenuApi(false)
    } else {
      this.session.clear()
    }
  }

  ngAfterViewInit() {
    $('[data-widget="treeview"]').each(function () {
      AdminLte.Treeview._jQueryInterface.call($(this), 'init')
    })
  }
  toggleMenuSidebar() {
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-open'
      )
      this.renderer.addClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      )
      this.sidebarMenuOpened = false
    } else {
      this.renderer.removeClass(
        document.querySelector('app-root'),
        'sidebar-collapse'
      )
      this.renderer.addClass(document.querySelector('app-root'), 'sidebar-open')
      this.sidebarMenuOpened = true
    }
  }
}
