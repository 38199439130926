import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { Contato } from '../iepro-crm/contatos/contato'

@Injectable({
  providedIn: 'root'
})
export class ContatoDataService {

  constructor () { }


  private contatoSource = new BehaviorSubject({ contato: null, user_id: '' })
  contatoAtual = this.contatoSource.asObservable()

  obtemContato(contato: Contato, user_id: string) {
    this.contatoSource.next({ contato: contato, user_id: user_id })
  }
}
