import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import * as CryptoJS from 'crypto-js'
import { constPostLogin, urlIdentity } from 'src/app/app.host'
import { UserLoginApi } from 'src/app/Shared/Models/user-login-api.model'
import { CurrentUser } from '../../../Shared/Models/current-user-model'
import { UserLogin } from '../../../Shared/Models/user-model'


@Injectable({
  providedIn: 'root'
})

@Injectable()
export class UserService {

  userModel = new UserLoginApi()

  constructor (private http: HttpClient) { }

  login(user: UserLogin) {
    var key = CryptoJS.enc.Utf8.parse('ri57wQXnTOwXSQmw')
    var iv = CryptoJS.enc.Utf8.parse('xKksSeTes2vUAJFP')
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(user.password), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      })

    var pass = encrypted.toString()

    this.userModel.login = user.login
    this.userModel.password = pass


    return this.http.post<CurrentUser>(urlIdentity + constPostLogin,
      this.userModel
    ).pipe()

  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser')
  }
}
