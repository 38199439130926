import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { HomepageComponent } from './homepage/homepage.component'
import { PdfViewerModule } from 'ng2-pdf-viewer'

@NgModule({
  declarations: [HomepageComponent],
  imports: [CommonModule, PdfViewerModule],
  exports: [HomepageComponent]
})
export class HomePageModule {}
