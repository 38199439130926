// import { GerenciamentoComercialRoutingModule } from './gerenciamento-comercial-routing.module';
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { NgxMaskModule } from 'ngx-mask'
import { ControlFormErroComponent } from './../control-form-erro/control-form-erro/control-form-erro.component'
import { BarraAcoesComponent } from './gerenciamento-comercial/barra-acoes/barra-acoes.component'
import { CadastroEmpresaComponent } from './gerenciamento-comercial/cadastro-empresa/cadastro-empresa.component'
import { GerenciamentoComercialComponent } from './gerenciamento-comercial/gerenciamento-comercial.component'
import { TabelaAtendimentoComponent } from './gerenciamento-comercial/tabela-atendimento/tabela-atendimento.component'
import { TabelaBancoComponent } from './gerenciamento-comercial/tabela-banco/tabela-banco.component'
import { TabelaInteresseComponent } from './gerenciamento-comercial/tabela-interesse/tabela-interesse.component'
import { TabelaProspeccaoComponent } from './gerenciamento-comercial/tabela-prospeccao/tabela-prospeccao.component'

@NgModule({
  declarations: [
    ControlFormErroComponent,
    GerenciamentoComercialComponent,
    BarraAcoesComponent,
    TabelaInteresseComponent,
    TabelaAtendimentoComponent,
    TabelaBancoComponent,
    TabelaProspeccaoComponent,
    CadastroEmpresaComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
    // GerenciamentoComercialRoutingModule
  ],

  exports: []
})
export class GerenciamentoComercialModule {}
