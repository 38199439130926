import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { AceiteIntimadorComponent } from '../intimador-online/aceite-intimador/aceite-intimador/aceite-intimador.component'
import { ModulosExterno } from '../modulos/modulos-externo.module'
import { LoginModule } from './../edital-online/components/login/login.module'
import { MenuSistemasRoutingModule } from './menu-sistemas-routing.module'
import { MenuCentralDocumentosComponent } from './menu-sistemas/menu-central-documentos/menu-central-documentos.component'
import { MenuCrmComercialComponent } from './menu-sistemas/menu-crm-comercial/menu-crm-comercial.component'
import { MenuIntimadorComponent } from './menu-sistemas/menu-intimador/menu-intimador.component'
import { MenuSistemasComponent } from './menu-sistemas/menu-sistemas.component'




@NgModule({
  imports: [
    ModulosExterno,
    CommonModule,
    MenuSistemasRoutingModule,
    LoginModule,
  ],
  declarations: [
    MenuSistemasComponent,
    MenuCentralDocumentosComponent,
    MenuCrmComercialComponent,
    AceiteIntimadorComponent,
    
    MenuIntimadorComponent
  ],
  exports: [MenuSistemasComponent],
  entryComponents: [
    AceiteIntimadorComponent
  ]
})
export class MenuSistemasModule { }


