import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiController } from 'src/app/Core/api-controller';
import { UserChangeModel } from 'src/app/Shared/Models/user-change-model';
import { Session } from 'src/app/Shared/session';

@Component({
  selector: 'app-alterar-integrador',
  templateUrl: './alterar-integrador.component.html',
  styleUrls: ['./alterar-integrador.component.scss']
})
export class AlterarIntegradorComponent implements OnInit {

  objChangeLogin : UserChangeModel;
  shared : Session;
  objDados : UserChangeModel;
  exibe : boolean = true; 
  exibeSelecionado : boolean = false;
  usuarios;
  idAtual;
  selecionavel = false;


  constructor(private http : HttpClient, private router : Router, private spinner: NgxSpinnerService, private alertService: AlertService) { 
    this.shared = Session.getInstance();
  }

  @ViewChild('login', {static: false}) login  : ElementRef;
  @ViewChild('email', {static: false}) email  : ElementRef;
  @ViewChild('documento', {static: false}) documento  : ElementRef;
  @ViewChild('password', {static: false}) password  : ElementRef;
  @ViewChild('password2', {static: false}) password2  : ElementRef;
  @ViewChild('option', {static: false}) option : ElementRef;

  ngOnInit() {
    
    if(this.shared.getToken() == undefined) {
      this.router.navigate([this.shared.getHomePage()]);
    }

    this.loadFields(true);
  }

  async getUsers() {
    ApiController.getUsuariosbyCartId(this.http, this.shared.getCartId())
      .then( usuarios => {
        this.usuarios = <UserChangeModel>usuarios;
        this.exibe = true;
      },
      err => {
        console.log(err);
      })
  }

  async loadFields(exibeselecionado) {
    ApiController.getDadosLoginId(this.http, this.shared.getCartId())
    .then( sucesso => {
      this.exibe = true;
      //console.log(this.shared.getCartId());
      if(exibeselecionado) {
        this.exibeSelecionado = false;
        //console.log("exibeselecionado false");
      }
      else {
        this.exibeSelecionado = true;
       // console.log("exibeselecionado true");
      }

      if(this.shared.getCartId()) 
        this.objDados = <UserChangeModel>sucesso;
      else 
        this.objDados = new UserChangeModel();
    },
    err => {
      this.objDados = new UserChangeModel();
      this.exibe = true;
      this.exibeSelecionado = true;
      //console.log("exibeselecionado true");
    })
  }

  async changeLogin(){
    this.spinner.show();  

    this.objChangeLogin = new UserChangeModel();
    this.objChangeLogin.login = this.login.nativeElement.value;
    this.objChangeLogin.email = this.email.nativeElement.value;
    this.objChangeLogin.documento = this.documento.nativeElement.value;

    this.objChangeLogin.id = this.objDados.id;

    await ApiController.putAlterarLogin(this.http, this.objChangeLogin)
    .then(
      result => {
        this.spinner.hide();

        this.alertService.success("Alterações realizadas com sucesso...");
        this.router.navigate([this.shared.getHomePage()]);
      },
      err => {
        this.spinner.hide();
      }
    )
  }
  
  async buscarDados(){
    var usuarioId = this.option.nativeElement.value.split('-');
    this.idAtual = usuarioId[0];
    this.usuarios.forEach(usuario => {
      if(usuario.id == this.idAtual) {
        this.objDados = usuario
        return;
      }
    });

    this.exibeSelecionado = true;
  }

  cancelar(){
    this.router.navigate(["./integrador"]);
  }
}
