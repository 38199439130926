import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NgxMaskModule } from 'ngx-mask'
import { ManutFirebaseComponent } from './manut-firebase/manut-firebase.component'
@NgModule({
  declarations: [ManutFirebaseComponent],
  imports: [
    CommonModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    ManutFirebaseComponent
  ]
})
export class ManutConfigAppModule { }
