export class PermissaoModel{
    
    public PostXMLFile: boolean = false;
    
    public ManageXMLFiles: boolean = false;
    
    public ManageOffice: boolean = false;
    
    public ManageTitles: boolean = false;
    
    public ManageSecurity: boolean = false;
    
    public ManagePublication: boolean = false;
    
    public SendEmails: boolean = false;   

    public UseAPI: boolean = false;
    
}