import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public message = new Subject<string>();

  constructor () { }

  setMessage(value: string) {
    this.message.next(value)
  }

}
