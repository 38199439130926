export const FirebaseConfig = {
  /* JANDER BEFORE LAST CHANGES
  apiKey: "AIzaSyAVqNah0bBYHgX5z5Ue3T1SFOEk9w4i2sY",
  authDomain: "consultacnp.firebaseapp.com",
  //databaseURL: "https://consultacnp.firebaseio.com",
  databaseURL: "https://consultacnp.firebaseio.com/",
  projectId: "consultacnp",
  storageBucket: "consultacnp.appspot.com",
  client_id: "213388120558 - m8somsng4i1cp26gef98lln2n9jl8roq.apps.googleusercontent.com",
  messagingSenderId: "213388120558"
  */
  apiKey: 'AIzaSyDWTXCWZLHPEiYlJ8WBoYSgfOtQ7UJeqaM',
  authDomain: 'calculadora-37d0c.firebaseapp.com',
  databaseURL: 'https://calculadora-37d0c.firebaseio.com',
  projectId: 'calculadora-37d0c',
  storageBucket: 'calculadora-37d0c.appspot.com',
  messagingSenderId: '206600691717',
  appId: '1:206600691717:web:3f3b0e0cc806ec22d0bc6c',
  measurementId: 'G-NJFYB324MZ'
}
