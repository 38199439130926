import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { CartorioAllDTOModel } from 'src/app/Shared/Models/cartorio-all-dtomodel'
import { ErrorModel } from 'src/app/Shared/Models/error-model'
import { Session } from 'src/app/Shared/session'
import { AlterarLoginComponent } from '../../alterar-login/alterar-login/alterar-login.component'
import { AlterarPassComponent } from '../../alterar-pass/alterar-pass/alterar-pass.component'

@Component({
  selector: 'app-gestao-cartorio',
  templateUrl: './gestao-cartorio.component.html',
  styleUrls: ['./gestao-cartorio.component.scss']
})
export class GestaoCartorioComponent implements OnInit {

  shared: Session
  cartorios
  dadosCartorio
  p: number = 1;
  term
  bsModalRef: BsModalRef

  @ViewChild('myModal', { static: false }) myModal

  constructor (
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService
  ) { }

  @ViewChild('description', { static: false }) description: ElementRef
  @ViewChild('cnpj', { static: false }) cnpj: ElementRef
  @ViewChild('responsible', { static: false }) responsible: ElementRef
  @ViewChild('responsibleDocument', { static: false }) responsibleDocument: ElementRef
  @ViewChild('email', { static: false }) email: ElementRef
  @ViewChild('address', { static: false }) address: ElementRef
  @ViewChild('district', { static: false }) district: ElementRef
  @ViewChild('phoneNumber', { static: false }) phoneNumber: ElementRef
  @ViewChild('cep', { static: false }) cep: ElementRef
  async ngOnInit() {
    await this.loadpage()
  }

  async loadpage() {
    this.spinner.show()
    this.shared = Session.getInstance()
    this.getCartorios()
  }

  async getCartorios() {
    this.spinner.show()

    this.cartorios = new Array<CartorioAllDTOModel>()
    ApiController.getListaCartorios(this.http)
      .then(sucesso => {
        var mapeado = <CartorioAllDTOModel[]>sucesso
        this.cartorios = mapeado
        //console.log(this.cartorios);
        this.spinner.hide()
      }
      )
  }

  findCart(letra) {
    this.spinner.show()
    ApiController.getFindCartorioLetra(this.http, letra)
      .then(sucesso => {
        var mapeado = <CartorioAllDTOModel[]>sucesso
        this.cartorios = mapeado
        this.spinner.hide()
      },
        err => {
          console.log(err)
          var mapeado = <ErrorModel>err.error
          var erromapedo = ApiController.mapError(mapeado.IEPROErrorID, mapeado.Mensagem, mapeado.ErroI)
          console.log(erromapedo)
          this.spinner.hide()
        })
  }

  getViewRemessa(id) {
    this.shared.setCartId(id)
    this.router.navigate(['/viewRemessaSuporte'])
  }

  createUser(id) {
    this.shared.setCartId(id)
    this.shared.setNewUser(true)
    this.router.navigate(['/cadastroUsuario'])
  }

  consultarInfos(id) {
    this.spinner.show()
    this.shared.setEditar(false)
    this.shared.setCartId(id)
    this.router.navigate(['/EditCartorio'])
  }

  openModalAlterarLogin(id) {
    this.shared.setCartId(id)
    this.bsModalRef = this.modalService.show(AlterarLoginComponent)
    this.bsModalRef.content.closeBtnName = 'Close'
  }
  openModalAlterarSenha(id) {
    this.shared.setCartId(id)
    this.bsModalRef = this.modalService.show(AlterarPassComponent)
    this.bsModalRef.content.closeBtnName = 'Close'
  }
}