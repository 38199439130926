import { ValorReferenciaModel } from "./valor-referencia-model";
import { ValorReferenciaModelSemFaixa } from "./valor-referencia-model";

export class EmolumentoModel {
    Conducao : number;
    Digitalizacao : number;
    FaixaAvaliacao : Array<ValorReferenciaModel>;
    FaixaAvaliacaoSelo : Array<ValorReferenciaModel>;
    FaixaEmolumentoSelo : Array<ValorReferenciaModel>;
    Intimacao : number;
    Processamento : number;
    Protesto : number;
}

export class EmoluentModelYear {
    vigencia : number;
}

export class EmolumentoModelFirebase {
    Conducao : number;
    Digitalizacao : number;
    FaixaAvaliacao : Array<ValorReferenciaModelSemFaixa>;
    FaixaAvaliacaoSelo : Array<ValorReferenciaModelSemFaixa>;
    FaixaEmolumentoSelo : Array<ValorReferenciaModelSemFaixa>;
    Intimacao : number;
    Processamento : number;
    Protesto : number;
}
