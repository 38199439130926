import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Informativa1Component } from './informativa1/informativa1.component';

@NgModule({
  declarations: [Informativa1Component],
  imports: [
    CommonModule
  ],
  exports: [
    Informativa1Component
  ]
})
export class InformativasModule { }
