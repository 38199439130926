import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from 'ngx-alerts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorModel } from 'src/app/Shared/Models/error-model';
import { Session } from 'src/app/Shared/session';
import { ApiController } from '../../../../Core/api-controller';

@Component({
  selector: 'app-publicacao-agendada-envio',
  templateUrl: './publicacao-agendada-envio.component.html',
  styleUrls: ['./publicacao-agendada-envio.component.scss']
})
export class PublicacaoAgendadaEnvioComponent implements OnInit {

  constructor(private http: HttpClient, private spinner: NgxSpinnerService, private router : Router, private alertService: AlertService) { }

  public shared : Session;
  
  @ViewChild('dateUpload', {static: false}) dateUpload : ElementRef;

  file;
  path = "Selecione seu arquivo...";
  size;
  envioOk : boolean;
  idPublicacao : string;
  retornoHttp;
  titulo;
  publicador;
  Enviando = false;

  ngOnInit() {
    this.shared = Session.getInstance();
     
    //if(this.shared.token == undefined)
      //this.router.navigate(['/']);
    //else {
      if(this.shared.getPermissao().ManagePublication) {
        this.titulo = "Envio de notícia";
        this.publicador = true;
      }
      else if (this.shared.getPermissao().PostXMLFile) {
        this.titulo = "Envio de remessa";
        this.publicador = false;
    }
  }

  async submitFile() {
    try
    {
      if(!this.Enviando)
      {
        this.Enviando = true;
        this.spinner.show();
        
        const formData = new FormData();
        formData.append('pdf', this.file);
        
        if (this.shared.getPermissao().ManagePublication) {
          await ApiController.postPublicacaoAgendamento(formData, this.http, this.dateUpload.nativeElement.value)
          .then(          
            result => {         
              console.log(result)   
              this.spinner.hide();                    
              this.alertService.success("Agendamento realizado com sucesso! - Id Publicação: " + result);
              this.router.navigate([this.shared.getHomePage()]);
            },
            err => {
              var mapeado = <ErrorModel>err.error;
              var erromapedo = ApiController.mapError(mapeado.IEPROErrorID, mapeado.Mensagem, mapeado.ErroI);
              console.log(mapeado.IEPROErrorID + " - " + mapeado.ErroI);
              this.spinner.hide();        
            })
        }       
        this.spinner.hide();    
      }    
    }
    catch(err)
    {
      this.spinner.hide();        
      console.log("Erro - " + err);
    }
  }

  inputFile(event) {
    this.spinner.show();
    if (event.target.files && event.target.files[0]) {
        this.path = event.target.files[0].name;
        this.file = event.target.files[0];
        this.size = event.target.files[0].size/1000;
        
        //console.log(event.target.files[0]);

        if(this.size > 5000)
        {
          console.log("Arquivo de --- "+ this.size + "KB");
          this.alertService.danger("Arquivo superior ao tamanho suportado... 5 MB");
          this.file = 0;     
          this.path = "Selecione seu arquivo...";   
        }
        else{ 
          console.log("Arquivo de "+ this.size + "KB");
        
        }
    }    
    this.spinner.hide();
  }

  cancelar(){
    this.router.navigate([this.shared.getHomePage()]);    
  }

}
