import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { AlertService } from 'ngx-alerts'
import { NgxSpinnerService } from 'ngx-spinner'
import { ContatoDataService } from '../../../_services/contato-data.service'
import { ContatoService } from '../../../_services/contato.service'
import { Session } from './../../../Shared/session'
import { Contato } from './../contato'

@Component({
  selector: 'app-edita',
  templateUrl: './edita.component.html',
  styleUrls: ['./edita.component.scss']
})
export class EditaComponent implements OnInit {
  public contato: Contato
  public ocorrenciaArray
  public key: string = ''
  public ocorrenciaInput
  public dataoOcorrenciaView = new Date().toLocaleDateString('pt-BR')
  public isCollapsed = true;
  public collapsedRegistro = true;
  public shared: Session

  constructor (
    private _contatoService: ContatoService,
    private _contatoDataService: ContatoDataService,
    private router: Router,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.shared = Session.getInstance()
    this.obtemContato()
    if (this.contato.nome === undefined || this.contato.nome === null) {
      this.router.navigate(['/iepro-crm'])
    }
  }

  obtemContato() {
    this.contato = new Contato()
    this.spinner.show()
    this._contatoDataService.contatoAtual.subscribe(data => {
      if (data.contato && data.user_id) {
        this.contato = new Contato()
        this.contato.documento = data.contato.documento
        this.contato.email = data.contato.email
        this.contato.mensagem = data.contato.mensagem
        this.contato.nome = data.contato.nome
        this.contato.telefone = data.contato.telefone
        this.contato.ocorrencia = data.contato.ocorrencia
        this.key = data.user_id
        this.ocorrenciaArray = Object.keys(this.contato.ocorrencia).map(key => this.contato.ocorrencia[key])
      }
    })
    this.spinner.hide()
  }

  onSubmit() {
    if (this.contato.ocorrencia) {
      try {
        this.contato.ocorrencia.ocorrenciaUser = this.ocorrenciaInput
        this.contato.ocorrencia.dataOcorrencia = this.dataoOcorrenciaView

        this.contato.ocorrencia.usuarioIepro = this.shared.getLogin()

        this._contatoService.update(this.contato, this.key)

        this.ocorrenciaArray.push({
          ocorrenciaUser: this.contato.ocorrencia.ocorrenciaUser,
          data: this.contato.ocorrencia.dataOcorrencia,
          usuarioIepro: this.contato.ocorrencia.usuarioIepro
        })

        this.ocorrenciaInput = null
        this.alertService.success("Registro Salvo com Sucesso!")

      } catch (error) {
        this.alertService.danger("Falha ao salvar registro")
        console.log(error)
      }
    } else {
      try {
        this.contato.ocorrencia = {
          ocorrenciaUser: this.ocorrenciaInput,
          dataOcorrencia: this.dataoOcorrenciaView,
          usuarioIepro: this.shared.getLogin()
        }
        this._contatoService.update(this.contato, this.key)
        this.ocorrenciaInput = null
        this.alertService.success("Registro Salvo com Sucesso!")
        this.router.navigate(['/iepro-crm'])
      } catch (error) {
        this.alertService.danger("Falha ao salvar registro")
        console.log(error)
      }
    }
  }

  voltar = () => this.router.navigate(['/iepro-crm'])
}
