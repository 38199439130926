import { Component, EventEmitter, Output } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { Subscription } from 'rxjs'
import { MenuService } from './Menu/menu.service'


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'EditalOnline';
  message: string
  subscription: Subscription
  userType: string
  //public shared : SharedStorage;
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();

  constructor (private spinner: NgxSpinnerService, public messageService: MenuService) {

  }

  ngOnInit() {
    this.subscription = this.messageService.message.subscribe(
      (message) => {
        this.message = message
        // console.log(this.message)
      }
    )
    /*this.shared = new SharedStorage();
    this.shared = JSON.parse(localStorage.getItem('currentUser'));
    this.userType = this.shared.user.userType ;
    

    /*this.spinner.show();  

    setTimeout(() => {      
        this.spinner.hide();
    }, 5000);*/

  }

  reciverFeedback(newValue) {

    /*this.shared = JSON.parse(localStorage.getItem('currentUser'));
    this.userType = newValue;*/
  }
}
