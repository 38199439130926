import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { of } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class PublicacoesDataService {
  private readonly API_INTIMADOR = environment.API_IntimadorDigital
  private readonly API_EDITAL = environment.API_EditalOnline

  private endPointPublicacaoUltima = 'Publicacoes/Ultima'

  constructor(private http: HttpClient) {}

  getUltimaPublicacaoValida() {
    return this.http
      .get(this.API_EDITAL + this.endPointPublicacaoUltima, {
        responseType: 'blob'
      })
      .pipe()
  }

  getPublicacaoDefault() {
    return this.http.get(`${this.API_EDITAL}PDF/default.pdf`).pipe()
  }
}
