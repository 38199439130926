/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import Swal from 'sweetalert2'
import { IntimacoesDataService } from './../../shared/intimacoes-data.service'

@Component({
  selector: 'app-aceite-intimador',
  templateUrl: './aceite-intimador.component.html',
  styleUrls: ['./aceite-intimador.component.scss']
})
export class AceiteIntimadorComponent implements OnInit {
  public aceiteIntimador = true

  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private intimacoesData: IntimacoesDataService,
    private router: Router
  ) {}

  ngOnInit() {}

  doHide() {
    this.bsModalRef.hide()
  }

  aceite() {
    this.intimacoesData
      .putAdesaoIntimador(this.aceiteIntimador)
      .subscribe(data => {
        console.log(data)
      })
    this.doHide()
    Swal.fire(
      'Contrato aceito',
      'Para que as alterações entrem em vigor, saia do sistema e entre novamente',
      'success'
    )
  }
}
