export class ValorReferenciaModel {
    Valor: number;
    ValorReferencia: number;
    ValorReferencia1: number;
    IdFaixa : number;
}

export class ValorReferenciaModelSemFaixa {
    Valor: number;
    ValorReferencia: number;
}
