/* eslint-disable angular/window-service */
import { HttpClient } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import * as jwt_decode from 'jwt-decode'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { NgxSpinnerService } from 'ngx-spinner'
import { ApiController } from 'src/app/Core/api-controller'
import { AlterarLoginComponent } from 'src/app/edital-online/components/alterar-login/alterar-login/alterar-login.component'
import { AlterarPassComponent } from 'src/app/edital-online/components/alterar-pass/alterar-pass/alterar-pass.component'
import { UserService } from 'src/app/edital-online/components/login/user.service'
import { ConfigDtoModel } from 'src/app/Shared/Models/config-dto-model'
import { UserLogin } from 'src/app/Shared/Models/user-model'
import { Session } from 'src/app/Shared/session'

@Component({
  selector: 'app-menu-sistemas',
  templateUrl: './menu-sistemas.component.html',
  styleUrls: ['./menu-sistemas.component.scss']
})
export class MenuSistemasComponent implements OnInit {
  public session: Session
  public user = new UserLogin('', '')
  public bsModalRef: BsModalRef
  public href: string = ''
  public UserNameToShow: string
  public nomeUsuario: string
  menuEdital = false
  menuIntimador = false
  menuCentral = false
  menuCrm = false
  menuSistemas = true

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private spinner: NgxSpinnerService,
    // private alertService: AlertService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.session = Session.getInstance()
    this.href = this.router.url
    if (this.href != '') {
      this.router.navigate([''])
    }
    this.mostrarMenu(event)

    if (this.session.getToken() != undefined) {
      this.getCartorioId()
      this.session.setMenu(true)
      this.session.setMenuApi(false)

      this.UserNameToShow = this.session.getLogin()
      this.nomeUsuario = this.UserNameToShow.split('@', 1)[0]

      ApiController.getConfig(this.http).then(
        sucesso => {
          this.session = Session.getInstance()
          let mapeado = <ConfigDtoModel>sucesso

          this.session.setValidNewsTimeInDays(mapeado.validNewsTimeInDays)
          this.session.setPostXMLStartHour(mapeado.postXMLStartHour)
          this.session.setPostXMLStartMin(mapeado.postXMLStartMin)

          this.session.setPostXMLEndMin(mapeado.postXMLEndMin)
          this.session.setPostXMLEndHour(mapeado.postXMLEndHour)

          this.spinner.hide()
        },
        err => {
          console.log(err)
          this.spinner.hide()
        }
      )
    } else {
      this.session.clear()
    }
    var token = this.session.getToken()
    var decode = jwt_decode(token)
    console.log(decode.unique_name)
  }

  getCartorioId() {
    ApiController.getIdCartorio(this.http).then(sucesso => {
      this.session.setCartId(sucesso)
    })
  }

  mostrarMenu(event) {
    if (event == 'menuEdital') {
      return (
        (this.menuEdital = true),
        (this.menuSistemas = false),
        this.router.navigate(['/home'])
      )
    }
    // if (event == 'menuCentral') {
    //   this.menuCentral = true,
    //     this.menuSistemas = false,
    //     this.router.navigate['/home']
    // }
    // if (event == 'menuIntimador') {
    //   ;(this.menuIntimador = true),
    //     (this.menuSistemas = false),
    //     this.router.navigate['/home']
    // }
    // if (event == 'menuCrm') {
    //   this.menuCrm = true,
    //     this.menuSistemas = false,
    //     this.router.navigate['/home']
    // }
  }

  openModalAlterarLogin() {
    this.bsModalRef = this.modalService.show(AlterarLoginComponent)
    this.bsModalRef.content.closeBtnName = 'Close'
  }
  openModalAlterarSenha() {
    this.bsModalRef = this.modalService.show(AlterarPassComponent)
    this.bsModalRef.content.closeBtnName = 'Close'
  }

  logOut() {
    this.spinner.show()
    try {
      localStorage.removeItem('currentUser')
      window.location.reload()
    } finally {
      this.spinner.hide()
    }
  }
}
