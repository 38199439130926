import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { NgxMaskModule } from 'ngx-mask'
import { CreateCartComponent } from './create-cart/create-cart.component'
import { FormControlErroComponent } from './form-control-erro/form-control-erro.component'

@NgModule({
  declarations: [CreateCartComponent, FormControlErroComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    CreateCartComponent
  ]
})
export class CreateCartModule { }
