import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { CurrentUser } from '../Shared/Models/current-user-model';
import { constPostLogin, urlAcesso } from '../app.host';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    constructor(private http: HttpClient) { }

    login(username: string, password: string) {

        var key = CryptoJS.enc.Utf8.parse('ri57wQXnTOwXSQmw');
        var iv = CryptoJS.enc.Utf8.parse('xKksSeTes2vUAJFP');
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(password), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        
        var  pass = encodeURIComponent(encrypted.toString());

        return this.http.post<CurrentUser>(urlAcesso + constPostLogin,
            JSON.stringify({login: username, password:pass}),
          { 
              headers: new HttpHeaders({
                "content-type": "application/x-www-form-urlencoded"
              })
          })

        /*  .pipe(map(user => {
            // login successful if there's a jwt token in the response
            if (user && user.token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
            }

            return user;
        }));*/

        /*return this.http.post<any>(`${config.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user));
                }

                return user;
            }));*/
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
    }
}