
export class Detalhe {
  deliveryDate: Date
  sendDate: Date
  name: string
  document: string
  value: string
  protocol: string
}

export class DetalheStatusIntimacoes extends Detalhe {
  date: Date
  whatsapp_sucessos: Array<Detalhe>
  whatsapp_falhas: Array<Detalhe>
  email_sucessos: Array<Detalhe>
  email_falhas: Array<Detalhe>
  sms_sucessos: Array<Detalhe>
  sms_falhas: Array<Detalhe>
  total_sucessos_sms: number
  total_sucessos_email: number
  total_sucessos_whats: number
  total_falhas_sms: number
  total_falhas_email: number
  total_falhas_whats: number

}

