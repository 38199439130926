import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-status-todas-intimacoes',
  templateUrl: './status-todas-intimacoes.component.html',
  styleUrls: ['./status-todas-intimacoes.component.scss']
})
export class StatusTodasIntimacoesComponent implements OnInit {
  p: number = 1
  term
  cartorios

  constructor() {}

  ngOnInit() {}
}
